import {
  Label,
  LabelSize,
  Icon,
  IconSize,
  Tooltip,
  LabelWeight,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { subheaderData } from '../../../../modules/products/viewProducts/utils';
import { DeliveryCost } from '../../../graphql/types/generated';
import {
  BasketOrder,
  BasketOrderLine,
  EditProductQuantityOptions,
} from '../../../providers/BasketProvider/useBasket';
import { useOrganisationsContext } from '../../../providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { getNumberOrZero } from '../../../utils/getNumberOrZero/getNumberOrZero';
import { getTaxRate } from '../../../utils/getTaxRate/getTaxRate';
import { OrderSummary } from '../../OrderSummary/OrderSummary';
import { DeliveryInfo } from '../DeliveryInfo/DeliveryInfo';
import { OrderProductRow } from '../OrderProductRow/OrderProductRow';
import {
  getTotalMaximumDeliveryTime,
  getTotalMinimumDeliveryTime,
} from '../utils';
import {
  OrderLineTable,
  OrderLineTableRow,
  TableHeaderText,
  OrderLineTableColumn,
} from './OrderLinesTableStyled';
import { getAllMoqPricesSortedDescending } from '../../../utils/getMoqPrice/getMoqPrice';
import { ProductPrice } from '../../../types/ProductPrice';
import { isMoqQuantityValid } from '../../../utils/isMoqQuantityValid/isMoqQuantityValid';

export interface Props {
  order: BasketOrder;
  handleNumberPackChange?: (options: EditProductQuantityOptions) => void;
  handleOrderLineEdit?: (productId: string) => void;
  handleProductRemovedFromAddress?: (
    productId: string,
    addressId: string
  ) => void;
  isEditing?: boolean;
}

export const OrderLinesTable = ({
  order,
  handleNumberPackChange,
  handleOrderLineEdit,
  handleProductRemovedFromAddress,
  isEditing = true,
}: Props) => {
  const { t } = useTranslation();
  const { currentOrganisation } = useOrganisationsContext();

  const handleProductRemoved = (productId: string, addressId: string) => {
    handleProductRemovedFromAddress &&
      handleProductRemovedFromAddress(productId, addressId);
  };

  const handleQuantityChange = (options: EditProductQuantityOptions) => {
    handleNumberPackChange && handleNumberPackChange(options);
  };

  const handleLineEdit = (productId: string) => {
    handleOrderLineEdit && handleOrderLineEdit(productId);
  };

  let deliveryFee = getNumberOrZero(order?.deliveryInfo?.deliveryFee);

  if (currentOrganisation?.config?.computeDeliveryCostPerProduct) {
    const productsDeliveryCost: number = order.orderLines.reduce(
      (acc: number, currentOrderLine: BasketOrderLine) => {
        const foundCostPerPack: DeliveryCost | undefined =
          currentOrderLine.product.deliveryCosts.find(
            deliveryCost =>
              deliveryCost.currencyCode === currentOrganisation.defaultCurrency
          );

        if (
          !foundCostPerPack ||
          typeof foundCostPerPack.costPerPackExclVat !== 'number'
        ) {
          return acc;
        }
        const numberOfPacks: number = getNumberOrZero(
          currentOrderLine.numberOfPacks
        );
        const deliveryCostForCurrentLine: number =
          numberOfPacks * foundCostPerPack?.costPerPackExclVat;
        return acc + deliveryCostForCurrentLine;
      },
      0
    );

    deliveryFee = productsDeliveryCost;
  }

  const productsAndDeliverySubtotal: number =
    deliveryFee !== undefined ? order.subTotal + deliveryFee : 0;
  const VAT =
    getTaxRate(currentOrganisation?.addresses[0].taxRates, 'VAT').taxValue || 0;
  const VATPercentage =
    getTaxRate(currentOrganisation?.addresses[0].taxRates, 'VAT')
      .taxPercentage || 0;
  const tariff =
    getTaxRate(currentOrganisation?.addresses[0].taxRates, 'Tariff').taxValue ||
    0;
  const tariffPercentage =
    getTaxRate(currentOrganisation?.addresses[0].taxRates, 'Tariff')
      .taxPercentage || 0;
  const calculatedVAT: number = productsAndDeliverySubtotal * VAT;
  const calculatedTariff: number = order.subTotal * tariff;

  const totalAmountOfOverflowProducts: number = order.orderLines.reduce(
    (total, currentValue) => {
      if (currentValue.overflowQuantity) {
        return total + currentValue.overflowQuantity;
      } else {
        return total;
      }
    },
    0
  );

  const totalAmountOfProductsOverMoqQuantity: number = order.orderLines.reduce(
    (total, currentValue) => {
      if (!isEditing) {
        return total;
      }
      const moqPrices: ProductPrice[] = getAllMoqPricesSortedDescending(
        currentValue.product.productPrices
      );
      if (
        !isMoqQuantityValid({
          unitQuantityPackSize: currentValue.product.unitQuantity,
          numberOfPacks: currentValue.numberOfPacks,
          minMoqQuantity: moqPrices[moqPrices.length - 1].minOrderQuantity,
        })
      ) {
        return total + 1;
      } else return total;
    },
    0
  );

  const postcode = order?.address.postcode;
  const minDeliveryTime = order?.deliveryInfo?.deliveryTimeline.min || 0;
  const maxDeliveryTime = order?.deliveryInfo?.deliveryTimeline.max || 0;
  const minManufacturingTimes = order?.orderLines?.map(orderLine =>
    orderLine.product.productType?.name === 'Dropship' &&
    orderLine.product.manufacturingLeadTime?.min
      ? orderLine.product.manufacturingLeadTime?.min
      : 0
  );
  const maxManufacturingTimes = order?.orderLines?.map(orderLine =>
    orderLine.product.productType?.name === 'Dropship' &&
    orderLine.product.manufacturingLeadTime?.max
      ? orderLine.product.manufacturingLeadTime?.max
      : 0
  );

  return (
    <OrderLineTable>
      <OrderLineTableRow css={{ marginBottom: '$spacing-fluid-block-small' }}>
        <OrderLineTableColumn style={{ flex: '1 5 49%' }}>
          <TableHeaderText
            size={LabelSize.Medium}
            weight={LabelWeight['Semi-Bold']}
          >
            {t('orderDetails.product', {
              count: order.orderLines.length,
            })}
            {/* for info on plurals in i18next: https://www.i18next.com/translation-function/plurals */}
          </TableHeaderText>
        </OrderLineTableColumn>
        <OrderLineTableColumn
          style={
            currentOrganisation?.config?.prepaidStock
              ? { flex: '1 2 20%', justifyContent: 'flex-end' }
              : { flex: '1 2 14%' }
          }
        >
          <TableHeaderText
            size={LabelSize.Medium}
            weight={LabelWeight['Semi-Bold']}
          >
            {t('orderDetails.quantity')}
          </TableHeaderText>
        </OrderLineTableColumn>
        {!currentOrganisation?.config?.prepaidStock &&
          currentOrganisation?.config?.displayProductUnitCost && (
            <OrderLineTableColumn
              style={{ flex: '1 2 14%', justifyContent: 'flex-end' }}
            >
              <TableHeaderText
                size={LabelSize.Medium}
                weight={LabelWeight['Semi-Bold']}
              >
                {t('orderDetails.unitCost')}
              </TableHeaderText>
            </OrderLineTableColumn>
          )}
        {!currentOrganisation?.config?.prepaidStock && (
          <OrderLineTableColumn
            style={{
              flex: '1 2 14%',
              justifyContent: 'flex-end',
            }}
          >
            <TableHeaderText
              size={LabelSize.Medium}
              weight={LabelWeight['Semi-Bold']}
            >
              {t('orderDetails.cost')}
            </TableHeaderText>
          </OrderLineTableColumn>
        )}
        <OrderLineTableColumn style={{ flex: '1 1 10%' }}>
          <Label size={LabelSize.Medium}>&nbsp;</Label>
        </OrderLineTableColumn>
      </OrderLineTableRow>
      {order.orderLines.map((orderLine, index) => {
        const numberOfUnits = isEditing
          ? (orderLine.product.unitQuantity || 1) * orderLine.numberOfPacks
          : orderLine.quantityInUnits || 1;

        return (
          <OrderProductRow
            productName={orderLine.product.name || 'product name'}
            product={orderLine.product}
            productImageUrl={orderLine.product.imageUrl}
            numberOfPacks={orderLine.numberOfPacks}
            numberOfUnits={numberOfUnits}
            cost={orderLine.subTotal}
            unitCost={orderLine.subTotal / numberOfUnits}
            onNumberOfPacksChange={quantity => {
              if (orderLine.product.id && order.address.id) {
                handleQuantityChange({
                  productId: orderLine.product.id,
                  addressId: order.address.id,
                  quantity,
                });
              }
            }}
            onProductDelete={() => {
              if (orderLine.product.id && order.address.id) {
                handleProductRemoved(orderLine.product.id, order.address.id);
              }
            }}
            onProductEdit={() => {
              if (orderLine.product.id) {
                handleLineEdit(orderLine.product.id);
              }
            }}
            overflowQuantity={orderLine.overflowQuantity}
            isEditing={isEditing}
            isFirstRow={index === 0}
            isLastRow={index === order.orderLines.length - 1}
            hasTariff={Boolean(tariffPercentage)}
            currencyCode={order.orderSummary?.currencyCode}
            key={orderLine.product.id}
          />
        );
      })}
      {totalAmountOfOverflowProducts > 0 && (
        <Label
          size={LabelSize.Small}
          css={{
            color: '$colour-brand-red-hue-1-base',
            fontFamily: '$font-latin-sans-serif-1',
            display: 'flex',
            marginTop: '$spacing-fluid-block-x-small',
            position: 'absolute',
          }}
        >
          <Icon
            size={IconSize['Static XXX-Small']}
            name="icon-alert-exclamation-outline"
          />
          &nbsp;&nbsp;
          {t('viewBasket.stockOverflowExplanation', {
            totalAmountOfOverflowProducts,
            count: totalAmountOfOverflowProducts,
          })}
        </Label>
      )}
      {totalAmountOfProductsOverMoqQuantity > 0 && (
        <Label
          size={LabelSize.Small}
          css={{
            color: '$colour-brand-red-hue-1-base',
            fontFamily: '$font-latin-sans-serif-1',
            display: 'flex',
            marginTop: '$spacing-fluid-block-x-small',
            position: 'absolute',
          }}
        >
          <Icon
            size={IconSize['Static XXX-Small']}
            name="icon-alert-exclamation-outline"
          />
          &nbsp;&nbsp;
          {t('viewBasket.moqNotification')}
        </Label>
      )}
      {isEditing && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '$spacing-fluid-block-large',
          }}
        >
          <div style={{ margin: '2rem 11% auto auto', flexBasis: '60%' }}>
            <OrderSummary
              size="small"
              productsSubTotal={order.subTotal}
              deliveryFee={deliveryFee}
              orderVAT={calculatedVAT}
              VATPercentage={VATPercentage}
              tariffPercentage={tariffPercentage}
              orderTariff={calculatedTariff}
              productsAndDeliverySubtotal={productsAndDeliverySubtotal}
              basketTotalIncludingTaxes={
                productsAndDeliverySubtotal + calculatedVAT + calculatedTariff
              }
              deliveryLabel={
                <Tooltip
                  trigger={
                    <span>
                      <DeliveryInfo
                        postcode={postcode}
                        minDays={getTotalMinimumDeliveryTime(
                          minDeliveryTime,
                          minManufacturingTimes
                        )}
                        maxDays={getTotalMaximumDeliveryTime(
                          maxDeliveryTime,
                          maxManufacturingTimes
                        )}
                      />
                    </span>
                  }
                  contentText={
                    subheaderData(
                      order?.orderLines[0].product,
                      currentOrganisation?.addresses
                    )?.subheaderToolTipText
                  }
                />
              }
              subtotalLabel={t('orderDetails.orderSubTotal')}
              totalIncludingTaxesLabel={
                tariffPercentage
                  ? t('orderDetails.orderTotalInclTaxes')
                  : t('orderDetails.orderTotalInclVAT')
              }
            />
          </div>
        </div>
      )}
    </OrderLineTable>
  );
};
