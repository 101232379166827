import React from 'react';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import LogRocket from 'logrocket';
import ReactDOM from 'react-dom/client';
import { I18nextProvider as TranslationsProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import config from './config';
import reportWebVitals from './reportWebVitals';
import i18n from './shared/internationalization';
import AuthenticationProvider from './shared/providers/AuthenticationProvider/AuthenticationProvider';
import BasketProvider from './shared/providers/BasketProvider/BasketProvider';
import ClientOrderProvider from './shared/providers/BasketProvider/ClientOrderProvider';
import QueryProvider from './shared/providers/QueryProvider/QueryProvider';
import './index.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import ToastProvider from './shared/providers/ToastProvider/ToastProvider';
import UserOrganisationsProvider from './shared/providers/UserOrganisationsProvider/UserOrganisationsProvider';
import UserProvider from './shared/providers/UserProvider/UserProvider';
import { CaptureConsole } from '@sentry/integrations';

config.logrocketAppId && LogRocket.init(config.logrocketAppId);

Sentry.init({
  dsn: config.sentryTrackingUrl,
  integrations: [new CaptureConsole({ levels: ['error'] })],
  environment: config.environment,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // FYI: https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/
  // below regexp is needed to not reporting errors from CSP security policy we have setup via nginx headers
  // more to be find in nginx.conf file in this repo
  ignoreErrors: [/\[Report Only\]/gi],
});

config.logrocketAppId &&
  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });

datadogRum.init({
  applicationId: config.datadogApplicationId,
  clientToken: config.datadogClientToken,
  site: config.datadogSite,
  service: config.datadogService,
  env: config.datadogEnvironment,
  version: config.appVersion,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackResources: true,
  trackLongTasks: true,
  trackUserInteractions: true,
  trackFrustrations: true,
  defaultPrivacyLevel: 'mask-user-input',
  // allowedTracingUrls: [config.apiOriginUrl],
});

datadogLogs.init({
  clientToken: config.datadogClientToken,
  site: config.datadogSite,
  forwardErrorsToLogs: true,
  service: config.datadogService,
  env: config.datadogEnvironment,
  version: config.appVersion,
  sessionSampleRate: 100,
  forwardConsoleLogs: ['log', 'warn', 'error'],
});

datadogRum.startSessionReplayRecording();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
      <TranslationsProvider i18n={i18n}>
        <AuthenticationProvider>
          <UserProvider>
            <QueryProvider>
              <UserOrganisationsProvider>
                <ToastProvider>
                  <BasketProvider>
                    <ClientOrderProvider>
                      <App />
                    </ClientOrderProvider>
                  </BasketProvider>
                </ToastProvider>
              </UserOrganisationsProvider>
            </QueryProvider>
          </UserProvider>
        </AuthenticationProvider>
      </TranslationsProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
