import { globalCss, styled, theme } from '@sourceful/design-system';
import { Z_INDEX_MODAL } from '../../../constants/zIndexValues';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { Nav } from '../../Nav/Nav';
import { Layout } from '../../PrivateRoute/PrivateRoute';

const PageWrapper = styled('div', {
  padding: '128px 0px 10px 0px',
});

const globalStyles = globalCss({
  ':root': {
    '--offset': theme.space['gridOffset'],
    '--max-width': theme.sizes['site'],
    '--columns': '6',
    '--gutter': theme.space['gridGutter'],

    '@bp-xs': {
      '--offset': theme.space['gridOffsetXS'],
    },

    '@bp-sm': {
      '--offset': theme.space['gridOffsetSM'],
      '--columns': '12',
      '--gutter': theme.space['gridGutterSM'],
    },

    '@bp-md': {
      '--offset': theme.space['gridOffsetMD'],
    },

    '@bp-lg': {
      '--offset': theme.space['gridOffsetLG'],
      '--gutter': theme.space['gridGutterLG'],
    },
  },
  'div[data-grid-container]': {
    maxWidth: '400px',
    '@bp-xs': {
      maxWidth: '600px',
    },
    '@bp-sm': {
      maxWidth: '800px',
    },
  },
  'div[data-state="open"]': {
    zIndex: Z_INDEX_MODAL,
  },
  'html body': {
    backgroundColor: '$colour-brand-neutral-hue-1-tint-6',
    paddingBottom: '$spacing-fluid-block-x-large',
    overflowY: 'scroll !important',
    marginRight: '0px !important',
  },
});

export const DefaultLayout: Layout = ({ children }) => {
  globalStyles();
  return (
    <>
      <Nav />
      <PageWrapper>
        <ErrorBoundary onError={console.error}>{children}</ErrorBoundary>
      </PageWrapper>
    </>
  );
};
