import { styled } from '@sourceful/design-system';
import { TextBlock } from 'react-placeholder/lib/placeholders';
import { BREAKPOINT_M } from '../../../../shared/constants/styles';

const BannerWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '40px 70px 150px',
  padding: '10px',
  width: '500px',
  rowGap: '8px',
  columnGap: '70px',
});

const OrderSummaryBannerWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexBasis: '100%',
  [BREAKPOINT_M]: {
    marginTop: '$spacing-fluid-block-large',
  },
  flex: 1,
  paddingTop: '10px',
  padding: '10px 5px 10px 5px',
  boxShadow: '0 0.0625rem 0.5rem 0 rgba(64, 64, 68, 0.3)',
  borderRadius: '5px',
  backgroundColor: '#fff',
});

//TODO: PRINTER BUTTON DESCOPED FOR MVP
// const ButtonWrapper = styled('div', {
//   alignSelf: 'flex-end',
// });

export const OrderSummaryBannerSkeleton = () => (
  <OrderSummaryBannerWrapper className="show-loading-animation">
    {/** adding class to show the loading animation https://github.com/buildo/react-placeholder/issues/93 */}
    <BannerWrapper>
      <TextBlock rows={1} color="lightGray" />
      <TextBlock rows={1} widths={[110]} color="lightGray" />
      <TextBlock
        rows={1}
        widths={[30]}
        color="lightGray"
        style={{ marginLeft: '70px' }}
      />
      <TextBlock rows={1} widths={[75]} color="lightGray" />
      <TextBlock rows={1} widths={[200]} color="lightGray" />
      <TextBlock
        rows={1}
        widths={[60]}
        color="lightGray"
        style={{ marginLeft: '70px' }}
      />
    </BannerWrapper>
    {/* TODO: PRINTER BUTTON DESCOPED FROM MVP */}
    {/* <ButtonWrapper> */}
    {/* <RectShape
        color="lightGray"
        style={{
          width: '70px',
          height: '35px',
          borderRadius: '20px',
          backgroundColor: 'lightGray',
        }}
      /> */}
    {/* </ButtonWrapper> */}
  </OrderSummaryBannerWrapper>
);
