import * as faker from 'faker';
import { Address } from '../types/Address';
import { isBoolean } from '../utils/isBoolean/isBoolean';

export interface AddressOptions {
  addressDescription?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  city?: string;
  region?: string;
  countryCode?: string;
  externalAddressId?: string;
  id?: string;
  isWarehouse?: boolean;
  isInternal?: boolean;
  name?: string;
  organisationId?: string;
  postcode?: string;
}

export const createAddress = ({
  addressDescription,
  addressLine1,
  addressLine2,
  addressLine3,
  city,
  region,
  countryCode,
  externalAddressId,
  id,
  isWarehouse,
  isInternal,
  name,
  organisationId,
  postcode,
}: AddressOptions = {}): Address => {
  return {
    addressDescription: addressDescription || faker.random.words(5),
    addressLine1: addressLine1 || faker.address.streetAddress(),
    addressLine2: addressLine2 || faker.address.secondaryAddress(),
    addressLine3: addressLine3 || faker.random.words(3),
    city: city || faker.address.city(),
    region: region || faker.address.state(),
    countryCode: countryCode || faker.random.word(),
    externalAddressId: externalAddressId || faker.datatype.uuid(),
    id: id || faker.datatype.uuid(),
    isWarehouse: isBoolean(isWarehouse) ? isWarehouse : false,
    isInternal: isBoolean(isInternal) ? isInternal : false,
    name: name || faker.random.words(4),
    organisationId: organisationId || faker.datatype.uuid(),
    postcode: postcode || faker.address.zipCode(),
  };
};
