// TODO: think of solution with enums
export const SOURCEFUL_PROVIDER_TYPE = 'sourceful';
export const STANDALONE_PROVIDER_TYPE = 'standalone';

export const SOURCEFUL_ORDER_PROCESSING_TYPE = 'sourceful';
export const STANDALONE_ORDER_PROCESSING_TYPE = 'standalone';
export const STANDALONE_PRODUCTS_SOURCE = 'standalone';

export const MODAL_ID_QUERY_PARAM_NAME = 'modalId';
export const PRODUCT_ID_QUERY_PARAM_NAME = 'productId';

export const UK_LOCALE = 'en-GB';
export const US_LOCALE = 'en-US';
export const UK_CURRENCY = 'GBP';
export const US_CURRENCY = 'USD';

export const UK_COUNTRY_CODE = 'GB';
export const US_COUNTRY_CODE = 'US';
