import React, { PropsWithChildren, ReactElement } from 'react';
import { DefaultLayout } from '../Layouts/DefaultLayout/DefaultLayout';
import RequireAuth from '../RequireAuth/RequireAuth';

export type Layout = (props: PropsWithChildren<unknown>) => ReactElement;

interface Props {
  children: React.ReactNode;
  layout?: Layout;
}

export const PrivateRoute = ({
  children,
  layout: Layout = DefaultLayout,
}: Props) => (
  <RequireAuth>
    <Layout>{children}</Layout>
  </RequireAuth>
);
