/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import {
  Button,
  ButtonRadii,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  Label,
  LabelSize,
  LabelWeight,
  Tooltip as TooltipComponent,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { UK_CURRENCY } from '../../../constants';
import { getProductStockLevel } from '../../../hooks/getProductStockLevel/GetProductStockLevel';
import { useOrganisationsContext } from '../../../providers/UserOrganisationsProvider/UserOrganisationsProvider';
import {
  WarningButtonColourOverride,
  InfoButtonColourOverride,
} from '../../../styles/SharedStyles';
import { Product } from '../../../types/Product';
import { getNumberWithCommas } from '../../../utils/getNumberWithCommas/getNumberWithCommas';
import { getPrice } from '../../../utils/getPrice/getPrice';
import { DoubleDisplay } from '../../DoubleDisplay/DoubleDisplay';
import { FormControl } from '../../Form/FormControl/FormControl';
import {
  OrderLineTableColumn,
  OrderLineTableRow,
} from '../OrderLinesTable/OrderLinesTableStyled';
import NumberInput from './NumberInput';
import { CardImage, ActionsWrapper } from './OrderProductRowStyled';
import { getAllMoqPricesSortedDescending } from '../../../utils/getMoqPrice/getMoqPrice';
import { ProductPrice } from '../../../types/ProductPrice';

export interface Props {
  onNumberOfPacksChange?: (value: number) => void;
  onProductDelete?: () => void;
  onProductEdit?: () => void;
  productName: string;
  productImageUrl?: string | null;
  numberOfPacks: number;
  numberOfUnits: number;
  cost: number;
  unitCost: number;
  overflowQuantity?: number;
  isEditing?: boolean;
  product: Product;
  isFirstRow: boolean;
  isLastRow: boolean;
  hasTariff?: boolean;
  currencyCode?: string;
}

export const OrderProductRow = ({
  onNumberOfPacksChange,
  onProductDelete,
  onProductEdit,
  productImageUrl,
  productName,
  product,
  numberOfPacks,
  numberOfUnits,
  overflowQuantity = 0,
  cost,
  unitCost,
  isEditing = true,
  isFirstRow,
  isLastRow,
  hasTariff = false,
  currencyCode,
}: Props) => {
  const { t } = useTranslation();
  const [hover, setHover] = useState<boolean>(false);
  const { currentOrganisation } = useOrganisationsContext();
  const currency =
    currencyCode || currentOrganisation?.defaultCurrency || UK_CURRENCY;

  const productHasMoqPricing: boolean =
    product?.productPrices.some(
      productPrice =>
        !!(productPrice.minOrderQuantity && productPrice.minOrderQuantity > 1)
    ) ?? false;

  const moqPrices: ProductPrice[] = getAllMoqPricesSortedDescending(
    product.productPrices
  );

  const minOrderQuantityForMoqProducts: number = productHasMoqPricing
    ? moqPrices[moqPrices.length - 1].minOrderQuantity || 1
    : 1;

  const handleRemove = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onProductDelete) {
      onProductDelete();
    }
  };

  const handleEdit = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (onProductEdit) {
      onProductEdit();
    }
  };

  const handleChange = (value: string) => {
    onNumberOfPacksChange &&
      onNumberOfPacksChange(Number(parseInt(value, 10).toString()));
  };

  const costValue = getPrice(cost, currency);
  const unitCostValue = getPrice(unitCost, currency, 3);

  const imageUrl = productImageUrl || 'https://via.placeholder.com/48';

  const Tooltip: any = TooltipComponent;

  const hasOverflow = overflowQuantity > 0;
  const totalUnits = (product.unitQuantity || 1) * numberOfPacks;

  const isUnderMoq =
    productHasMoqPricing && totalUnits < minOrderQuantityForMoqProducts;

  return (
    <OrderLineTableRow
      default
      firstRow={isFirstRow}
      lastRow={isLastRow}
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <OrderLineTableColumn
        style={{
          flex: '1 5 49%',
        }}
      >
        <CardImage src={imageUrl} />
        <Label
          size={LabelSize.Medium}
          css={{
            color: '$colour-brand-neutral-hue-1-shade-1',
            paddingLeft: '$spacing-fluid-inline-small',
          }}
          weight={LabelWeight['Semi-Bold']}
        >
          {productName}
        </Label>
      </OrderLineTableColumn>
      <OrderLineTableColumn
        style={
          currentOrganisation?.config?.prepaidStock
            ? { flex: '1 2 20%', justifyContent: 'flex-end' }
            : { flex: '1 2 14%' }
        }
      >
        {isEditing ? (
          <div
            style={{
              display: 'flex',
            }}
          >
            <DoubleDisplay
              css={{
                rowGap: 4,
                flexDirection: 'column',
              }}
              left={
                <FormControl noColumnGap css={{ justifyContent: 'end' }}>
                  <NumberInput
                    error={hasOverflow || isUnderMoq}
                    value={numberOfPacks.toString()}
                    onBlur={(inputValue: string) => {
                      handleChange(inputValue);
                    }}
                    onChange={(inputValue: string) => {
                      handleChange(inputValue);
                    }}
                    minimum={minOrderQuantityForMoqProducts}
                  />
                </FormControl>
              }
              right={
                <Label
                  size={LabelSize.Small}
                  css={
                    hasOverflow
                      ? {
                          color: '$colour-brand-red-hue-1-base',
                          fontFamily: '$font-latin-sans-serif-1',
                          display: 'flex',
                        }
                      : {
                          color: '$colour-brand-neutral-hue-1-shade-1',
                          fontFamily: '$font-latin-sans-serif-1',
                        }
                  }
                >
                  {hasOverflow && (
                    <>
                      <Icon
                        size={IconSize['Static XXX-Small']}
                        name="icon-alert-exclamation-outline"
                      />
                      &nbsp;
                      {t('viewBasket.stockOverflowNotification', {
                        amountOfPacksInBasket:
                          getProductStockLevel(product).numberOfPacks +
                          overflowQuantity,
                        amountOfPacks:
                          getProductStockLevel(product).numberOfPacks,
                      })}
                    </>
                  )}
                  {!hasOverflow &&
                    !isUnderMoq &&
                    t('orderDetails.numberOfUnits', {
                      amountOfUnits: getNumberWithCommas(numberOfUnits),
                    })}
                </Label>
              }
            />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
            }}
          >
            <DoubleDisplay
              css={{
                rowGap: 4,
                flexDirection: 'column',
              }}
              left={
                <Label
                  size={LabelSize.Large}
                  css={{
                    color: '$colour-brand-neutral-hue-1-shade-1',
                    fontFamily: '$font-latin-sans-serif-1',
                  }}
                >
                  {t('orderDetails.numberOfPacks', {
                    count: numberOfPacks,
                  })}
                </Label>
              }
              right={
                <Label
                  size={LabelSize.Small}
                  css={{
                    color: '$colour-brand-neutral-hue-1-shade-1',
                    fontFamily: '$font-latin-sans-serif-1',
                  }}
                >
                  {t('orderDetails.numberOfUnits', {
                    amountOfUnits: getNumberWithCommas(numberOfUnits),
                  })}
                </Label>
              }
            />
          </div>
        )}
      </OrderLineTableColumn>
      {!currentOrganisation?.config?.prepaidStock &&
        currentOrganisation?.config?.displayProductUnitCost && (
          <OrderLineTableColumn
            style={{
              flex: '1 2 14%',
              justifyContent: 'flex-end',
              alignSelf: 'space-between',
            }}
          >
            <DoubleDisplay
              css={{
                rowGap: 4,
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
              left={
                <Label
                  css={{
                    fontFamily: '$font-latin-sans-serif-1',
                  }}
                  size={LabelSize.Medium}
                >
                  {unitCostValue}
                </Label>
              }
              right={
                <Label
                  size={LabelSize.Small}
                  css={{
                    color: '$colour-brand-neutral-hue-1-shade-1',
                    fontFamily: '$font-latin-sans-serif-1',
                  }}
                >
                  {hasTariff
                    ? t('orderDetails.exclTaxes')
                    : t('orderDetails.exclVAT')}
                </Label>
              }
            />
          </OrderLineTableColumn>
        )}
      {!currentOrganisation?.config?.prepaidStock && (
        <OrderLineTableColumn
          style={{
            flex: '1 2 14%',
            justifyContent: 'flex-end',
            alignSelf: 'space-between',
          }}
        >
          <DoubleDisplay
            css={{
              rowGap: 4,
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
            left={
              <Label
                css={{
                  fontFamily: '$font-latin-sans-serif-1',
                }}
                size={LabelSize.Medium}
              >
                {costValue}
              </Label>
            }
            right={
              <Label
                size={LabelSize.Small}
                css={{
                  color: '$colour-brand-neutral-hue-1-shade-1',
                  fontFamily: '$font-latin-sans-serif-1',
                }}
              >
                {hasTariff
                  ? t('orderDetails.exclTaxes')
                  : t('orderDetails.exclVAT')}
              </Label>
            }
          />
        </OrderLineTableColumn>
      )}

      <OrderLineTableColumn
        style={{
          display: 'flex',
          flex: '1 1 10%',
          justifyContent: 'flex-end',
        }}
      >
        {isEditing && (
          <ActionsWrapper css={!hover ? { visibility: 'hidden' } : {}}>
            <Tooltip
              trigger={
                <Button
                  variant={ButtonVariant.Secondary}
                  size={ButtonSize['XX-Small']}
                  radii={ButtonRadii.Small}
                  onClick={handleEdit}
                  css={{
                    paddingInlineStart: '$spacing-static-inline-x-small',
                    paddingInlineEnd: '$spacing-static-inline-x-small',
                  }}
                  colourOverride={
                    hover
                      ? InfoButtonColourOverride
                      : {
                          default: {
                            color: '$colour-lightest',
                            backgroundColor: '$colour-lightest',
                          },
                        }
                  }
                >
                  <Icon
                    size={IconSize['Static XXX-Small']}
                    name="icon-document-pencil"
                  />
                </Button>
              }
              contentText={t('viewBasket.tooltipEditProduct')}
            />
            <Tooltip
              trigger={
                <Button
                  variant={ButtonVariant.Secondary}
                  size={ButtonSize['XX-Small']}
                  radii={ButtonRadii.Small}
                  onClick={handleRemove}
                  css={{
                    paddingInlineStart: '$spacing-static-inline-x-small',
                    paddingInlineEnd: '$spacing-static-inline-x-small',
                  }}
                  colourOverride={
                    hover
                      ? WarningButtonColourOverride
                      : {
                          default: {
                            color: '$colour-lightest',
                            backgroundColor: '$colour-lightest',
                          },
                        }
                  }
                >
                  <Icon
                    size={IconSize['Static XXX-Small']}
                    name="icon-navigation-trash"
                  />
                </Button>
              }
              contentText={t('viewBasket.tooltipDeleteProduct')}
            />
          </ActionsWrapper>
        )}
      </OrderLineTableColumn>
    </OrderLineTableRow>
  );
};
