import { UK_CURRENCY, UK_LOCALE } from '../../constants';

export const formatPrice = (
  locale: typeof UK_LOCALE | string,
  currency: typeof UK_CURRENCY | 'EUR' | string,
  number: number
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(number);
};
