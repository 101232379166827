import { ReactNode } from 'react';
import { styled } from '@sourceful/design-system';
import { CSSProperties } from '../../types/CSS';

export const DoubleDisplayStyled = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

export interface Props {
  left: ReactNode;
  right: ReactNode;
  css?: CSSProperties;
}

export const DoubleDisplay = ({ left, right, css }: Props) => {
  return (
    <DoubleDisplayStyled style={css}>
      {left}
      {right}
    </DoubleDisplayStyled>
  );
};
