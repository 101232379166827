import {
  useGetCurrentOrganisationClientOrderQuery,
  // ClientOrder as ClientOrderGraphqlType,
} from '../../../../../../shared/graphql/types/generated';
import { useUserContext } from '../../../../../../shared/providers/UserProvider/UserProvider';
import { ClientOrder } from '../../../../../../shared/types/ClientOrder';
import { mapClientOrder } from '../../mappers';

interface HookResult {
  clientOrder: ClientOrder | null;
  clientOrderOrderLoading: boolean;
  clientOrderOrderError?: unknown;
}

const useCurrentOrganisationClientOrder = (
  clientOrderId: string
): HookResult => {
  const { currentOrganisationId } = useUserContext();

  const { isLoading, error, data } = useGetCurrentOrganisationClientOrderQuery({
    clientOrderId,
    currentOrganisationId, // this is to retry the http request when currentOrganisationId changes
  } as never);

  // @TODO: fix this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const clientOrder: any = data?.getCurrentOrganisationClientOrder
    ? data?.getCurrentOrganisationClientOrder
    : null;

  return {
    clientOrderOrderLoading: isLoading,
    clientOrderOrderError: error,
    clientOrder: clientOrder ? mapClientOrder(clientOrder) : null,
  };
};

export default useCurrentOrganisationClientOrder;
