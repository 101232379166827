import {
  Title,
  TitleSize,
  TitleLineHeight,
  GridContainer,
  Grid,
  styled,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { AlertBanner } from '../../../../shared/components/AlertBanner/AlertBanner';
import { TitleWrapper } from '../../../../shared/components/Nav/NavStyled';
import { UK_LOCALE, US_CURRENCY } from '../../../../shared/constants';
import { useOrganisationsContext } from '../../../../shared/providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { useUserContext } from '../../../../shared/providers/UserProvider/UserProvider';
import TableRow from '../components/TableRow';
import TableRowSkeleton from '../components/TableRowSkeleton';
import useCurrentOrganisationClientOrders from '../graphql/hooks/useCurrentOrganisationClientOrders';
import { TableContainer, TableHeader } from './ViewClientOrdersStyled';
// responsive table styling: https://codepen.io/team/css-tricks/pen/wXgJww?editors=1100

const NoOrdersAlert = styled('div', {});

export const ViewClientOrders = () => {
  const { t } = useTranslation();
  const { clientOrdersLoading, clientOrdersError, clientOrders } =
    useCurrentOrganisationClientOrders();
  const { currentOrganisation } = useOrganisationsContext();
  const currencyCode = currentOrganisation?.defaultCurrency || UK_LOCALE;

  const { isSwitchingOrgs } = useUserContext();

  const isLoading = clientOrdersLoading || isSwitchingOrgs;

  return (
    <>
      <TitleWrapper>
        <GridContainer>
          <Grid columns={{ '@initial': 1 }}>
            <Title
              size={TitleSize.Medium}
              lineHeight={TitleLineHeight.Normal}
              as="h2"
            >
              {t('viewClientOrders.orders')}
            </Title>
          </Grid>
        </GridContainer>
      </TitleWrapper>

      {clientOrdersError ? (
        <GridContainer>
          <Grid columns={{ '@initial': 1 }}>
            <AlertBanner
              firstLineMessage="viewClientOrders.errorAlert"
              variant="error"
            />
          </Grid>
        </GridContainer>
      ) : null}

      {!clientOrdersError && !isLoading && clientOrders.length === 0 && (
        <GridContainer>
          <Grid gapY={'medium'} columns={{ '@initial': 1 }}>
            <NoOrdersAlert>
              {' '}
              {t('viewClientOrders.dontHaveOrdersAlert')}
            </NoOrdersAlert>
          </Grid>
        </GridContainer>
      )}

      {Boolean(isLoading || clientOrders.length) && (
        <div
          style={{
            overflowX: 'auto',
          }}
        >
          <GridContainer>
            <Grid columns={{ '@initial': 1 }}>
              <TableContainer>
                <thead>
                  <tr>
                    <TableHeader>
                      {t('viewClientOrders.tableHeader.orderId')}
                    </TableHeader>
                    <TableHeader>
                      {t('viewClientOrders.tableHeader.status')}
                    </TableHeader>
                    <TableHeader>
                      {t('viewClientOrders.tableHeader.placedOn')}
                    </TableHeader>
                    <TableHeader>
                      {t('viewClientOrders.tableHeader.deliveryDate')}
                    </TableHeader>
                    <TableHeader>
                      {t('viewClientOrders.tableHeader.toWhichLocation')}
                    </TableHeader>
                    <TableHeader>
                      {t('viewClientOrders.tableHeader.products')}
                    </TableHeader>
                    <TableHeader>
                      {currencyCode === US_CURRENCY
                        ? t('viewClientOrders.tableHeader.totalInclTaxes')
                        : t('viewClientOrders.tableHeader.totalInclVAT')}
                    </TableHeader>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    clientOrders.map((clientOrder, index) => (
                      <TableRow key={index} clientOrder={clientOrder} />
                    ))}
                  {isLoading && <TableRowSkeleton amountOfRows={3} />}
                </tbody>
              </TableContainer>
            </Grid>
          </GridContainer>
        </div>
      )}
    </>
  );
};
