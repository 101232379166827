import { styled } from '@sourceful/design-system';
import { BREAKPOINT_L, BREAKPOINT_XL } from '../../constants/styles';

export const ClientReferenceWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

export const UploadedAttachmentsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

export const AttachmentsUploaderWrapper = styled('div', {
  marginTop: '$spacing-static-block-x-small',
});

export const AttachmentsWrapper = styled('div', {
  marginTop: '$spacing-static-block-medium',
  marginBottom: '$spacing-static-block-medium',
  width: '50%',
  [BREAKPOINT_XL]: { width: '75%' },
  [BREAKPOINT_L]: { width: '100%' },
});

export const InvoiceDetailsWrapper = styled('div', {
  marginBottom: '$spacing-static-block-medium',
});

export const CollapsibleHeaderWrapper = styled('div', {
  display: 'flex',
  flex: 1,
});
