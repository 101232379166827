import React, { createContext, useContext } from 'react';
import useCurrentOrganisationProducts from '../../../modules/products/viewProducts/graphql/hooks/useCurrentOrganisationProducts/useCurrentOrganisationProducts';
import { createStore, Store } from '../../stores';
import { Address } from '../../types/Address';
import { Organisation } from '../../types/Organisation';
import { User } from '../../types/User';
import { getTaxRate } from '../../utils/getTaxRate/getTaxRate';
import { useOrganisationsContext } from '../UserOrganisationsProvider/UserOrganisationsProvider';
import { useUserContext } from '../UserProvider/UserProvider';
import {
  BasketState,
  createDefaultBasketState,
  useBasket,
  UseBasketResult,
} from './useBasket';

export interface Options {
  children: React.ReactElement;
}

export type NumberOfDays = number;

export enum DeliveryTimelineType {
  DAYS_IN_RANGE = 'daysInRange',
  DAYS = 'days',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DeliveryTimelineValue = any;
// TODO: find better way of retrieving conditional types
// | { minDays: NumberOfDays; maxDays: NumberOfDays, estimatedDeliveryDate }
// | { minDays: NumberOfDays };

export interface DeliveryTimeline {
  contractualExpectedDeliveryDate?: Date;
  min?: null | number;
  max?: null | number;
  units?: null | string;
  businessDaysOnly?: null | boolean;
}
export interface DeliveryInfo {
  addressId: string;
  deliveryFee: number;
  deliveryTimeline: DeliveryTimeline;
}
export interface BasketContextInterface extends UseBasketResult {
  deliveryInformations: DeliveryInfo[];
}

const DEFAULT_CONTEXT: BasketContextInterface = {
  ...createDefaultBasketState(),
  addProductToBasket: () => null,
  editProductInBasket: () => null,
  markProductAsDeletedInBasket: () => null,
  markProductAsNonDeletedInBasket: () => null,
  isProductInBasket: () => false,
  getProductAddressesLines: () => [],
  removeMarkAsDeletedProducts: () => null,
  removeBasketOrderByAddressId: () => null,
  removeProductFromAddress: () => null,
  editProductQuantityInBasket: () => null,
  handleClientReferenceChange: () => null,
  handleAddAttachment: () => null,
  handleRemoveAttachment: () => null,
  resetBasketState: () => null,
  deliveryInformations: [],
};

export const BasketContext =
  createContext<BasketContextInterface>(DEFAULT_CONTEXT);

export const useBasketContext = () => useContext(BasketContext);

export const BASKET_KEY_NAME = '__stock_management_basket__';

export const basketStore: Store<BasketState> = createStore<BasketState>();

export interface GetBasketKeyOptions {
  organisation?: null | Organisation;
  user?: null | User;
}

const getBasketKey = ({ organisation, user }: GetBasketKeyOptions) => {
  if (!organisation?.id || !user?.id) {
    return null;
  }

  return `${BASKET_KEY_NAME}_${organisation.id}_${user.id}`;
};

export const BasketProvider = ({ children, ...props }: Options) => {
  const { products, productsLoading } = useCurrentOrganisationProducts();

  const { currentOrganisation } = useOrganisationsContext();
  const { user } = useUserContext();

  const isLoadingBasket = productsLoading || !currentOrganisation;

  const addresses = Array.isArray(currentOrganisation?.addresses)
    ? currentOrganisation?.addresses
    : [];

  const deliveryInformations = (addresses as Address[]).map(address => ({
    addressId: address.id as string,
    deliveryFee: address.deliveryFee as number,
    deliveryTimeline: address.deliveryTimeline as DeliveryTimeline,
  }));

  const VAT =
    getTaxRate(currentOrganisation?.addresses[0].taxRates, 'VAT').taxValue || 0;
  const tariff =
    getTaxRate(currentOrganisation?.addresses[0].taxRates, 'Tariff').taxValue ||
    0;
  const currencyCode = currentOrganisation?.defaultCurrency || 'GBP';

  const basketKey = getBasketKey({ organisation: currentOrganisation, user });

  const initialValue =
    !isLoadingBasket && basketKey ? basketStore.getItem(basketKey) : undefined;

  const basket = useBasket({
    products,
    deliveryInformations,
    initialValue,
    basketKey,
    computeDeliveryCostPerProduct:
      currentOrganisation?.config?.computeDeliveryCostPerProduct,
    onBasketChange: (basketState: BasketState) => {
      basketKey && basketStore.setItem(basketKey, basketState);
    },
    currencyCode,
    VAT,
    tariff,
  });

  return (
    <BasketContext.Provider
      value={{
        ...basket,
        deliveryInformations,
      }}
      {...props}
    >
      {children}
    </BasketContext.Provider>
  );
};

export default BasketProvider;
