import React, { createContext, useContext } from 'react';
import useCurrentOrganisation from '../../graphql/hooks/useCurrentOrganisation/useCurrentOrganisation';
import { Organisation } from '../../types/Organisation';
import { useUserContext } from '../UserProvider/UserProvider';

export interface Options {
  children: React.ReactElement;
}

export interface UserOrganisationsContext {
  currentOrganisation: Organisation | null;
  refetchCurrentOrganisation: () => void;
}

const DEFAULT_CONTEXT = {
  currentOrganisation: null,
  refetchCurrentOrganisation: () => null,
};

export const OrganisationsContext =
  createContext<UserOrganisationsContext>(DEFAULT_CONTEXT);

export const useOrganisationsContext = () => useContext(OrganisationsContext);

export const UserOrganisationsProvider = ({ children, ...props }: Options) => {
  const { currentOrganisationId } = useUserContext();
  const {
    currentOrganisation,
    currentOrganisationLoading,
    refetchCurrentOrganisation,
  } = useCurrentOrganisation({
    currentOrganisationId,
  });

  return (
    <OrganisationsContext.Provider
      value={{
        currentOrganisation: currentOrganisationLoading
          ? null
          : currentOrganisation,
        refetchCurrentOrganisation,
      }}
      {...props}
    >
      {children}
    </OrganisationsContext.Provider>
  );
};

export default UserOrganisationsProvider;
