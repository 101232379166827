import { ClientOrder as ClientOrderGraphqlType } from '../../../../../shared/graphql/types/generated';
import { BasicClientOrder } from '../../../../../shared/types/ClientOrder';

export const mapBasicClientOrder = (
  clientOrder: ClientOrderGraphqlType
): BasicClientOrder => {
  const clientOrderStatus = clientOrder.status
    ? clientOrder.status.name
    : 'Submitted';

  const expectedDeliveryDate = clientOrder?.contractualExpectedDeliveryDate
    ? clientOrder?.contractualExpectedDeliveryDate
    : '';

  return {
    id: clientOrder?.id,
    externalClientOrderId: clientOrder?.externalClientOrderId,
    addressName: clientOrder.address?.name || '',
    numberOfProducts: clientOrder?.clientOrderLines?.length || 0,
    createdAt: clientOrder?.createdAt,
    expectedDeliveryDate: expectedDeliveryDate,
    status: clientOrder?.isDraft ? 'Draft' : clientOrderStatus,
    clientOrderSummary: {
      deliveryValueExcludingVAT:
        clientOrder?.clientOrderSummary?.deliveryValueExcludingVAT,
      productsSubtotal: clientOrder?.clientOrderSummary?.productsSubtotal,
      orderSubtotal: clientOrder?.clientOrderSummary?.orderSubtotal,
      tariffPercentage: clientOrder?.clientOrderSummary?.tariffPercentage,
      tariffValue: clientOrder?.clientOrderSummary?.tariffValue,
      totalIncludingTaxes: clientOrder?.clientOrderSummary?.totalIncludingTaxes,
      vatPercentage: clientOrder?.clientOrderSummary?.vatPercentage,
      vatValue: clientOrder?.clientOrderSummary?.vatValue,
      currencyCode: clientOrder?.clientOrderSummary?.currencyCode,
    },
  };
};
