import { styled } from '@sourceful/design-system';
import { Link } from 'react-router-dom';
import { LinkText, Text } from '../../styles/SharedStyles';

const NavigationBannerWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '25px',
  '@media print': {
    display: 'none',
  },
});

interface NavigationBannerProps {
  previousPageName: string;
  currentPageName: string;
  previousPageLink: string;
}

export const NavigationBanner = ({
  previousPageName,
  currentPageName,
  previousPageLink,
}: NavigationBannerProps) => {
  return (
    <NavigationBannerWrapper>
      <Link to={previousPageLink}>
        <LinkText>{previousPageName}</LinkText>
      </Link>
      <Text>/</Text>
      <Text>{currentPageName}</Text>
    </NavigationBannerWrapper>
  );
};
