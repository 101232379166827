import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  onError?: (error: Error, errorInfo: ErrorInfo) => void | Promise<void>;
}

interface State {
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (this.props.onError) {
      await this.props.onError(error, errorInfo);
    }
  }

  getValue(): string {
    let result = '';
    if (this.state.error) {
      result = `${this.state.error.message}\n\n${this.state.error?.stack}`;
    }
    return result;
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <div>
          <h4>Whoops! It looks like there has been an error.</h4>
          <p style={{ textOverflow: 'wrap' }}>
            This is likely a bug. Please contact the administrator and include
            the error details below.
          </p>

          <details>
            <summary>
              <b>Summary</b>
            </summary>

            <br />

            <textarea
              value={this.getValue()}
              style={{ width: '100%', height: 300 }}
              disabled
            />
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
