import {
  Label,
  LabelSize,
  LabelWeight,
  Icon,
  IconSize,
  Tooltip,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import {
  getTotalMaximumDeliveryTime,
  getTotalMinimumDeliveryTime,
} from '../../../../../shared/components/Orders/utils';
import { UK_CURRENCY, UK_LOCALE } from '../../../../../shared/constants';
import { useOrganisationsContext } from '../../../../../shared/providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { Product } from '../../../../../shared/types/Product';
import { getNumberOrZero } from '../../../../../shared/utils/getNumberOrZero/getNumberOrZero';
import { getNumberWithCommas } from '../../../../../shared/utils/getNumberWithCommas/getNumberWithCommas';
import { getProductPrice } from '../../../../../shared/utils/getProductPrice/getProductPrice';
import { subheaderData } from '../../../../products/viewProducts/utils';
import {
  ProductTable,
  ProductTableRow,
  TemporaryIconTextWrapper,
  ProductTableColumn,
  TextColumn,
  CardImage,
} from '../AddProductToBasketModal/AddProductToBasketModal.styled';

export interface Props {
  product: Product;
  quantityInBasketForMoq: number;
  productHasMoqPricing: boolean;
}

const ProductCard = ({
  product,
  quantityInBasketForMoq,
  productHasMoqPricing,
}: Props) => {
  const { t } = useTranslation();
  const { currentOrganisation } = useOrganisationsContext();

  let internalProductStockQuantity = 0;

  if (product && product.productStocks.length > 0) {
    const productStockWithInternalAddress = product.productStocks.find(
      productStock => productStock.address?.isInternal
    );
    if (productStockWithInternalAddress) {
      internalProductStockQuantity =
        productStockWithInternalAddress.quantity || 0;
    }
  }

  const productStockPacks = Math.floor(
    internalProductStockQuantity / (product?.unitQuantity || 1)
  );

  const ProductStockOrDeliveryInfoIcon =
    product.productType?.name === 'Warehouse'
      ? 'icon-navigation-location-sourceful'
      : 'icon-shopping-shipping';

  const minDeliveryTimesForAllAddresses = currentOrganisation?.addresses.map(
    address => getNumberOrZero(address.deliveryTimeline?.min)
  );
  const minDeliveryTime = minDeliveryTimesForAllAddresses
    ? Math.min(...minDeliveryTimesForAllAddresses)
    : 1;
  const maxDeliveryTimesForAllAddresses = currentOrganisation?.addresses.map(
    address => getNumberOrZero(address.deliveryTimeline?.max)
  );
  const maxDeliveryTime = maxDeliveryTimesForAllAddresses
    ? Math.max(...maxDeliveryTimesForAllAddresses)
    : 2;

  const ProductStockOrDeliveryInfoText =
    product.productType?.name === 'Warehouse' ? (
      <>
        {t('addProductToBasket.amountOfUnits', {
          amountOfUnits: getNumberWithCommas(internalProductStockQuantity),
          count: internalProductStockQuantity,
        })}{' '}
        {t('addProductToBasket.amountOfPacks', {
          amountOfPacks: productStockPacks,
          count: productStockPacks,
        })}
      </>
    ) : (
      <Tooltip
        trigger={
          <span>
            {t('viewProducts.deliveryLeadTime', {
              min: getTotalMinimumDeliveryTime(minDeliveryTime, [
                getNumberOrZero(product.manufacturingLeadTime?.min),
              ]),
              max: getTotalMaximumDeliveryTime(maxDeliveryTime, [
                getNumberOrZero(product.manufacturingLeadTime?.max),
              ]),
              durationType: product.manufacturingLeadTime?.units,
            })}
          </span>
        }
        contentText={
          subheaderData(product, currentOrganisation?.addresses)
            ?.subheaderToolTipText
        }
      />
    );

  const productPrice = product?.unitQuantity
    ? getProductPrice({
        unitQuantity: productHasMoqPricing
          ? quantityInBasketForMoq
          : product?.unitQuantity,
        prices: product.productPrices,
        locale: currentOrganisation?.locale || UK_LOCALE,
        currencyCode: currentOrganisation?.defaultCurrency || UK_CURRENCY,
      })
    : null;

  return (
    <ProductTable>
      <ProductTableRow
        default
        firstRow="true"
        lastRow="true"
        compact="true"
        css={{ marginBottom: '$spacing-fluid-block-small' }}
      >
        <ProductTableColumn largerWidth>
          {product?.imageUrl !== undefined && product?.imageUrl !== null && (
            <CardImage src={product.imageUrl} />
          )}
          <TextColumn
            css={{
              paddingLeft: '$spacing-fluid-block-small',
            }}
            isPrepaidStock={currentOrganisation?.config?.prepaidStock}
          >
            <TemporaryIconTextWrapper>
              <Icon
                size={IconSize['Static XXX-Small']}
                name={ProductStockOrDeliveryInfoIcon}
                css={{
                  color: '$colour-brand-neutral-hue-1-base',
                }}
              ></Icon>
              <Label
                size={LabelSize.Small}
                weight={LabelWeight['Regular']}
                css={{
                  color: '$colour-brand-neutral-hue-1-base',
                  marginLeft: '4px',
                }}
              >
                {ProductStockOrDeliveryInfoText}
              </Label>
            </TemporaryIconTextWrapper>
            <Label
              size={LabelSize.Medium}
              css={{
                color: '$colour-brand-neutral-hue-1-shade-1',
              }}
              weight={LabelWeight['Semi-Bold']}
            >
              {product.name}
            </Label>
            {!currentOrganisation?.config?.prepaidStock && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Label
                  size={LabelSize.Small}
                  weight={LabelWeight['Regular']}
                  css={{ color: '$colour-brand-neutral-hue-1-base' }}
                >
                  {productPrice &&
                    t('addProductToBasket.pricePerPack', {
                      price: productHasMoqPricing
                        ? productPrice.pricePerUnit
                        : productPrice.price,
                    })}
                </Label>
                {currentOrganisation?.config?.displayProductUnitCost && (
                  <Label
                    size={LabelSize.Small}
                    weight={LabelWeight['Regular']}
                    css={{
                      color: '$colour-brand-neutral-hue-1-base',
                      marginLeft: '$spacing-static-inline-x-small',
                    }}
                  >
                    {productPrice &&
                      t('addProductToBasket.pricePerUnit', {
                        pricePerUnit: productPrice.pricePerUnit,
                      })}
                  </Label>
                )}
              </div>
            )}
          </TextColumn>
        </ProductTableColumn>
        <ProductTableColumn>
          <TextColumn
            isPrepaidStock={currentOrganisation?.config?.prepaidStock}
          >
            <TemporaryIconTextWrapper>
              <Icon
                size={IconSize['Static XXX-Small']}
                name="icon-products-box"
                css={{
                  color: '$colour-brand-neutral-hue-1-base',
                }}
              ></Icon>
              <Label
                size={LabelSize.Small}
                weight={LabelWeight['Regular']}
                css={{
                  color: '$colour-brand-neutral-hue-1-base',
                  marginLeft: '4px',
                }}
              >
                {product?.unitQuantity &&
                  t('addProductToBasket.amountOfUnitsInPackExplanation', {
                    amountOfUnits: getNumberWithCommas(product?.unitQuantity),
                    count: product?.unitQuantity,
                  })}
              </Label>
            </TemporaryIconTextWrapper>
          </TextColumn>
        </ProductTableColumn>
      </ProductTableRow>
    </ProductTable>
  );
};

export default ProductCard;
