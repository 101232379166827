/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useCallback } from 'react';
import {
  Button,
  ButtonRadii,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  GridContainer,
  Grid,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../providers/UserProvider/UserProvider';
import TemporarySelect from '../TemporarySelect/TemporarySelect';
import NavBasketDropdown from './NavBasketDropdown/NavBasketDropdown';
import { NavLink } from './NavLink';
import NavLogo from './NavLogo';
import NavProfile from './NavLogout';
import {
  ListItem,
  NavBottomBorder,
  NavWrapper,
  SelectWrapper,
  StyledNav,
  UnorderedList,
} from './NavStyled';

const colourOverride = {
  active: {
    color: '$colour-brand-blue-hue-1-base !important', // colour.ui.light.container.neutral.no-contrast.text.blue.lo
    backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
  },
  default: {
    color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
    backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
  },
  hover: {
    color: '$colour-brand-neutral-hue-1-shade-2', // colour.ui.light.container.neutral.xx-lo.text.neutral.hi
    backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
  },
  visited: {
    color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
    backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
  },
};

const responsiveButton = {
  paddingInlineStart: 'inherit',
  backgroundColor: '$colour-lightest !important',
};

export const Nav = () => {
  const [isResponsiveMenuOpen, setIsResponsiveMenuOpen] =
    useState<boolean>(false);
  const { isAuthenticated, setCurrentOrganisationId } = useUserContext();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { organisations } = useUserContext();

  // TODO: how to deal with personal orgs
  const filteredOrganisations = organisations.filter(
    organisation => !organisation.name?.startsWith('personal')
  );

  const items = filteredOrganisations.map(organisation => ({
    label: organisation.display_name,
    value: organisation.id,
  }));

  const { currentOrganisationId } = useUserContext();

  const handleOrganisationSwitch = useCallback(async (value: string) => {
    await setCurrentOrganisationId(value);
    setIsResponsiveMenuOpen(false);
  }, []);

  const setMenuClosed = useCallback(() => setIsResponsiveMenuOpen(false), []);
  const toggleMenuVisibility = useCallback(
    () => setIsResponsiveMenuOpen(!isResponsiveMenuOpen),
    [isResponsiveMenuOpen]
  );

  const navigateHome = useCallback(() => navigate('/'), []);

  return (
    <NavWrapper responsiveMenuOpen={isResponsiveMenuOpen}>
      <GridContainer>
        <Grid columns={{ '@initial': 1 }}>
          <StyledNav>
            <UnorderedList>
              <ListItem
                visibleInHeaderForResponsiveView={true}
                notIncludedInPrintView
                onClick={toggleMenuVisibility}
              >
                <Button
                  radii={ButtonRadii['X-Small']}
                  size={ButtonSize['X-Small']}
                  variant={ButtonVariant.Tertiary}
                  css={{
                    paddingInlineStart: '0',
                  }}
                  colourOverride={colourOverride}
                >
                  <Icon
                    size={IconSize['X-Small']}
                    name={
                      isResponsiveMenuOpen
                        ? 'icon-alert-cross-lrg'
                        : 'icon-navigation-menu'
                    }
                  />
                </Button>
              </ListItem>
              <ListItem onClick={navigateHome}>
                <NavLogo />
              </ListItem>
            </UnorderedList>

            <UnorderedList navigationLinks responsive={isResponsiveMenuOpen}>
              <ListItem
                visibleInHeaderForResponsiveView={false}
                responsive={isResponsiveMenuOpen}
                notIncludedInPrintView
                css={{
                  marginLeft: 48,
                }}
                onClick={setMenuClosed}
              >
                <NavLink
                  to="/product-catalogue"
                  matches={['/', '/product-catalogue']}
                  cssOverride={isResponsiveMenuOpen ? responsiveButton : {}}
                >
                  {t('navigation.products')}
                </NavLink>
              </ListItem>
              <ListItem
                visibleInHeaderForResponsiveView={false}
                responsive={isResponsiveMenuOpen}
                notIncludedInPrintView
                css={{
                  marginLeft: 16,
                }}
                onClick={setMenuClosed}
              >
                <NavLink
                  to="/orders"
                  cssOverride={isResponsiveMenuOpen ? responsiveButton : {}}
                >
                  {t('navigation.orders')}
                </NavLink>
              </ListItem>
              {currentOrganisationId && items.length > 1 && (
                <ListItem
                  visibleInHeaderForResponsiveView={false}
                  responsive={isResponsiveMenuOpen}
                  notIncludedInPrintView
                  right
                  borderTop
                >
                  <SelectWrapper>
                    <TemporarySelect
                      value={currentOrganisationId || ''}
                      onChange={handleOrganisationSwitch}
                      options={items}
                      dataCy="select-organisation-dropdown"
                      placeholder="Select organisation"
                    />
                  </SelectWrapper>
                </ListItem>
              )}
            </UnorderedList>

            <UnorderedList right notIncludedInPrintView>
              {isAuthenticated && (
                <NavBasketDropdown closeResponsiveMenu={setMenuClosed} />
              )}
              {isAuthenticated && <NavProfile />}
            </UnorderedList>
          </StyledNav>
          {isResponsiveMenuOpen && <NavBottomBorder />}
        </Grid>
      </GridContainer>
    </NavWrapper>
  );
};
