import { useCallback } from 'react';
import {
  Button,
  ButtonRadii,
  ButtonSize,
  ButtonVariant,
  CSS,
} from '@sourceful/design-system';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

export interface Props {
  to: string;
  matches?: string[];
  children: React.ReactNode;
  cssOverride?: CSS;
}

export const NavLink = ({ to, matches, children, cssOverride }: Props) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const active = (matches || [to]).some(path => {
    return matchPath(path, pathname);
  });
  const navigateToPath = useCallback(() => navigate(to), []);

  return (
    <Button
      size={ButtonSize.Medium}
      radii={ButtonRadii['X-Small']}
      variant={ButtonVariant.Tertiary}
      css={
        active
          ? {
              ...cssOverride,
              color: '$colour-brand-neutral-hue-1-shade-2 !important',
              backgroundColor: '$colour-lightest !important',
            }
          : { ...cssOverride }
      }
      onClick={navigateToPath}
      colourOverride={{
        active: {
          color: '$colour-brand-neutral-hue-1-shade-2', // colour.ui.light.container.neutral.xx-lo.text.neutral.hi
          backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
        },
        default: {
          color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
          backgroundColor: '$colour-lightest', // colour.ui.light.container.neutral.no-contrast.colour
        },
        hover: {
          color: '$colour-brand-neutral-hue-1-shade-2', // colour.ui.light.container.neutral.xx-lo.text.neutral.hi
        },
        visited: {
          color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
          backgroundColor: '$colour-lightest', // colour.ui.light.container.neutral.no-contrast.colour
        },
      }}
    >
      {children}
    </Button>
  );
};
