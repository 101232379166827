import { TextBlock } from 'react-placeholder/lib/placeholders';
import { TableContentRow, TableData } from '../page/ViewClientOrdersStyled';

interface TableRowSkeletonProps {
  amountOfRows: number;
}

const TableRowSkeleton = (props: TableRowSkeletonProps) => {
  return (
    <>
      {new Array(props.amountOfRows).fill(null).map((_, index) => (
        <TableContentRow key={index} className="show-loading-animation">
          {new Array(8).fill(null).map((_, index) => (
            <TableData key={index}>
              <TextBlock rows={1} color="lightGray" />
            </TableData>
          ))}
        </TableContentRow>
      ))}
    </>
  );
};

export default TableRowSkeleton;
