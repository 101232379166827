export const BREAKPOINT_XL = '@media (max-width: 1439px)';
export const MAX_WIDTH_XL = 1128;

export const BREAKPOINT_L = '@media (max-width: 1239px)';
export const MAX_WIDTH_L = 928;

export const BREAKPOINT_M = '@media (max-width: 1024px)';
export const MAX_WIDTH_M = 536;

export const BREAKPOINT_S = '@media (max-width: 599px)';
export const MAX_WIDTH_S = 328;
