import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogActionsContainer,
  AlertDialogCancel,
  AlertDialogDescription,
  AlertDialogTitle,
  Body,
  BodySize,
  Button,
  ButtonRadii,
  ButtonSize,
  ButtonVariant,
  IconTextWeight,
  Title,
  TitleSize,
  TitleWeight,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';

export interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onOpenChange: (isOpen: boolean) => void;
}

export const DeleteLocationAndProductsAlertDialog = ({
  isOpen,
  onConfirm,
  onOpenChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <AlertDialog
      isOpen={isOpen}
      onOpenChange={open => {
        onOpenChange(open);
      }}
    >
      <AlertDialogTitle asChild>
        <Title
          size={TitleSize.Small}
          weight={TitleWeight['Semi-Bold']}
          as="h2"
          css={{ fontFamily: '$font-latin-sans-serif-1' }}
        >
          {t('alertDialog.deleteLocationAndProductsTitle')}
        </Title>
      </AlertDialogTitle>
      <AlertDialogDescription asChild>
        <Body
          size={BodySize.Medium}
          css={{ fontFamily: '$font-latin-sans-serif-1' }}
        >
          {t('alertDialog.deleteLocationAndProducts')}
        </Body>
      </AlertDialogDescription>

      <AlertDialogActionsContainer>
        <AlertDialogCancel asChild>
          <Button
            size={ButtonSize.Large}
            variant={ButtonVariant.Secondary}
            radii={ButtonRadii.Small}
            css={{ fontFamily: '$font-latin-sans-serif-1' }}
            text={t('alertDialog.cancel')}
            textWeight={IconTextWeight['Semi-Bold']}
          />
        </AlertDialogCancel>

        <AlertDialogAction asChild>
          <Button
            size={ButtonSize.Large}
            variant={ButtonVariant.Primary}
            radii={ButtonRadii.Small}
            css={{ fontFamily: '$font-latin-sans-serif-1' }}
            text={t('alertDialog.delete')}
            textWeight={IconTextWeight['Semi-Bold']}
            onClick={onConfirm}
          />
        </AlertDialogAction>
      </AlertDialogActionsContainer>
    </AlertDialog>
  );
};
