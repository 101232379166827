import {
  Button,
  Label,
  LabelSize,
  LabelWeight,
  styled,
  Tooltip,
  ButtonVariant,
  ButtonSize,
  ButtonRadii,
  Icon,
  IconSize,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { BasketLine } from '../../../providers/BasketProvider/useBasket';
import { getNumberWithCommas } from '../../../utils/getNumberWithCommas/getNumberWithCommas';

const NavBasketDropdownProductCardStyled = styled('div', {
  display: 'flex',
  padding: '$spacing-fluid-block-x-small',
  justifyContent: 'space-between',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$colour-brand-neutral-hue-1-tint-4',
  borderRadius: '$radii-medium',
  variants: {
    noBottomBorder: {
      true: {
        borderWidth: '1px 1px 0 1px',
      },
    },
    noRadius: {
      true: {
        borderRadius: 0,
      },
    },
    noTopRadius: {
      true: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    noBottomRadius: {
      true: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
});

const OrderInfoWrapper = styled('div', {
  display: 'flex',
});

const IconTextWrapper = styled('div', {
  cursor: 'pointer',
  color: '$colour-brand-neutral-hue-1-shade-1',
});

const ProductCardContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
});

const ImageWraper = styled('div', {
  padding: '0px 16px 0px 0px',
});

const ProductCardActions = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'top',
});

export const CardImageComponent = styled('img', {
  height: 64,
  width: 64,
  objectFit: 'cover',
  maxWidth: 64,
  maxHeight: 64,
  borderRadius: '8px',
});

export interface Props {
  basketLine: BasketLine;
  onDelete: (basketLine: BasketLine) => void;
  // TODO: refactor to CSS:
  noTopRadius?: boolean;
  noRadius?: boolean;
  noBottomRadius?: boolean;
  noBottomBorder?: boolean;
}

const getOrderingPacksTooltipText = ({
  numberOfPacks,
  unitQuantity,
}: {
  numberOfPacks: number;
  unitQuantity: number;
}) => {
  const suffix = numberOfPacks > 1 ? 's' : '';

  const numberOfUnits = numberOfPacks * unitQuantity;
  return `Ordering ${numberOfPacks} pack${suffix} (${getNumberWithCommas(
    numberOfUnits
  )} units)`;
};

const ProductCardContainer = styled('div', {
  display: 'flex',
});

const getIconText = ({
  numberOfPacks,
  unitQuantity,
}: {
  numberOfPacks: number;
  unitQuantity: number;
}) => {
  const numberOfUnits = numberOfPacks * unitQuantity;
  return `${getNumberWithCommas(numberOfUnits)} units`;
};

export const NavBasketDropdownProductCard = ({
  basketLine,
  // TODO: think about better way of doing that in CSS
  noTopRadius,
  noBottomRadius,
  noRadius,
  noBottomBorder,
  onDelete,
}: Props) => {
  // TODO: src property does not exist issue
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CardImage: any = CardImageComponent;
  const { t } = useTranslation();

  const numberOfPacks = basketLine.addressLines.reduce((acc, { quantity }) => {
    return acc + quantity;
  }, 0);

  const orderingPacksTooltipText = getOrderingPacksTooltipText({
    numberOfPacks,
    unitQuantity: basketLine.product.unitQuantity || 1,
  });

  const orderingPacksIconText = getIconText({
    numberOfPacks,
    unitQuantity: basketLine.product.unitQuantity || 1,
  });

  return (
    <NavBasketDropdownProductCardStyled
      noTopRadius={noTopRadius}
      noBottomRadius={noBottomRadius}
      noRadius={noRadius}
      noBottomBorder={noBottomBorder}
    >
      <ProductCardContainer>
        <ImageWraper>
          <CardImage src={basketLine.product.imageUrl} />
        </ImageWraper>
        <ProductCardContent>
          <OrderInfoWrapper>
            <Tooltip
              trigger={
                <IconTextWrapper css={{ paddingRight: 16 }}>
                  <Label
                    size={LabelSize.Small}
                    css={{
                      fontFamily: '$font-latin-sans-serif-1',
                      color: '$colour-brand-neutral-hue-1-base',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      size={IconSize['Static XXX-Small']}
                      name="icon-products-box"
                    />
                    &nbsp;
                    {orderingPacksIconText}
                  </Label>
                </IconTextWrapper>
              }
              contentText={orderingPacksTooltipText}
            />
            <Tooltip
              trigger={
                <IconTextWrapper>
                  <Label
                    size={LabelSize.Small}
                    css={{
                      fontFamily: '$font-latin-sans-serif-1',
                      color: '$colour-brand-neutral-hue-1-base',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      size={IconSize['Static XXX-Small']}
                      name="icon-navigation-location"
                    />
                    &nbsp;
                    {basketLine.addressLines.length}
                  </Label>
                </IconTextWrapper>
              }
              content={
                <Label as="div" size={LabelSize.Small}>
                  <p>{t('basketDropdown.deliveringTo')}</p>
                  <ul>
                    {basketLine.addressLines.map(({ address }, key) => {
                      return <li key={key}>{address.name}</li>;
                    })}
                  </ul>
                </Label>
              }
            />
          </OrderInfoWrapper>
          <Label
            size={LabelSize.Small}
            css={{
              fontFamily: '$font-latin-sans-serif-1',
              color: '$colour-brand-neutral-hue-1-shade-1',
            }}
            weight={LabelWeight['Semi-Bold']}
          >
            {basketLine.product.name}
          </Label>
        </ProductCardContent>
      </ProductCardContainer>
      <ProductCardActions>
        <Tooltip
          trigger={
            <Button
              variant={ButtonVariant.Secondary}
              size={ButtonSize['XX-Small']}
              css={{
                marginRight: '0 !important',
                paddingInlineStart: '$spacing-static-inline-x-small',
                paddingInlineEnd: '$spacing-static-inline-x-small',
              }}
              colourOverride={{
                active: {
                  color: '$colour-brand-red-hue-1-base', // colour.ui.light.container.neutral.no-contrast.text.red.lo
                  backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
                },
                default: {
                  color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                  backgroundColor: '$colour-lightest', // colour.ui.light.container.neutral.no-contrast.colour
                },
                hover: {
                  color: '$colour-brand-red-hue-1-base', // colour.ui.light.container.neutral.no-contrast.text.red.lo
                  backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
                },
                visited: {
                  color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                  backgroundColor: '$colour-lightest', // colour.ui.light.container.neutral.no-contrast.colour
                },
              }}
              radii={ButtonRadii.Small}
              onClick={() => onDelete(basketLine)}
            >
              <Icon
                size={IconSize['Static XXX-Small']}
                name="icon-navigation-trash"
              />
            </Button>
          }
          contentText={t('basketDropdown.removeProduct')}
        />
      </ProductCardActions>
    </NavBasketDropdownProductCardStyled>
  );
};
