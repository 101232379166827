import { styled } from '@sourceful/design-system';
import { RectShape } from 'react-placeholder/lib/placeholders';
import { BREAKPOINT_M } from '../../constants/styles';

const RowPlaceHolder = styled('div', {
  width: '100%',
  height: '65px',
  padding: '10px',
  marginBottom: '20px',
});

const PlaceholderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  [BREAKPOINT_M]: {
    marginTop: '$spacing-fluid-block-large',
  },
  flex: 1,
  padding: '10px 5px 10px 5px',
  boxShadow: '0 0.0625rem 0.5rem 0 rgba(64, 64, 68, 0.3)',
  borderRadius: '5px',
  backgroundColor: '#fff',
  marginTop: '20px',
});

export const OrderDetailsCardSkeleton = () => (
  <PlaceholderWrapper className="show-loading-animation">
    {/** adding class to show the loading animation https://github.com/buildo/react-placeholder/issues/93 */}
    <RowPlaceHolder>
      <RectShape
        color="lightGray"
        style={{
          width: '100%',
          height: '65px',
          borderRadius: '5px',
        }}
      />
    </RowPlaceHolder>
    <RowPlaceHolder>
      <RectShape
        color="lightGray"
        style={{
          width: '100%',
          height: '65px',
          borderRadius: '5px',
        }}
      />
    </RowPlaceHolder>
    <RowPlaceHolder>
      <RectShape
        color="lightGray"
        style={{
          width: '100%',
          height: '65px',
          borderRadius: '5px',
        }}
      />
    </RowPlaceHolder>
  </PlaceholderWrapper>
);
