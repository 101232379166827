import {
  Label,
  LabelSize,
  Title,
  TitleSize,
  TitleWeight,
  Button,
  ButtonSize,
  ButtonVariant,
  ButtonRadii,
  IconText,
  IconTextIconPosition,
  IconTextIconSize,
  IconTextSpacing,
  IconTextSize,
  IconTextWeight,
} from '@sourceful/design-system';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { formatOrderNumber } from '../../../../shared/utils/formatOrderNumber/formatOrderNumber';
import { StatusTag } from '../../viewClientOrders/page/ViewClientOrdersStyled';
import {
  BannerWrapper,
  OrderSummaryBannerWrapper,
  PrinterButtonWrapper,
} from './OrderSummaryBannerStyled';

export interface Props {
  createdAt?: string;
  clientOrderId?: string;
  statusName?: string;
}

export const OrderSummaryBanner = ({
  createdAt,
  clientOrderId,
  statusName,
}: Props) => {
  const { t } = useTranslation();
  return (
    <OrderSummaryBannerWrapper>
      <BannerWrapper>
        {/* first row */}
        <Label
          size={LabelSize.Small}
          css={{ color: '$colour-brand-neutral-hue-1-shade-1' }}
        >
          {t('viewClientOrder.order')}
        </Label>
        <Label
          size={LabelSize.Small}
          css={{ color: '$colour-brand-neutral-hue-1-shade-1' }}
        >
          {t('viewClientOrder.orderDate')}
        </Label>
        {/* todo: get 'created by' user's name */}
        {/* <Label
          size={LabelSize.Small}
          css={{ color: '$colour-brand-neutral-hue-1-shade-1' }}
        >
          {t('viewClientOrder.createdBy')}
        </Label> */}
        <Label
          size={LabelSize.Small}
          css={{ color: '$colour-brand-neutral-hue-1-shade-1' }}
        >
          {t('viewClientOrder.status')}
        </Label>
        {/* second row */}
        <Title
          size={TitleSize.Medium}
          weight={TitleWeight['Semi-Bold']}
          css={{ alignSelf: 'center' }}
        >
          {formatOrderNumber(clientOrderId)}
        </Title>
        <Label size={LabelSize.Medium} css={{ alignSelf: 'center' }}>
          {`${dayjs(createdAt).format('D MMMM YYYY').toString()}`}
        </Label>
        {/* todo: get 'created by' user's name */}
        {/* <Label size={LabelSize.Medium} css={{ alignSelf: 'center' }}> */}
        {/* user
        </Label> */}
        <StatusTag
          css={{ alignSelf: 'center' }}
          red={statusName === 'Draft'}
          blue={statusName === 'Submitted'}
          yellow={statusName === 'In transit'}
          grey={statusName === 'Delivered'}
        >
          {statusName}
        </StatusTag>
      </BannerWrapper>

      <PrinterButtonWrapper>
        <Button
          radii={ButtonRadii.Small}
          variant={ButtonVariant.Secondary}
          size={ButtonSize['X-Large']}
          onClick={() => window.print()}
        >
          <IconText
            iconName="icon-document-print"
            iconPosition={IconTextIconPosition['Inline Start']}
            iconSize={IconTextIconSize.Small}
            spacing={IconTextSpacing.Small}
            text={'Print order'}
            textSize={IconTextSize.Small}
            textType="Label"
            textWeight={IconTextWeight['Semi-Bold']}
          />
        </Button>
      </PrinterButtonWrapper>
    </OrderSummaryBannerWrapper>
  );
};
