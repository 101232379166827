import { UK_CURRENCY, UK_LOCALE } from '../../../../shared/constants';
import { AddressValue } from '../../../../shared/types/Address';
import { Product } from '../../../../shared/types/Product';
import { getMoqPrice } from '../../../../shared/utils/getMoqPrice/getMoqPrice';

export const getNumberOfPacks = (addresses: AddressValue[]) => {
  return addresses.reduce((acc, nextAddressValue: AddressValue) => {
    const valueToBeAdded = Number.isInteger(Number(nextAddressValue.quantity))
      ? Number(nextAddressValue.quantity)
      : 0;
    return acc + valueToBeAdded;
  }, 0);
};

export const getProductPacksQuantity = (numberOfPacks: number): string => {
  const suffix = numberOfPacks > 1 ? 's' : '';

  return numberOfPacks > 0 ? `${numberOfPacks} pack${suffix}` : '';
};

export interface ProductSubtTotalOptions {
  product: Product;
  numberOfPacks: number;
  locale?: string;
  currencyCode?: string;
}

export const getProductSubTotal = ({
  product,
  numberOfPacks,
  locale = UK_LOCALE,
  currencyCode = UK_CURRENCY,
}: ProductSubtTotalOptions) => {
  if (product.productPrices.length === 0) {
    return 0;
  }

  const unitQty = product.unitQuantity ?? 1;

  const selectedPrice = getMoqPrice({
    totalUnits: numberOfPacks * unitQty,
    prices: product.productPrices,
    currencyCode,
  });

  const price = numberOfPacks * (selectedPrice?.pricePerUnit ?? 0) * unitQty;
  return price.toLocaleString(locale, {
    style: 'currency',
    currency: currencyCode,
  });
};
