import * as faker from 'faker';
import { createAddress } from '../../factories/addressFactory';
import { createProduct } from '../../factories/productFactory';
import { FileAttachment } from '../../types/FileAttachment';
import { Product } from '../../types/Product';
import { isBoolean } from '../../utils/isBoolean/isBoolean';
import { AddressLine, BasketLine, BasketOrder } from './useBasket';

export interface Options {
  product?: Product;
  isDeleted?: boolean;
  addressLines?: AddressLine[];
}

export const createTestBasketLine = ({
  product,
  isDeleted,
  addressLines,
}: Options = {}): BasketLine => {
  const fallbackAddressLines = [
    {
      quantity: faker.datatype.number(),
      address: createAddress(),
    },
    {
      quantity: faker.datatype.number(),
      address: createAddress(),
    },
  ];

  const updatedAddressLines = addressLines || fallbackAddressLines;
  const sumOfAddressLineQuantities = updatedAddressLines.reduce<number>(
    (addressLineNumberOfPacks, { quantity }) => {
      if (quantity > 0) {
        return addressLineNumberOfPacks + quantity;
      }
      return addressLineNumberOfPacks;
    },
    0
  );
  return {
    product: product || createProduct(),
    addressLines: updatedAddressLines,
    addedAt: new Date(),
    addedAtTimestamp: Date.now(),
    isDeleted: isBoolean(isDeleted) ? (isDeleted as boolean) : false,
    sumOfAddressLineQuantities,
    overflowQuantity: 0,
  };
};

export interface CreateOrderOptions {
  basketOrders: BasketOrder[];
  isDraft: boolean;
}

export interface DeliveryTimeline {
  minDays?: number;
  maxDays?: number;
  contractualExpectedDeliveryDate?: string;
}
interface ClientOrderLine {
  quantity: number;
  productId: string;
}

interface ClientOrder {
  addressId: string;
  clientOrderLines: ClientOrderLine[];
  attachments: FileAttachment[];
  clientReference: string;
}
export interface CreateClientOrderPayload {
  isDraft: boolean;
  clientOrders: ClientOrder[];
}

export const createClientOrderPayload = ({
  isDraft,
  basketOrders,
}: CreateOrderOptions): CreateClientOrderPayload => {
  const clientOrders = basketOrders.map(basketOrder => {
    const clientOrderLines = basketOrder.orderLines.map(
      ({ product, numberOfPacks }) => {
        return {
          productId: product.id as string,
          quantity: numberOfPacks,
        };
      }
    );

    const clientReference = basketOrder.clientReference
      ? basketOrder.clientReference.trim()
      : '';

    return {
      addressId: basketOrder.address?.id as string,
      clientOrderLines,
      attachments: basketOrder.attachments || [],
      clientReference,
    };
  });

  return {
    clientOrders,
    isDraft,
  };
};
