import { TaxRate } from '../../types/Address';

export interface TaxValues {
  taxValue?: number | null;
  taxPercentage?: number | null;
  taxName?: string | null;
}

export const getTaxRate = (
  taxRates: TaxRate[] | null | undefined,
  taxType: 'VAT' | 'Tariff'
) => {
  const taxRate = taxRates?.find(
    (taxRate: TaxRate) => taxRate.taxRateType?.displayName == taxType
  );

  return {
    taxValue: taxRate?.taxValue,
    taxPercentage: taxRate?.taxPercentage,
    taxName: taxRate?.taxRateType?.displayName,
  };
};
