import { User as Auth0User } from '@auth0/auth0-react';
import { User } from '../../types/User';

export const mapUser = (user?: Auth0User): User => {
  return {
    ...user,
    id: user?.sub,
    preferredUsername: user?.preferred_username,
    emailVerified: user?.email_verified,
    phoneNumber: user?.phone_number,
    phoneNumberVerified: user?.phone_number_verified,
    updatedAt: user?.updated_at,
  };
};
