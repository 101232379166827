import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Title,
  TitleSize,
  TitleWeight,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder/lib';
import { useSearchParams } from 'react-router-dom';
import { PRODUCT_ID_QUERY_PARAM_NAME } from '../../../../../shared/constants';
import { useBasketContext } from '../../../../../shared/providers/BasketProvider/BasketProvider';
import { ModalComponentProps } from '../../../../../shared/providers/ModalProvider/ModalProvider';
import { useToastContext } from '../../../../../shared/providers/ToastProvider/ToastProvider';
import { useOrganisationsContext } from '../../../../../shared/providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { AddressValue, Values } from '../../../../../shared/types/Address';
import useCurrentOrganisationProduct from '../../graphql/hooks/useCurrentOrganisationProduct/useCurrentOrganisationProduct';
import { AddDropshipProductToBasketForm } from '../AddProductToBasketForm/AddDropshipProductToBasketForm';
import { AddPrepaidStockToBasketForm } from '../AddProductToBasketForm/AddPrepaidStockToBasketForm';
import { AddProductToBasketFormSkeleton } from '../AddProductToBasketForm/AddProductToBasketFormSkeleton';
import { AddWarehouseProductToBasketForm } from '../AddProductToBasketForm/AddWarehouseProductToBasketForm';
import ProductCard from '../ProductCard/ProductCard';
import ProductCardSkeleton from '../ProductCard/ProductCardSkeleton';
import { ProductCardWrapper } from './AddProductToBasketModal.styled';
import { isProductWithMoqPricing } from '../../../../../shared/utils/isProductWithMoqPricing/isProductWithMoqPricing';

export const AddProductToBasketModal = ({
  open,
  handleClose,
}: ModalComponentProps) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { [PRODUCT_ID_QUERY_PARAM_NAME]: productIdFromQueryParams } =
    Object.fromEntries(Array.from(searchParams));

  const [quantityInBasketForMoq, setQuantityInBasketForMoq] =
    useState<number>(0);

  const productId = productIdFromQueryParams;

  const { product } = useCurrentOrganisationProduct({ productId });

  const productHasMoqPricing = isProductWithMoqPricing({
    productPrices: product?.productPrices,
  });
  const { currentOrganisation } = useOrganisationsContext();

  const isReady = Boolean(product && currentOrganisation);

  const placeholderAddresses = new Array(3).fill(null);

  const {
    isProductInBasket,
    getProductAddressesLines,
    addProductToBasket,
    editProductInBasket,
  } = useBasketContext();

  const { showToast } = useToastContext();

  const productAddressesLines = getProductAddressesLines(productId);

  const addressesValues: AddressValue[] =
    currentOrganisation?.addresses &&
    Array.isArray(currentOrganisation?.addresses)
      ? currentOrganisation.addresses.map(address => {
          const foundAddress = productAddressesLines.find(
            productLineAddress => productLineAddress.address.id === address.id
          );

          return {
            address,
            quantity: foundAddress?.quantity || 0,
          };
        })
      : ([] as AddressValue[]);

  const productIsInBasket = isProductInBasket(productId);

  const handleSubmit = ({ addressesValues }: Values) => {
    if (!product) {
      return;
    }
    const handler = productIsInBasket
      ? editProductInBasket
      : addProductToBasket;

    const sumOfAddressLineQuantities = addressesValues.reduce<number>(
      (acc, next) => {
        return acc + next.quantity;
      },
      0
    );

    handler({
      product,
      addressLines: addressesValues,
      sumOfAddressLineQuantities,
    });

    if (product.name && product.imageUrl) {
      showToast({
        title: productIsInBasket
          ? t('toast.updatedInBasketTitle')
          : t('toast.addedToBasketTitle'),
        description: product.name,
        iconName: 'icon-shopping-basket',
      });
    }

    handleClose();
  };
  return (
    <Dialog isOpen={open} onOpenChange={handleClose} size="medium">
      <DialogTitle asChild>
        <Title
          size={TitleSize.Medium}
          weight={TitleWeight['Semi-Bold']}
          css={{
            fontFamily: '$font-latin-sans-serif-1',
            color: '$colour-brand-neutral-hue-1-shade-2',
            marginBottom: '$spacing-fluid-block-small',
          }}
          as="h2"
        >
          {productIsInBasket
            ? t('addProductToBasket.editInBasket')
            : t('addProductToBasket.addToBasket')}
        </Title>
      </DialogTitle>

      <ProductCardWrapper>
        <ReactPlaceholder
          showLoadingAnimation={true}
          ready={isReady}
          customPlaceholder={<ProductCardSkeleton />}
        >
          {product && (
            <ProductCard
              product={product}
              quantityInBasketForMoq={quantityInBasketForMoq}
              productHasMoqPricing={productHasMoqPricing}
            />
          )}
        </ReactPlaceholder>
      </ProductCardWrapper>

      <ReactPlaceholder
        showLoadingAnimation={true}
        ready={isReady}
        customPlaceholder={
          <AddProductToBasketFormSkeleton addresses={placeholderAddresses} />
        }
      >
        {product &&
          currentOrganisation &&
          !currentOrganisation.config?.prepaidStock &&
          product.productType?.name === 'Warehouse' && (
            <AddWarehouseProductToBasketForm
              onSubmit={handleSubmit}
              onCancel={handleClose}
              product={product}
              addressesValues={addressesValues}
              isEditing={productIsInBasket}
            />
          )}
        {product &&
          currentOrganisation &&
          currentOrganisation.config?.prepaidStock && (
            <AddPrepaidStockToBasketForm
              onSubmit={handleSubmit}
              onCancel={handleClose}
              product={product}
              addressesValues={addressesValues}
              isEditing={productIsInBasket}
            />
          )}
        {product &&
          currentOrganisation &&
          product.productType?.name === 'Dropship' && (
            <AddDropshipProductToBasketForm
              onSubmit={handleSubmit}
              onCancel={handleClose}
              product={product}
              addressesValues={addressesValues}
              isEditing={productIsInBasket}
              setQuantityInBasketForMoq={setQuantityInBasketForMoq}
              productHasMoqPricing={productHasMoqPricing}
            />
          )}
      </ReactPlaceholder>
    </Dialog>
  );
};
