import { Organisation } from '../../../types/Organisation';
import { mapOrganisationData } from '../../mappers';
import { useGetCurrentOrganisationQuery } from '../../types/generated';

interface HookResult {
  currentOrganisationLoading: boolean;
  currentOrganisationError?: unknown;
  refetchCurrentOrganisation: () => void;
  currentOrganisation: Organisation | null;
}

export interface Options {
  currentOrganisationId: string | null;
}

const useCurrentOrganisation = ({
  currentOrganisationId,
}: Options): HookResult => {
  const { isLoading, error, data, refetch } = useGetCurrentOrganisationQuery(
    {
      currentOrganisationId: currentOrganisationId as never,
    },
    { enabled: !!currentOrganisationId }
  );

  return {
    refetchCurrentOrganisation: () => refetch(),
    currentOrganisationLoading: isLoading,
    currentOrganisationError: error,
    currentOrganisation: data?.currentOrganisation
      ? mapOrganisationData(data.currentOrganisation)
      : null,
  };
};

export default useCurrentOrganisation;
