export enum ROLE {
  ADMIN = 'admin',
  PUBLIC = 'public',
}

export const ROLES = [ROLE.ADMIN, ROLE.PUBLIC];

export const DEFAULT_ROLES = [ROLE.PUBLIC];

export const HASURA_CLAIMS_NAMESPACE = 'https://hasura.io/jwt/claims';
export const HASURA_ALLOWED_ROLES_CLAIM = 'x-hasura-allowed-roles';
