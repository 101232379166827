import { styled, Label } from '@sourceful/design-system';

export const OrderLineTable = styled('section', {
  width: '100%',
});

export const OrderLineTableRow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  variants: {
    default: {
      true: {
        padding: '$spacing-fluid-block-x-small',
        background: '$colour-lightest',
        borderBottom: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
        borderRight: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
        borderLeft: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
      },
    },
    hover: {
      true: {
        background: '$colour-brand-neutral-hue-1-tint-6',
      },
    },
    firstRow: {
      true: {
        borderTopLeftRadius: '$radii-small $radii-small',
        borderTopRightRadius: '$radii-small $radii-small',
        borderTop: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
      },
    },
    lastRow: {
      true: {
        borderBottomLeftRadius: '$radii-small $radii-small',
        borderBottomRightRadius: '$radii-small $radii-small',
        borderBottom: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
      },
    },
  },
});

export const TableHeaderText = styled(Label, {
  fontFamily: '$font-latin-sans-serif-1',
  color: '$colour-brand-neutral-hue-1-shade-1',
  fontWeight: 600,
});

export const OrderLineTableColumn = styled('div', {
  display: 'flex',
  alignItems: 'center',
});
