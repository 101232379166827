import dayjs from 'dayjs';
import { Trans } from 'react-i18next';

export interface Props {
  postcode?: string | null;
  minDays?: number | null;
  maxDays?: number | null;
  deliveredAt?: string | null;
  contractualExpectedDeliveryDate?: Date | null;
  isEditing?: boolean;
}

export const DeliveryInfo = ({
  postcode,
  minDays,
  maxDays,
  deliveredAt,
  contractualExpectedDeliveryDate,
}: Props) => {
  if (deliveredAt) {
    return (
      <Trans
        i18nKey="orderDetails.deliveredWithPostcodeAndDeliveryDate"
        values={{
          postcode,
          deliveryDate: `${dayjs(deliveredAt)
            .format('D MMMM YYYY')
            .toString()}`,
        }}
        defaults="Delivered to <bold>{{postcode}}</bold> on <bold>{{deliveryDate}}</bold>"
        components={{ bold: <strong /> }}
      />
    );
  }
  if (contractualExpectedDeliveryDate) {
    return (
      <Trans
        i18nKey="orderDetails.deliveryWithPostcodeAndDeliveryDate"
        values={{
          postcode,
          deliveryDate: `${dayjs(contractualExpectedDeliveryDate)
            .format('D MMMM YYYY')
            .toString()}`,
        }}
        defaults="Delivers to <blue>{{postcode}}</blue> by <bold>{{deliveryDate}}</bold>"
        components={{ bold: <strong /> }}
      />
    );
  }
  return (
    <Trans
      i18nKey="orderDetails.deliveryWithPostcodeAndMinMaxTimeline"
      values={{ postcode, minDays, maxDays }}
      defaults="Delivers to <blue>{{postcode}}</blue> in <bold>{{minDays}} to
        {{maxDays}} days</bold>"
      components={{ bold: <strong /> }}
    />
  );
};
