import { ClientOrder as ClientOrderGraphqlType } from '../../../../../shared/graphql/types/generated';
import {
  ClientOrder,
  ClientOrderLine,
} from '../../../../../shared/types/ClientOrder';

export const mapClientOrder = (
  clientOrder: ClientOrderGraphqlType
): ClientOrder => {
  return {
    ...clientOrder,
    isDraft: false,
    deliveryInfo: {
      addressId: clientOrder?.addressId,
      deliveryFee: Number(clientOrder?.deliveryExcludingVAT),
      deliveryTimeline: {
        contractualExpectedDeliveryDate:
          clientOrder?.contractualExpectedDeliveryDate
            ? new Date(clientOrder?.contractualExpectedDeliveryDate)
            : undefined,
      },
    },
    orderLines: clientOrder?.clientOrderLines.map(clientOrderLine => {
      return {
        ...clientOrderLine,
        orderId: clientOrder.id,
        product: {
          ...clientOrderLine?.product,
          productStocks: [],
          manufacturingLeadTime: {
            min: clientOrderLine?.product?.manufacturingLeadTime?.min,
            max: clientOrderLine?.product?.manufacturingLeadTime?.max,
          },
        },
      } as ClientOrderLine;
    }),
  } as ClientOrder;
};
