import '@sourceful/reset.css';
import '@sourceful/reset.css/forms.css';
import '@sourceful/reset.css/typography.css';
import { Routes, Route } from 'react-router-dom';
import { ViewBasket } from './modules/basket/viewBasket/page/ViewBasket';
import { ViewClientOrder } from './modules/clientOrders/viewClientOrder/page/ViewClientOrder';
import { ViewClientOrders } from './modules/clientOrders/viewClientOrders/page/ViewClientOrders';
import { ViewProducts } from './modules/products/viewProducts/page/ViewProducts';
import { PrivateRoute } from './shared/components/PrivateRoute/PrivateRoute';
import NotFound from './shared/pages/NotFound/NotFound';
import ModalProvider from './shared/providers/ModalProvider/ModalProvider';
import { useUserContext } from './shared/providers/UserProvider/UserProvider';

// FYI: https://react.i18next.com/misc/testing
const routes = [
  {
    path: '/product-catalogue',
    element: <ViewProducts />,
  },
  { path: '/', element: <ViewProducts /> },
  { path: '/basket', element: <ViewBasket /> },
  { path: '/orders', element: <ViewClientOrders /> },
  { path: '/orders/:clientOrderId', element: <ViewClientOrder /> },
  { path: '*', element: <NotFound /> },
];

export default function App() {
  const { isLoading } = useUserContext();

  if (isLoading) return null;

  return (
    <>
      <Routes>
        {routes.map(({ element, path }, index) => {
          return (
            <Route
              key={index}
              path={path}
              element={<PrivateRoute>{element}</PrivateRoute>}
            />
          );
        })}
      </Routes>
      <Routes>
        <Route path="*" element={<ModalProvider />} />
      </Routes>
    </>
  );
}
