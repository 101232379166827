import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import {
  IconSize,
  Label,
  LabelSize,
  LabelWeight,
  Collapsible,
  LabelLineHeight,
} from '@sourceful/design-system';
import useDownload from '../Files/useDownload';
import { FileAttachment } from '../../types/FileAttachment';
import AttachmentsUploader from '../Files/AttachmentsUploader';
import { FormControl } from '../Form/FormControl/FormControl';
import { StyledInput } from '../Orders/OrderProductRow/OrderProductRowStyled';
import AttachmentDownloader from '../Files/AttachmentDownloader';
import { useToastContext } from '../../providers/ToastProvider/ToastProvider';
import { useOrganisationsContext } from '../../providers/UserOrganisationsProvider/UserOrganisationsProvider';
import {
  AttachmentsUploaderWrapper,
  AttachmentsWrapper,
  ClientReferenceWrapper,
  CollapsibleHeaderWrapper,
  InvoiceDetailsWrapper,
  UploadedAttachmentsWrapper,
} from './InvoiceDetailsStyled';

export interface Props {
  attachments: FileAttachment[];
  onAttachmentAdd: (attachments: FileAttachment[]) => void;
  onAttachmentRemove: (attachmentUuid: string) => void;
  onClientReferenceChange: (clientReference: string) => void;
  isEditing: boolean;
  orderCreationDate?: string | null;
  addressId: string;
  clientReference: string;
}

const InvoiceDetails = ({
  isEditing,
  orderCreationDate,
  addressId,
  clientReference,
  onClientReferenceChange,
  onAttachmentAdd,
  onAttachmentRemove,
  attachments,
}: Props) => {
  const { t } = useTranslation();

  const { downloadFile } = useDownload();

  const { showToast } = useToastContext();
  const { currentOrganisation } = useOrganisationsContext();

  const isOrderDetailsEnabled = Boolean(
    currentOrganisation?.config?.enableClientReferenceCollection
  );

  const handleAddAttachmentDownload = async (
    attachmentUuid: string,
    originalFilename: string
  ) => {
    try {
      await downloadFile(attachmentUuid, originalFilename);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showToast({
        title: "Couldn't download file",
        description: error.message,
        iconName: 'icon-alert-information-outline',
      });
    }
  };

  const [invoiceAttachments, nonInvoiceAttachments] = attachments.reduce(
    (acc, attachment) => {
      if (
        attachment &&
        attachment.metadata &&
        JSON.parse(attachment.metadata).type === 'invoice'
      ) {
        acc[0].push(attachment);
      } else {
        acc[1].push(attachment);
      }
      return acc;
    },
    [[], []] as [FileAttachment[], FileAttachment[]]
  );

  const sortedNonInvoiceAttachments = nonInvoiceAttachments.sort(
    (attachment1, attachment2) => {
      return attachment1.originalFilename.localeCompare(
        attachment2.originalFilename
      );
    }
  );

  const onUploadError = (error: Error) => {
    showToast({
      title: "Couldn't upload file",
      description: error.message,
      iconName: 'icon-alert-information-outline',
    });
  };

  const CollapsibleHeaderTrigger = () => (
    <CollapsibleHeaderWrapper>
      <Label
        size={LabelSize.Large}
        weight={LabelWeight['Semi-Bold']}
        css={{ color: '$colour-brand-neutral-hue-1-shade-1', flex: 2 }}
      >
        {t('orderDetails.invoiceDetails')}
      </Label>
      <Label
        size={LabelSize.Small}
        weight={LabelWeight.Regular}
        css={{
          color: '$colour-brand-neutral-hue-1-shade-1',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {t('orderDetails.optional')}
      </Label>
    </CollapsibleHeaderWrapper>
  );

  const isInvoicePending =
    !isEditing &&
    dayjs(orderCreationDate).isSame(dayjs(), 'day') &&
    invoiceAttachments.length === 0;

  if (!isOrderDetailsEnabled) {
    return null;
  }

  return (
    <>
      {isEditing ? (
        <Collapsible
          triggerElementFunction={CollapsibleHeaderTrigger}
          iconSize={IconSize['Static XXX-Small']}
          css={{
            padding: '$spacing-fluid-block-small',
            paddingBlock: 0,
            margin: 0,
            marginTop: '$spacing-fluid-block-medium',

            backgroundColor: '$colour-lightest ',
            borderTop: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
            borderRadius: '$radii-x-small',

            '& > button': {
              paddingBlock: '$spacing-fluid-block-small',
              marginBlockEnd: '0 !important',
            },
          }}
        >
          <InvoiceDetailsWrapper>
            <FormControl
              hasIcon={false}
              noColumnGap={true}
              css={{
                display: 'flex',
                flexDirection: 'column',
                fontFamily: '$font-latin-sans-serif-1',
              }}
              label={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Label
                    as="label"
                    size={LabelSize.Small}
                    weight={LabelWeight['Semi-Bold']}
                    lineHeight={LabelLineHeight.Loose}
                  >
                    {t('orderDetails.reference')}
                  </Label>
                  <Label
                    as="label"
                    size={LabelSize.Small}
                    lineHeight={LabelLineHeight.Loose}
                  >
                    {t('orderDetails.referenceExplanation')}
                  </Label>
                </div>
              }
            >
              <div>
                <StyledInput
                  name={`orderReference.${addressId}`}
                  placeholder={t('orderDetails.referencePlaceholder')}
                  css={{ minWidth: 250 }}
                  value={clientReference}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    onClientReferenceChange(value);
                  }}
                />
              </div>
            </FormControl>

            <AttachmentsWrapper>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Label
                  as="label"
                  size={LabelSize.Small}
                  weight={LabelWeight['Semi-Bold']}
                  lineHeight={LabelLineHeight.Loose}
                >
                  {t('orderDetails.attachments')}
                </Label>
                <Label
                  as="label"
                  size={LabelSize.Small}
                  lineHeight={LabelLineHeight.Loose}
                >
                  {t('orderDetails.attachmentsExplanation')}
                </Label>
              </div>
              {attachments.length > 0 && (
                <div>
                  {sortedNonInvoiceAttachments.map(
                    ({ attachmentUuid, originalFilename }) => (
                      <AttachmentDownloader
                        attachmentUuid={attachmentUuid}
                        originalFilename={originalFilename}
                        onDownload={handleAddAttachmentDownload}
                        onAttachmentRemove={onAttachmentRemove}
                        key={attachmentUuid}
                        isEditing={isEditing}
                      />
                    )
                  )}
                </div>
              )}
              <AttachmentsUploaderWrapper>
                <AttachmentsUploader
                  onError={onUploadError}
                  onSuccess={onAttachmentAdd}
                />
              </AttachmentsUploaderWrapper>
            </AttachmentsWrapper>
          </InvoiceDetailsWrapper>
        </Collapsible>
      ) : (
        <>
          <ClientReferenceWrapper
            css={{ marginBottom: '$spacing-fluid-block-small' }}
          >
            <Label
              as="label"
              css={{
                color: '$colour-brand-neutral-hue-1-shade-1',
                flex: 'initial',
                width: '200px',
              }}
              size={LabelSize.Small}
            >
              {t('orderDetails.reference')}
            </Label>
            <Label
              as="label"
              size={LabelSize.Small}
              css={{ color: '$colour-brand-neutral-hue-1-shade-1' }}
              weight={
                clientReference ? LabelWeight['Semi-Bold'] : LabelWeight.Regular
              }
            >
              {clientReference || t('orderDetails.noReference')}
            </Label>
          </ClientReferenceWrapper>{' '}
          {sortedNonInvoiceAttachments.length > 0 && (
            <UploadedAttachmentsWrapper>
              <Label
                as="label"
                css={{
                  color: '$colour-brand-neutral-hue-1-shade-1',
                  flex: 'initial',
                  width: '200px',
                }}
                size={LabelSize.Small}
              >
                {t('orderDetails.attachments')}
              </Label>

              <div style={{ flex: 1 }}>
                {sortedNonInvoiceAttachments.map(
                  ({ attachmentUuid, originalFilename }) => (
                    <AttachmentDownloader
                      attachmentUuid={attachmentUuid}
                      originalFilename={originalFilename}
                      onDownload={handleAddAttachmentDownload}
                      onAttachmentRemove={onAttachmentRemove}
                      key={attachmentUuid}
                      isEditing={isEditing}
                    />
                  )
                )}
              </div>
            </UploadedAttachmentsWrapper>
          )}
        </>
      )}

      {!isEditing && invoiceAttachments.length > 0 && (
        <UploadedAttachmentsWrapper
          css={{
            marginTop: '$spacing-fluid-block-x-small',
          }}
        >
          <Label
            as="label"
            css={{
              color: '$colour-brand-neutral-hue-1-shade-1',
              flex: 'initial',
              width: '200px',
            }}
            size={LabelSize.Small}
          >
            {t('orderDetails.invoice')}
          </Label>
          {invoiceAttachments.length > 0 && (
            <div style={{ flex: 1 }}>
              {invoiceAttachments.map(
                ({ attachmentUuid, originalFilename }) => (
                  <AttachmentDownloader
                    attachmentUuid={attachmentUuid}
                    originalFilename={originalFilename}
                    onDownload={handleAddAttachmentDownload}
                    onAttachmentRemove={onAttachmentRemove}
                    key={attachmentUuid}
                    isEditing={isEditing}
                  />
                )
              )}
            </div>
          )}
        </UploadedAttachmentsWrapper>
      )}

      {!isEditing && isInvoicePending && (
        <UploadedAttachmentsWrapper
          css={{
            marginTop: '$spacing-fluid-block-x-small',
          }}
        >
          <Label
            as="label"
            css={{
              color: '$colour-brand-neutral-hue-1-shade-1',
              flex: 'initial',
              width: '200px',
            }}
            size={LabelSize.Small}
          >
            {t('orderDetails.invoice')}
          </Label>
          <Label
            as="label"
            size={LabelSize.Small}
            css={{ color: '$colour-brand-neutral-hue-1-shade-1' }}
            weight={LabelWeight.Regular}
          >
            {t('orderDetails.invoicePending')}
          </Label>
        </UploadedAttachmentsWrapper>
      )}
    </>
  );
};

export default InvoiceDetails;
