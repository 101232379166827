import { createContext, useContext, useState, ReactNode } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { IconName, Toast, ToastViewPort } from '@sourceful/design-system';
import { Z_INDEX_TOAST } from '../../constants/zIndexValues';

export type ToastComponent = () => JSX.Element | null;

export interface ToastItem {
  id: string;
}

export interface ShowToastProps {
  description: string;
  iconName: IconName;
  title?: string;
  toastDuration?: number;
}

export interface ToastContextInterface {
  showToast: (props: ShowToastProps) => void;
}

const DEFAULT_TOAST_CONTEXT: ToastContextInterface = {
  showToast: () => null,
};

const ToastContext = createContext<ToastContextInterface>(
  DEFAULT_TOAST_CONTEXT
);

export const useToastContext = () => useContext(ToastContext);

const ToastProvider = ({ children, ...props }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<ShowToastProps[]>([]);

  const showToast = (props: ShowToastProps) => {
    setToasts(prevToasts => [...prevToasts, props]);
  };

  const contextValue: ToastContextInterface = {
    showToast,
  };

  return (
    <ToastPrimitive.Provider swipeDirection="right">
      {toasts.length > 0 &&
        toasts.map((toast, index) => {
          return (
            <Toast
              data-cy="toast"
              key={`${toast.title}-${index}`}
              title={toast.title}
              description={toast.description}
              iconName={toast.iconName}
              duration={toast.toastDuration}
            />
          );
        })}
      <ToastContext.Provider value={contextValue} {...props}>
        {children}
      </ToastContext.Provider>
      <ToastViewPort
        toastPlacement="top-right"
        style={{
          position: 'fixed',
          zIndex: Z_INDEX_TOAST,
          marginTop: '70px',
        }}
      />
    </ToastPrimitive.Provider>
  );
};

export default ToastProvider;
