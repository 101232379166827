import { useEffect, ReactElement } from 'react';
import { useUserContext } from '../../providers/UserProvider/UserProvider';

interface Props {
  children: ReactElement;
}

const RequireAuth = ({ children }: Props): React.ReactElement | null => {
  const { isLoading, isAuthenticated, login } = useUserContext();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    login({
      appState: { returnTo: document.location },
    });
  }, [isLoading, isAuthenticated, login]);

  if (isLoading) {
    return null;
  }

  return isAuthenticated === true ? children : null;
};

export default RequireAuth;
