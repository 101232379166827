import { useUserContext } from '../../providers/UserProvider/UserProvider';

export const useOrganisationName = () => {
  const { organisations, currentOrganisationId } = useUserContext();
  const currentOrganisation = organisations
    ? organisations.filter(
        organisation => organisation.id === currentOrganisationId
      )
    : [];
  return currentOrganisation.length > 0
    ? currentOrganisation[0].display_name
    : '';
};
