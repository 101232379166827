/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import {
  Label,
  LabelLineHeight,
  LabelSize,
  LabelWeight,
  Icon,
  IconSize,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { FormControl } from '../../../../../shared/components/Form/FormControl/FormControl';
import { StyledInput } from '../../../../../shared/components/Orders/OrderProductRow/OrderProductRowStyled';
import { getProductStockLevel } from '../../../../../shared/hooks/getProductStockLevel/GetProductStockLevel';
import { Address } from '../../../../../shared/types/Address';
import { Product } from '../../../../../shared/types/Product';
import { getNumberWithCommas } from '../../../../../shared/utils/getNumberWithCommas/getNumberWithCommas';
import {
  getAddressText,
  getOverflowQuantity,
} from '../AddProductToBasketForm/utils';
import {
  ProductTableRow,
  ProductTableColumn,
  TextColumn,
} from '../AddProductToBasketModal/AddProductToBasketModal.styled';

export interface Props {
  index: number;
  amountOfLocations: number;
  address: Address;
  hasOverflow?: boolean;
  product: Product;
  numberOfPacks: number;
  numberOfPacksForCurrentLocation: number;
  availablePacks?: number;
  amountOfUnits: number;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  inputQuantityValue: number;
  locale: string;
  currencyCode: string;
}

const AddPrepaidStockToBasketProductRow = ({
  index,
  amountOfLocations,
  address,
  hasOverflow,
  product,
  numberOfPacks,
  availablePacks,
  amountOfUnits,
  setFieldValue,
  inputQuantityValue,
}: Props) => {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);

  return (
    <ProductTableRow
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={index}
      default
      firstRow={index === 0}
      lastRow={index === amountOfLocations - 1}
      css={{
        justifyContent: 'space-around',
        margin: 0,
        paddingLeft: '$spacing-fluid-block-x-small',
      }}
    >
      <ProductTableColumn style={{ flex: '0 0 50%' }}>
        <TextColumn style={{ paddingLeft: 0 }}>
          <Label
            size={LabelSize.Medium}
            lineHeight={LabelLineHeight.Normal}
            weight={LabelWeight['Semi-Bold']}
            css={{
              color: '$colour-brand-neutral-hue-1-shade-1',
              marginBottom: '$spacing-fluid-block-xx-small',
            }}
          >
            {address?.name}
          </Label>
          <Label
            size={LabelSize.Small}
            css={{
              color: '$colour-brand-neutral-hue-1-shade-1',
            }}
          >
            {getAddressText(address)}
          </Label>
        </TextColumn>
      </ProductTableColumn>
      <ProductTableColumn
        style={{
          flex: '0 0 50%',
          alignItems: 'center',
          justifyContent: 'right',
        }}
      >
        <TextColumn style={{ paddingLeft: 0 }}>
          <Label
            size={LabelSize.Medium}
            lineHeight={LabelLineHeight.Normal}
            weight={LabelWeight['Semi-Bold']}
            css={{
              color: '$colour-brand-neutral-hue-1-shade-1',
              marginBottom: '$spacing-fluid-block-x-small',
              marginLeft: '$spacing-fluid-block-xx-small',
            }}
          >
            {t('addProductToBasket.packQuantity')}
          </Label>
          <FormControl
            footer={
              <Label
                as="label"
                size={LabelSize.Small}
                css={
                  hasOverflow
                    ? {
                        color: '$colour-brand-red-hue-1-base',
                        fontFamily: '$font-latin-sans-serif-1',
                      }
                    : {
                        color: '$colour-brand-neutral-hue-1-shade-1',
                        fontFamily: '$font-latin-sans-serif-1',
                      }
                }
              >
                {availablePacks && hasOverflow && product.unitQuantity && (
                  <>
                    <Icon
                      size={IconSize['Static XXX-Small']}
                      name="icon-alert-exclamation-outline"
                    />
                    &nbsp;
                    {t('viewBasket.stockOverflowNotification', {
                      amountOfPacksInBasket:
                        getProductStockLevel(product).numberOfPacks +
                        getOverflowQuantity({
                          numberOfPacks,
                          availablePacks,
                        }),
                      amountOfPacks:
                        getProductStockLevel(product).numberOfPacks,
                    })}
                  </>
                )}
                {!hasOverflow &&
                  product.unitQuantity &&
                  t('addProductToBasket.amountOfUnits', {
                    amountOfUnits: getNumberWithCommas(amountOfUnits),
                    count: amountOfUnits,
                  })}
              </Label>
            }
          >
            <div>
              <StyledInput
                error={hasOverflow}
                name={`addressesValues.${index}.quantity`}
                placeholder="0"
                min={0}
                type="number"
                value={inputQuantityValue}
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) => {
                  const newValue =
                    value.length > 0 ? parseInt(value, 10).toString() : 0;
                  setFieldValue(`addressesValues.${index}.quantity`, newValue);
                }}
              />
            </div>
          </FormControl>
        </TextColumn>
      </ProductTableColumn>
    </ProductTableRow>
  );
};

export default AddPrepaidStockToBasketProductRow;
