/* eslint-disable @typescript-eslint/no-explicit-any */
import { BasketOrder } from '../../../../shared/providers/BasketProvider/useBasket';
import { ClientOrder } from '../../../../shared/types/ClientOrder';

export const mapClientOrderToBasketStructure = (
  clientOrder: ClientOrder
): BasketOrder => {
  const attachments = (clientOrder.attachments || []).map(attachment => {
    return {
      originalFilename: attachment.originalFilename || '',
      attachmentUuid: attachment.uuid || '',
      metadata: attachment.metadata || undefined,
    };
  });

  return {
    externalClientOrderId: clientOrder.externalClientOrderId || undefined,
    address: clientOrder.address as any,
    subTotal: (clientOrder.clientOrderLines as any).reduce(
      (total: any, clientOrderLine: any) =>
        total + (clientOrderLine?.totalExcludingVAT || 0),
      0
    ),
    clientReference: clientOrder.clientReference || undefined,
    deliveryInfo: {
      addressId: clientOrder.addressId as any,
      deliveryFee: clientOrder.deliveryExcludingVAT as any,
      deliveryTimeline: {
        contractualExpectedDeliveryDate:
          clientOrder.deliveryInfo?.deliveryTimeline
            .contractualExpectedDeliveryDate,
        min: clientOrder.address?.deliveryTimeline?.min, // need to add the the values for manufacturing_lead_time for the lowerst min
        max: clientOrder.address?.deliveryTimeline?.max, // need to add the the values for manufacturing_lead_time for the highest max
      },
    },
    attachments,
    orderSummary: {
      deliveryValueExcludingVAT:
        clientOrder.clientOrderSummary?.deliveryValueExcludingVAT,
      productsSubtotal: clientOrder.clientOrderSummary?.productsSubtotal,
      orderSubtotal: clientOrder.clientOrderSummary?.orderSubtotal,
      tariffPercentage: clientOrder.clientOrderSummary?.tariffPercentage,
      tariffValue: clientOrder.clientOrderSummary?.tariffValue,
      totalIncludingTaxes: clientOrder.clientOrderSummary?.totalIncludingTaxes,
      vatPercentage: clientOrder.clientOrderSummary?.vatPercentage,
      vatValue: clientOrder.clientOrderSummary?.vatValue,
      currencyCode:
        clientOrder.currencyCode ||
        clientOrder.clientOrderSummary?.currencyCode,
      createdAt: clientOrder.createdAt,
    },
    orderLines: (clientOrder.clientOrderLines as any).map((orderLine: any) => {
      return {
        product: {
          id: orderLine?.product?.id as any,
          additionalAttributes: orderLine?.product?.additionalAttributes as any,
          externalProductId: orderLine?.product?.externalProductId as any,
          name: orderLine?.product?.name as any,
          description: orderLine?.product?.description as any,
          imageUrl: orderLine?.product?.imageUrl as any,
          unitQuantity: orderLine?.product?.unitQuantity as any,
          productStocks: [],
          deliveryCosts: orderLine?.product?.deliveryCosts || [],
          productPrices: ((orderLine.product as any)?.productPrices || []).map(
            (productPrice: any) => {
              return {
                id: productPrice.id as any,
                currencyCode: productPrice.currencyCode as any,
                minOrderQuantity: productPrice.minOrderQuantity as any,
                pricePerUnit: productPrice.pricePerUnit as any,
              };
            }
          ),
        },
        quantityInUnits: orderLine.quantityInUnits as any,
        numberOfPacks: orderLine.quantity as any,
        subTotal: orderLine.totalExcludingVAT as any,
        overflowQuantity: 0, //Basket specific feature
      };
    }),
  };
};
