import { useEffect, useState } from 'react';
import { usePrevious } from '../../hooks/usePrevious/usePrevious';
import { Address } from '../../types/Address';
import { FileAttachment } from '../../types/FileAttachment';
import { Product } from '../../types/Product';
import { DeliveryInfo } from './BasketProvider';
import { computeBasketState } from './computeBasketState/computeBasketState';

export interface OnOrderPlacedOptions {
  basketOrders: BasketOrder[];
}

export interface PlaceOrderOptions {
  basketState: BasketState;
  isDraft: boolean;
}

export interface BasketOptions {
  products?: Product[];
  deliveryInformations?: DeliveryInfo[];
  computeDeliveryCostPerProduct?: boolean;
  initialValue?: BasketState;
  onBasketChange?: (basketState: BasketState) => void;
  currencyCode?: string;
  basketKey?: string | null;
  VAT: number;
  tariff: number;
}

export interface AddressLine {
  address: Address;
  quantity: number;
  clientReference?: string;
  attachments?: FileAttachment[];
}

export interface BasketLine {
  product: Product;
  addressLines: AddressLine[];
  sumOfAddressLineQuantities: number;
  overflowQuantity: number;
  addedAt: Date;
  addedAtTimestamp: number;
  isDeleted: boolean;
}

export interface BasketOrderLine {
  product: Product;
  numberOfPacks: number;
  quantityInUnits?: number;
  subTotal: number;
  overflowQuantity?: number;
}

export interface OrderSummary {
  deliveryValueExcludingVAT?: number;
  productsSubtotal?: number;
  orderSubtotal?: number;
  tariffPercentage?: number | null;
  tariffValue?: number | null;
  totalIncludingTaxes?: number;
  vatPercentage?: number;
  vatValue?: number;
  currencyCode?: string;
  createdAt?: string | null;
}

export interface BasketOrder {
  address: Address;
  orderLines: BasketOrderLine[];
  orderSummary?: OrderSummary;
  subTotal: number;
  deliveryInfo?: DeliveryInfo;
  externalClientOrderId?: string;
  attachments?: FileAttachment[];
  clientReference?: string;
}

export interface BasketActionOptions {
  product: Product;
  addressLines: AddressLine[];
  sumOfAddressLineQuantities: number;
}

export interface BasketSummary {
  productsSubTotal: number;
  deliverySubTotal: number;
  basketSubTotal: number;
  vat: number;
  basketTotalIncludingTaxes: number;
  tariff: number | null;
}

export interface BasketState {
  basketCreatedAt: Date;
  basketCreatedAtTimestamp: number;
  basketLines: BasketLine[];
  basketOrders: BasketOrder[];
  basketSummary: BasketSummary;
}

export const DEFAULT_BASKET_SUMMARY: BasketSummary = {
  productsSubTotal: 0,
  deliverySubTotal: 0,
  basketSubTotal: 0,
  vat: 0,
  basketTotalIncludingTaxes: 0,
  tariff: null,
};

export const DEFAULT_BASKET_STATE: BasketState = {
  basketCreatedAt: new Date(),
  basketCreatedAtTimestamp: Date.now(),
  basketLines: [],
  basketOrders: [],
  basketSummary: DEFAULT_BASKET_SUMMARY,
};

export const createDefaultBasketState = () => DEFAULT_BASKET_STATE;

export interface DeliveryTimeline {
  minDays?: number;
  maxDays?: number;
  contractualExpectedDeliveryDate?: string;
}

export interface PlaceOrderResult {
  clientOrderIds?: string[];
  deliveryTimeline?: DeliveryTimeline;
}

export interface EditProductQuantityOptions {
  productId: string;
  addressId: string;
  quantity: number;
}

export interface UseBasketResult extends BasketState {
  addProductToBasket: (basketLine: BasketActionOptions) => void;
  editProductInBasket: (basketLine: BasketActionOptions) => void;
  markProductAsDeletedInBasket: (productId: string) => void;
  isProductInBasket: (productId: string) => boolean;
  getProductAddressesLines: (productId: string) => AddressLine[];
  markProductAsNonDeletedInBasket: (productId: string) => void;
  removeMarkAsDeletedProducts: () => void;
  removeBasketOrderByAddressId: (addressId: string) => void;
  removeProductFromAddress: (productId: string, addressId: string) => void;
  editProductQuantityInBasket: (options: EditProductQuantityOptions) => void;
  handleClientReferenceChange: (
    clientReference: string,
    addressId: string
  ) => void;
  handleAddAttachment: (
    attachments: FileAttachment[],
    addressId: string
  ) => void;
  handleRemoveAttachment: (attachmentId: string) => void;
  resetBasketState: () => void;
}

const withTimestamps = () => ({
  addedAt: new Date(),
  addedAtTimestamp: Date.now(),
});

export const useBasket = ({
  initialValue,
  products,
  deliveryInformations = [],
  computeDeliveryCostPerProduct = false,
  onBasketChange,
  basketKey,
  currencyCode,
  VAT,
  tariff,
}: BasketOptions): UseBasketResult => {
  const storeState = initialValue || createDefaultBasketState();
  const [basketState, setBasketState] = useState<BasketState>(storeState);
  const [isClientBasketInitialized, setIsClientBasketInitialized] =
    useState<boolean>(false);

  const previousBasketKey = usePrevious(basketKey);

  useEffect(() => {
    if (initialValue && !isClientBasketInitialized) {
      setBasketState(initialValue);
      setIsClientBasketInitialized(true);
    }
    if (isClientBasketInitialized && !initialValue) {
      setIsClientBasketInitialized(false);
    }
  }, [initialValue, isClientBasketInitialized]);

  useEffect(() => {
    if (
      basketKey !== previousBasketKey &&
      setBasketState &&
      (!initialValue ||
        JSON.stringify(initialValue) !== JSON.stringify(basketState))
    ) {
      setBasketState(initialValue || createDefaultBasketState());
    }
  }, [initialValue, basketState, setBasketState, basketKey, previousBasketKey]);

  const previousState = usePrevious(basketState);
  const previousProducts = usePrevious(products);
  const previousDeliveryInformations = usePrevious(deliveryInformations);

  useEffect(() => {
    if (
      onBasketChange &&
      JSON.stringify(previousState) !== JSON.stringify(basketState)
    ) {
      onBasketChange(basketState);
    }
  }, [onBasketChange, previousState, basketState, initialValue]);

  useEffect(() => {
    if (
      deliveryInformations &&
      deliveryInformations?.length > 0 &&
      JSON.stringify(previousProducts) !== JSON.stringify(deliveryInformations)
    ) {
      setBasketState((previousBasketState: BasketState) => {
        return computeBasketState({
          computeDeliveryCostPerProduct,
          basketState: {
            ...previousBasketState,
          },
          productsFromRemoteSource: products,
          deliveryInformations,
          currencyCode,
          VAT,
          tariff,
        });
      });
    }
  }, [previousDeliveryInformations, deliveryInformations]);

  useEffect(() => {
    if (
      products &&
      products?.length > 0 &&
      JSON.stringify(previousProducts) !== JSON.stringify(products)
    ) {
      setBasketState((previousBasketState: BasketState) => {
        return computeBasketState({
          computeDeliveryCostPerProduct,
          basketState: {
            ...previousBasketState,
          },
          productsFromRemoteSource: products,
          deliveryInformations,
          currencyCode,
          VAT,
          tariff,
        });
      });
    }
  }, [previousProducts, products]);

  const addProductToBasket = (basketLineToBeAdded: BasketActionOptions) => {
    const foundBasketLine = basketState.basketLines.find(
      (basketLine: BasketLine) => {
        return (
          basketLine.product.id &&
          basketLineToBeAdded.product.id &&
          basketLine.product.id === basketLineToBeAdded.product.id
        );
      }
    );
    if (!foundBasketLine) {
      setBasketState((previousBasketState: BasketState) => {
        const addedBasketLine = {
          ...basketLineToBeAdded,
          ...withTimestamps(),
          isDeleted: false,
          overflowQuantity: 0,
        };

        return computeBasketState({
          computeDeliveryCostPerProduct,
          basketState: {
            ...previousBasketState,
            basketLines: [addedBasketLine, ...basketState.basketLines],
          },
          deliveryInformations,
          currencyCode,
          VAT,
          tariff,
        });
      });
    }
  };

  const editProductInBasket = (basketLineToBeEdited: BasketActionOptions) => {
    const updatedBasketLines = basketState.basketLines.map(
      (basketLine: BasketLine) => {
        if (
          basketLine.product.id &&
          basketLineToBeEdited.product.id &&
          basketLine.product.id === basketLineToBeEdited.product.id
        ) {
          const sumOfQuantities = basketLineToBeEdited.addressLines.reduce(
            (acc, addressLine) => {
              return acc + (addressLine.quantity || 0);
            },
            0
          );

          if (sumOfQuantities === 0) {
            return null;
          }

          return {
            ...basketLineToBeEdited,
            ...withTimestamps(),
            isDeleted: false,
            overflowQuantity: 0,
          };
        }
        return basketLine;
      }
    );

    const filteredBasketLines = updatedBasketLines.filter(Boolean);

    setBasketState((previousBasketState: BasketState) => {
      return computeBasketState({
        computeDeliveryCostPerProduct,
        basketState: {
          ...previousBasketState,
          basketLines: filteredBasketLines as BasketLine[],
        },
        deliveryInformations,
        currencyCode,
        VAT,
        tariff,
      });
    });
  };

  const isProductInBasket = (productId: string) => {
    return !!basketState.basketLines.find(
      basketLine => basketLine.product.id === productId
    );
  };

  const getProductAddressesLines = (productId: string) => {
    const foundBasketLine = basketState.basketLines.find(
      basketLine => basketLine.product.id === productId
    );

    if (!foundBasketLine) return [];

    return foundBasketLine.addressLines;
  };

  const markProductAsDeletedInBasket = (productId: string) => {
    const updatedBasketLines = basketState.basketLines.map(
      (basketLine: BasketLine) => {
        if (basketLine.product.id && basketLine.product.id === productId) {
          return {
            ...basketLine,
            isDeleted: true,
          };
        }
        return basketLine;
      }
    );

    setBasketState((previousBasketState: BasketState) => {
      return computeBasketState({
        computeDeliveryCostPerProduct,
        basketState: {
          ...previousBasketState,
          basketLines: updatedBasketLines,
        },
        deliveryInformations,
        currencyCode,
        VAT,
        tariff,
      });
    });
  };

  const handleClientReferenceChange = (
    clientReference: string,
    addressId: string
  ) => {
    // TODO: look into basketLines data structure as setup below is suboptimal
    const updatedBasketLines = basketState.basketLines.map(
      (basketLine: BasketLine) => {
        const updatedAddressLines = basketLine.addressLines.map(addressLine => {
          if (addressLine.address.id === addressId) {
            return {
              ...addressLine,
              clientReference,
            };
          }

          return addressLine;
        });

        if (updatedAddressLines.length === 0) {
          return null;
        }

        return {
          ...basketLine,
          addressLines: updatedAddressLines,
          ...withTimestamps(),
        };
      }
    );

    const filteredBasketLines = updatedBasketLines.filter(Boolean);

    setBasketState((previousBasketState: BasketState) => {
      return computeBasketState({
        computeDeliveryCostPerProduct,
        basketState: {
          ...previousBasketState,
          basketLines: filteredBasketLines as BasketLine[],
        },
        deliveryInformations,
        currencyCode,
        VAT,
        tariff,
      });
    });
  };

  const handleAddAttachment = (
    attachments: FileAttachment[],
    addressId: string
  ) => {
    // TODO: look into basketLines data structure as setup below is suboptimal
    const updatedBasketLines = basketState.basketLines.map(
      (basketLine: BasketLine) => {
        const updatedAddressLines = basketLine.addressLines.map(addressLine => {
          if (addressLine.address.id === addressId) {
            return {
              ...addressLine,
              attachments: [...(addressLine.attachments || []), ...attachments],
            };
          }

          return addressLine;
        });

        if (updatedAddressLines.length === 0) {
          return null;
        }

        return {
          ...basketLine,
          addressLines: updatedAddressLines,
          ...withTimestamps(),
        };
      }
    );

    const filteredBasketLines = updatedBasketLines.filter(Boolean);

    setBasketState((previousBasketState: BasketState) => {
      return computeBasketState({
        computeDeliveryCostPerProduct,
        basketState: {
          ...previousBasketState,
          basketLines: filteredBasketLines as BasketLine[],
        },
        deliveryInformations,
        currencyCode,
        VAT,
        tariff,
      });
    });
  };

  const handleRemoveAttachment = (attachmentUuid: string) => {
    // TODO: look into basketLines data structure as setup below is suboptimal
    const updatedBasketLines = basketState.basketLines.map(
      (basketLine: BasketLine) => {
        const updatedAddressLines = basketLine.addressLines.map(
          (addressLine: AddressLine) => {
            const updatedAttachments = (addressLine.attachments || []).filter(
              attachment => attachment.attachmentUuid !== attachmentUuid
            );

            return {
              ...addressLine,
              attachments: updatedAttachments,
            };
          }
        );

        if (updatedAddressLines.length === 0) {
          return null;
        }

        return {
          ...basketLine,
          addressLines: updatedAddressLines,
          ...withTimestamps(),
        };
      }
    );

    const filteredBasketLines = updatedBasketLines.filter(Boolean);

    setBasketState((previousBasketState: BasketState) => {
      return computeBasketState({
        computeDeliveryCostPerProduct,
        basketState: {
          ...previousBasketState,
          basketLines: filteredBasketLines as BasketLine[],
        },
        deliveryInformations,
        currencyCode,
        VAT,
        tariff,
      });
    });
  };

  const markProductAsNonDeletedInBasket = (productId: string) => {
    const updatedBasketLines = basketState.basketLines.map(
      (basketLine: BasketLine) => {
        if (basketLine.product.id && basketLine.product.id === productId) {
          return {
            ...basketLine,
            isDeleted: false,
          };
        }
        return basketLine;
      }
    );

    setBasketState((previousBasketState: BasketState) => {
      return computeBasketState({
        computeDeliveryCostPerProduct,
        basketState: {
          ...previousBasketState,
          basketLines: updatedBasketLines,
        },
        deliveryInformations,
        currencyCode,
        VAT,
        tariff,
      });
    });
  };

  const removeMarkAsDeletedProducts = () => {
    const updatedBasketLines = basketState.basketLines.filter(
      (basketLine: BasketLine) => {
        return !basketLine.isDeleted;
      }
    );

    setBasketState((previousBasketState: BasketState) => {
      return computeBasketState({
        computeDeliveryCostPerProduct,
        basketState: {
          ...previousBasketState,
          basketLines: updatedBasketLines,
        },
        VAT,
        tariff,
      });
    });
  };

  const removeBasketOrderByAddressId = (addressId: string) => {
    const updatedBasketLines = basketState.basketLines.map(
      (basketLine: BasketLine) => {
        const basketLineAddressesWithoutAddressId =
          basketLine.addressLines.filter(addressLine => {
            return addressLine.address.id !== addressId;
          });

        if (basketLineAddressesWithoutAddressId.length === 0) {
          return null;
        }

        return {
          ...basketLine,
          addressLines: basketLineAddressesWithoutAddressId,
          ...withTimestamps(),
        };
      }
    );

    const filteredBasketLines = updatedBasketLines.filter(Boolean);

    setBasketState((previousBasketState: BasketState) => {
      return computeBasketState({
        computeDeliveryCostPerProduct,
        basketState: {
          ...previousBasketState,
          basketLines: filteredBasketLines as BasketLine[],
        },
        deliveryInformations,
        currencyCode,
        VAT,
        tariff,
      });
    });
  };

  const resetBasketState = () => {
    setBasketState(createDefaultBasketState());
    onBasketChange && onBasketChange(createDefaultBasketState());
  };

  const removeProductFromAddress = (productId: string, addressId: string) => {
    const updatedBasketLines = basketState.basketLines.map(
      (basketLine: BasketLine) => {
        if (basketLine.product.id === productId) {
          const basketLineAddressesWithoutAddressId =
            basketLine.addressLines.filter(addressLine => {
              return addressLine.address.id !== addressId;
            });

          if (basketLineAddressesWithoutAddressId.length === 0) {
            return null;
          }

          return {
            ...basketLine,
            addressLines: basketLineAddressesWithoutAddressId,
            ...withTimestamps(),
          };
        }
        return basketLine;
      }
    );

    const filteredBasketLines = updatedBasketLines.filter(basketLine =>
      Boolean(basketLine)
    );

    setBasketState((previousBasketState: BasketState) => {
      return computeBasketState({
        computeDeliveryCostPerProduct,
        basketState: {
          ...previousBasketState,
          basketLines: filteredBasketLines as BasketLine[],
        },
        deliveryInformations,
        currencyCode,
        VAT,
        tariff,
      });
    });
  };

  const editProductQuantityInBasket = ({
    addressId,
    productId,
    quantity,
  }: EditProductQuantityOptions) => {
    const updatedBasketLines = basketState.basketLines
      .map((basketLine: BasketLine) => {
        if (basketLine.product.id === productId) {
          const updatedAddressLines = basketLine.addressLines
            .map(addressLine => {
              if (addressLine.address.id == addressId) {
                if (quantity === 0) {
                  return null;
                }
                return {
                  ...addressLine,
                  quantity,
                };
              }
              return addressLine;
            })
            .filter(Boolean) as unknown as AddressLine[];

          if (updatedAddressLines.length === 0) {
            return null;
          }

          return {
            ...basketLine,
            addressLines: updatedAddressLines,
            ...withTimestamps(),
          };
        }
        return basketLine;
      })
      .filter(Boolean) as unknown as BasketLine[];

    setBasketState((previousBasketState: BasketState) => {
      return computeBasketState({
        computeDeliveryCostPerProduct,
        basketState: {
          ...previousBasketState,
          basketLines: updatedBasketLines,
        },
        deliveryInformations,
        currencyCode,
        VAT,
        tariff,
      });
    });
  };
  return {
    ...basketState,
    addProductToBasket,
    editProductInBasket,
    markProductAsDeletedInBasket,
    markProductAsNonDeletedInBasket,
    removeMarkAsDeletedProducts,
    isProductInBasket,
    handleClientReferenceChange,
    handleAddAttachment,
    handleRemoveAttachment,
    getProductAddressesLines,
    removeBasketOrderByAddressId,
    removeProductFromAddress,
    resetBasketState,
    editProductQuantityInBasket,
  };
};
