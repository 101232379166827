import {
  Title,
  TitleSize,
  TitleLineHeight,
  styled,
  ProductCard,
  Grid,
  GridContainer,
  Label,
  LabelSize,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import ReactPlaceholder from 'react-placeholder';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AlertBanner } from '../../../../shared/components/AlertBanner/AlertBanner';
import { TitleWrapper } from '../../../../shared/components/Nav/NavStyled';
import { ProductCardSkeleton } from '../../../../shared/components/ProductCard/ProductCardSkeleton';
import {
  MODAL_ID_QUERY_PARAM_NAME,
  PRODUCT_ID_QUERY_PARAM_NAME,
  UK_CURRENCY,
  UK_LOCALE,
} from '../../../../shared/constants';
import { ADD_PRODUCT_TO_BASKET_MODAL_ID } from '../../../../shared/constants/modals';
import { getProductStockLevel } from '../../../../shared/hooks/getProductStockLevel/GetProductStockLevel';
import { useBasketContext } from '../../../../shared/providers/BasketProvider/BasketProvider';
import { useOrganisationsContext } from '../../../../shared/providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { useUserContext } from '../../../../shared/providers/UserProvider/UserProvider';
import { Product } from '../../../../shared/types/Product';
import { getProductPrice } from '../../../../shared/utils/getProductPrice/getProductPrice';
import useCurrentOrganisationProducts from '../graphql/hooks/useCurrentOrganisationProducts/useCurrentOrganisationProducts';
import { subheaderData } from '../utils';
import { getAllMoqPricesSortedDescending } from '../../../../shared/utils/getMoqPrice/getMoqPrice';
import { isProductWithMoqPricing } from '../../../../shared/utils/isProductWithMoqPricing/isProductWithMoqPricing';
import config from '../../../../config';

const NoProductsAlert = styled('div', {});

export const ViewProducts = () => {
  const { products, productsLoading, productsError } =
    useCurrentOrganisationProducts();

  const [, setSearchParams] = useSearchParams();

  const { currentOrganisation } = useOrganisationsContext();
  const { isSwitchingOrgs } = useUserContext();

  const navigate = useNavigate();

  const { isProductInBasket } = useBasketContext();

  const onAddProductToBasket = (productId?: string | null) => () => {
    setSearchParams(
      `?${MODAL_ID_QUERY_PARAM_NAME}=${ADD_PRODUCT_TO_BASKET_MODAL_ID}&${PRODUCT_ID_QUERY_PARAM_NAME}=${productId}`
    );
  };

  const handleViewBasket = () => {
    navigate('/basket');
  };

  const { t } = useTranslation();

  const productsPlaceholders = new Array(12).fill(null);

  const productToBeShown: Product[] =
    productsLoading && !productsError ? productsPlaceholders : products;

  const isReady = Boolean(
    !productsLoading &&
      !productsError &&
      currentOrganisation &&
      !isSwitchingOrgs
  );

  const noStockTooltipWarning = (
    <Label as="div" size={LabelSize.Small}>
      <p>{t('viewProducts.noStockWarning')}</p>
    </Label>
  );

  const notAvailableForOrderWarning = (
    <Label as="div" size={LabelSize.Small}>
      <p>{t('viewProducts.notAvailableForOrderWarning')}</p>
    </Label>
  );

  const isWarehouseOrg = products?.some(
    product =>
      product?.productType?.name === 'Warehouse' || product?.productTypeId === 1
  );

  return (
    <div>
      {isReady &&
      isWarehouseOrg &&
      config.featureFlagWarehouseHolidayBannerEnabled === 'true' ? (
        <GridContainer>
          <Grid columns={{ '@initial': 1 }}>
            <AlertBanner
              firstLineMessage="alertMessages.warehouseClosedForHolidays"
              secondLineMessage="alertMessages.happyHolidays"
              variant="info"
            />
          </Grid>
        </GridContainer>
      ) : null}
      <TitleWrapper>
        <GridContainer>
          <Grid columns={{ '@initial': 1 }}>
            <Title
              size={TitleSize.Medium}
              lineHeight={TitleLineHeight.Normal}
              as="h2"
            >
              {t('viewProducts.yourProducts')}
            </Title>
          </Grid>
        </GridContainer>
      </TitleWrapper>
      {productsError ? (
        <GridContainer>
          <Grid columns={{ '@initial': 1 }}>
            <AlertBanner
              firstLineMessage="viewProducts.loadDataError"
              variant="error"
            />
          </Grid>
        </GridContainer>
      ) : null}

      {!productsError && (
        <GridContainer>
          <Grid
            gapY={'medium'}
            columns={{ '@initial': 1, '@bp-xs': 2, '@bp-sm': 3, '@bp-md': 4 }}
          >
            {productToBeShown.map((product, key) => {
              const isDropShipProduct =
                product?.productType?.name === 'Dropship';
              const isWarehouseProduct =
                product?.productType?.name === 'Warehouse';

              const hasNoStock = isDropShipProduct
                ? false
                : product && getProductStockLevel(product)?.numberOfPacks === 0;

              const productHasMoqPricing = isProductWithMoqPricing({
                productPrices: product?.productPrices,
              });

              // if there is no MOQ pricing on the product it will return the normal price(s)
              const pricesSortedByMoq = product?.productPrices
                ? getAllMoqPricesSortedDescending(product.productPrices)
                : null;

              const productPrice = () => {
                if (
                  product?.productPrices &&
                  pricesSortedByMoq &&
                  product?.unitQuantity
                ) {
                  return getProductPrice({
                    unitQuantity: productHasMoqPricing
                      ? pricesSortedByMoq[pricesSortedByMoq.length - 1]
                          ?.minOrderQuantity || 1
                      : product?.unitQuantity,
                    prices: product.productPrices,
                    locale: currentOrganisation?.locale || UK_LOCALE,
                    currencyCode:
                      currentOrganisation?.defaultCurrency || UK_CURRENCY,
                  });
                } else return null;
              };

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const tags: any = [];

              if (hasNoStock) {
                tags.push({
                  text: 'Out of stock',
                  icon: 'icon-navigation-location-sourceful',
                  color: 'red',
                  toolTipText: noStockTooltipWarning,
                });
              }

              if (!productPrice) {
                tags.push({
                  text: 'Product not available',
                  icon: 'icon-navigation-location-sourceful',
                  color: 'red',
                  toolTipText: notAvailableForOrderWarning,
                });
              }

              const unitPriceString =
                productPrice() !== null &&
                !currentOrganisation?.config?.prepaidStock &&
                (productHasMoqPricing ||
                  currentOrganisation?.config?.displayProductUnitCost)
                  ? t('viewProducts.pricePerUnit', {
                      pricePerUnit: productPrice()?.pricePerUnit,
                    })
                  : undefined;

              const packPriceString =
                productPrice() !== null &&
                !currentOrganisation?.config?.prepaidStock &&
                !productHasMoqPricing
                  ? t('viewProducts.pricePerPack', {
                      price: productPrice()?.price,
                    })
                  : undefined;

              const priceString =
                packPriceString && unitPriceString
                  ? packPriceString + '\n' + unitPriceString
                  : packPriceString || unitPriceString || '';

              return (
                <ReactPlaceholder
                  showLoadingAnimation={true}
                  ready={isReady}
                  key={key}
                  customPlaceholder={<ProductCardSkeleton />}
                >
                  {product && (
                    <div
                      data-cy={product.name}
                      style={{ whiteSpace: 'pre-wrap' }} // Added for the combined price string to be displayed with a line break
                    >
                      <ProductCard
                        layout="portrait"
                        isInBasket={isProductInBasket(product.id || '')}
                        onAddToBasket={onAddProductToBasket(product.id)}
                        onUpdateBasket={onAddProductToBasket(product.id)}
                        addButtonText={t('viewProducts.add')}
                        updateButtonText={t('viewProducts.update')}
                        isButtonDisabled={hasNoStock || !productPrice}
                        buttonToolTipText={
                          isWarehouseProduct &&
                          getProductStockLevel(product)?.numberOfPacks === 0 &&
                          noStockTooltipWarning
                        }
                        onClickViewBasket={handleViewBasket}
                        inBasketToolTipText={t('viewProducts.viewBasket')}
                        imageURL={product.imageUrl || ''}
                        imageDescription={product.name || ''}
                        subheaderText={
                          subheaderData(product, currentOrganisation?.addresses)
                            ?.subheaderText
                        }
                        subheaderIcon={
                          subheaderData(product, currentOrganisation?.addresses)
                            ?.subheaderIcon
                        }
                        subheaderToolTipText={
                          subheaderData(product, currentOrganisation?.addresses)
                            ?.subheaderToolTipText
                        }
                        cardTitle={product.name || ''}
                        price={priceString}
                        tags={tags}
                      />
                    </div>
                  )}
                </ReactPlaceholder>
              );
            })}
          </Grid>
        </GridContainer>
      )}

      {!productsError && isReady && productToBeShown.length === 0 && (
        <GridContainer>
          <Grid gapY={'medium'} columns={{ '@initial': 1 }}>
            <NoProductsAlert>
              {t('viewProducts.dontHaveProductsAlert')}
            </NoProductsAlert>
          </Grid>
        </GridContainer>
      )}
    </div>
  );
};
