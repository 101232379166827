import { styled } from '@sourceful/design-system';
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders';
import { Card } from '../Card/Card';
import { CardContent } from '../Card/CardContext';

export const ProductInfo = styled('div', {
  padding: '16px 24px',
});

export const ProductCardSkeleton = () => (
  <Card className="show-loading-animation">
    {/** adding class to show the loading animation https://github.com/buildo/react-placeholder/issues/93 */}
    <RectShape
      color="lightGray"
      style={{
        width: '100%',
        height: 198,
      }}
    />
    <CardContent>
      <ProductInfo>
        <TextBlock rows={6} color="lightGray" />
      </ProductInfo>
    </CardContent>
  </Card>
);
