import {
  Product as ProductGraphqlType,
  ProductStock as ProductStockGraphqlType,
  ProductPrice as ProductPriceGraphqlType,
  TaxRate as TaxRateGraphqlType,
  DeliveryCost as DeliveryCostGraphqlType,
} from '../../../../../shared/graphql/types/generated';
import { DeliveryCost, Product } from '../../../../../shared/types/Product';
import { ProductPrice } from '../../../../../shared/types/ProductPrice';
import { ProductStock } from '../../../../../shared/types/ProductStock';

type ProductStockItem = ProductStockGraphqlType | null | undefined;
type ProductPriceItem = ProductPriceGraphqlType | null | undefined;
type TaxRateItem = TaxRateGraphqlType | null | undefined;
type DeliveryCostItem = DeliveryCostGraphqlType | null | undefined;

const isProductStock = (
  item: ProductStockItem
): item is ProductStockGraphqlType => {
  return Boolean(item);
};

const isProductPrice = (
  item: ProductPriceItem
): item is ProductPriceGraphqlType => {
  return Boolean(item);
};

const isDeliveryCost = (
  item: DeliveryCostItem
): item is DeliveryCostGraphqlType => {
  return Boolean(item);
};

export const isTaxRate = (item: TaxRateItem): item is TaxRateGraphqlType => {
  return Boolean(item);
};

const mapProductPrice = (
  productPrice: ProductPriceGraphqlType
): ProductPrice => {
  return productPrice;
};

const mapDeliveryCost = (
  deliveryCost: DeliveryCostGraphqlType
): DeliveryCost => {
  return deliveryCost;
};

const mapProductStock = (
  productStock: ProductStockGraphqlType
): ProductStock => {
  const taxRates =
    productStock.address?.taxRates &&
    Array.isArray(productStock.address?.taxRates)
      ? productStock.address?.taxRates
      : [];

  const filteredTaxRates = taxRates.filter(isTaxRate);
  return {
    ...productStock,
    address: {
      ...productStock.address,
      taxRates: filteredTaxRates,
    },
  };
};

export const mapProduct = (product: ProductGraphqlType): Product => {
  const productStocks = Array.isArray(product.productStocks)
    ? product.productStocks.filter(isProductStock)
    : [];
  const productPrices =
    product.productPrices && Array.isArray(product.productPrices)
      ? product.productPrices
      : [];
  const deliveryCosts =
    product.deliveryCosts && Array.isArray(product.deliveryCosts)
      ? product.deliveryCosts
      : [];

  const filteredProductPrices = productPrices.filter(isProductPrice);
  const filteredDeliveryCosts = deliveryCosts.filter(isDeliveryCost);

  const mappedProductPrices = filteredProductPrices.map(mapProductPrice);

  const mappedProductStocks = productStocks.map(mapProductStock);
  const mappedDeliveryCosts = filteredDeliveryCosts.map(mapDeliveryCost);
  return {
    ...product,
    manufacturingLeadTime: product.manufacturingLeadTime || {},
    deliveryCosts: mappedDeliveryCosts,
    productStocks: mappedProductStocks,
    productPrices: mappedProductPrices,
  };
};
