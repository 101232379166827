import { GridContainer, Grid } from '@sourceful/design-system';
import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders';
import {
  OrderInfoWrapper,
  SummaryWrapper,
  ButtonWrapper,
} from '../../../../shared/components/Grid/ViewOrderGrid';
import { OrderDetailsCardSkeleton } from '../../../../shared/components/OrderDetailsCard/OrderDetailsCardSkeleton';
import { SummarySkeleton } from '../../../../shared/components/Summary/SummarySkeleton';

export const ViewBasketSkeleton = () => {
  return (
    <GridContainer>
      <Grid gapY={'large'} columns={{ '@initial': 6, '@bp-sm': 12 }}>
        <OrderInfoWrapper>
          <OrderDetailsCardSkeleton />
        </OrderInfoWrapper>
        <SummaryWrapper css={{ position: 'relative', top: '-3.25rem' }}>
          <SummarySkeleton />

          <RectShape
            color="lightGray"
            className="show-loading-animation"
            style={{
              width: '100%',
              height: 40,
              marginTop: '20px',
            }}
          />
          <TextBlock
            color="lightGray"
            className="show-loading-animation"
            rows={2}
            style={{
              marginTop: '20px',
            }}
          />
        </SummaryWrapper>
        <ButtonWrapper>
          <RectShape
            color="lightGray"
            className="show-loading-animation"
            style={{
              width: '180px',
              height: 40,
              marginTop: '24px',
            }}
          />
        </ButtonWrapper>
      </Grid>
    </GridContainer>
  );
};
