import {
  UK_CURRENCY,
  US_CURRENCY,
  UK_LOCALE,
  US_LOCALE,
} from '../../constants';

export const getPrice = (
  price: number,
  currencyCode?: string,
  minimumFractionDigits?: number
) => {
  return price?.toLocaleString(
    currencyCode === US_CURRENCY ? US_LOCALE : UK_LOCALE,
    {
      style: 'currency',
      currency: currencyCode ?? UK_CURRENCY,
      minimumFractionDigits: minimumFractionDigits ?? 2,
    }
  );
};
