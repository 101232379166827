import { styled } from '@sourceful/design-system';
import { TextBlock } from 'react-placeholder/lib/placeholders';
import { SummaryDataWrapper } from '../../../modules/basket/viewBasket/page/ViewBasket';
import { BREAKPOINT_M } from '../../constants/styles';

const SummaryPlaceholderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexBasis: '100%',
  [BREAKPOINT_M]: {
    marginTop: '$spacing-fluid-block-large',
  },
  flex: 1,
  paddingTop: '20px',
  gridColumn: '1 / span 6',
  '@bp-sm': {
    gridColumn: '9 / span 4',
  },
});

const SummaryTextWrapper = styled('div', {
  background: 'blue',
  padding: '15px 10px 0px 10px',
  backgroundColor: '#fff',
  boxShadow: '0 0.0625rem 0.5rem 0 rgba(64, 64, 68, 0.3)',
  borderRadius: '5px',
});

export const SummarySkeleton = () => (
  <SummaryPlaceholderWrapper className="show-loading-animation">
    {/** adding class to show the loading animation https://github.com/buildo/react-placeholder/issues/93 */}
    <SummaryTextWrapper>
      <TextBlock rows={1} color="lightGray" />
      <SummaryDataWrapper>
        <TextBlock rows={5} color="lightGray" />
      </SummaryDataWrapper>
    </SummaryTextWrapper>
  </SummaryPlaceholderWrapper>
);
