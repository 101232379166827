import axios from 'axios';
import { useEffect, useState } from 'react';
import config from '../../../config';
import { FileAttachment } from '../../types/FileAttachment';

export interface UploadedFile {
  attachment_uuid: string;
  original_filename: string;
}

interface UseFileUploadProps<ResponseData> {
  endpoint?: string;
  onUploadComplete?: (
    responseData: ResponseData[],
    originalFile: File[]
  ) => void;
}

const useUpload = <ResponseData extends File[]>({
  onUploadComplete,
  endpoint,
}: UseFileUploadProps<ResponseData>) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setProgress(0);
  }, [selectedFiles]);

  const onUploadProgress = (progressEvent: ProgressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setProgress(percentCompleted);
  };

  const uploadFiles = async () => {
    if (selectedFiles.length === 0) {
      console.error('Please choose a file');
      return;
    }

    const attachmentPromises = selectedFiles.map(async selectedFile => {
      try {
        setIsUploading(true);

        const formData = new FormData();
        formData.append('filename', selectedFile.name);
        formData.append('file', selectedFile);

        const response = await axios.post(
          `${config.fileUploaderBaseUrl}/upload`,
          formData,
          { onUploadProgress }
        );

        const defaultData: FileAttachment = {
          attachmentUuid: response.data.uuid_name,
          originalFilename: selectedFile.name,
        };

        return endpoint ? response.data : defaultData;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('Error in upload attachment API call', error);
        if (typeof error.response?.data?.detail === 'string') {
          throw new Error(error.response.data.detail);
        }

        throw error;
      }
    });

    const uploadedAttachments = await Promise.all(attachmentPromises);

    onUploadComplete && onUploadComplete(uploadedAttachments, selectedFiles);

    setIsUploading(false);
  };

  return {
    selectedFiles,
    setSelectedFiles,
    progress,
    isUploading,
    uploadFiles,
  };
};

export default useUpload;
