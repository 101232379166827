import { Product } from '../../../types/Product';
import { DeliveryInfo } from '../BasketProvider';
import { computeBasketOrders } from '../computeBasketOrders/computeBasketOrders';
import { computeBasketSummary } from '../computeBasketSummary/computeBasketSummary';
import { BasketLine, BasketState, DEFAULT_BASKET_STATE } from '../useBasket';

export interface ComputeBasketStateProps {
  productsFromRemoteSource?: Product[];
  basketState: BasketState;
  deliveryInformations?: DeliveryInfo[];
  currencyCode?: string;
  VAT: number;
  tariff: number;
  computeDeliveryCostPerProduct: boolean;
}

export const computeBasketState = ({
  basketState,
  productsFromRemoteSource,
  deliveryInformations = [],
  currencyCode,
  VAT,
  tariff,
  computeDeliveryCostPerProduct,
}: ComputeBasketStateProps): BasketState => {
  if (basketState.basketLines.length === 0) {
    return DEFAULT_BASKET_STATE;
  }
  let updatedBasketLines = basketState.basketLines;

  // as basket may contains "stale" products info/pricing
  if (productsFromRemoteSource && productsFromRemoteSource.length > 0) {
    updatedBasketLines = updatedBasketLines.map(basketLine => {
      const foundProduct = productsFromRemoteSource.find(
        product =>
          product.id &&
          basketLine.product.id &&
          product.id === basketLine.product.id
      );

      return {
        ...basketLine,
        product: foundProduct ? foundProduct : basketLine.product,
      };
    });
  }

  const validatedBasketLines = updatedBasketLines.map(basketLine => {
    if (basketLine.isDeleted) {
      return null;
    }

    let internalProductStockQuantity = 0;
    let overflowQuantity = 0;
    const sumOfAddressLineQuantities = basketLine.addressLines.reduce<number>(
      (addressLineNumberOfPacks, { quantity }) => {
        if (quantity > 0) {
          return addressLineNumberOfPacks + quantity;
        }
        return addressLineNumberOfPacks;
      },
      0
    );

    if (sumOfAddressLineQuantities === 0) {
      return null;
    }

    if (basketLine.product.productStocks.length > 0) {
      const productStockWithInternalAddress =
        basketLine.product.productStocks.find(
          productStock => productStock.address?.isInternal
        );
      if (productStockWithInternalAddress) {
        internalProductStockQuantity =
          productStockWithInternalAddress.quantity || 0;
      }

      const productStockPacks = Math.floor(
        internalProductStockQuantity / (basketLine.product.unitQuantity || 1)
      );

      if (sumOfAddressLineQuantities > productStockPacks) {
        overflowQuantity = sumOfAddressLineQuantities - productStockPacks;
      }
    }

    return {
      ...basketLine,
      sumOfAddressLineQuantities,
      overflowQuantity,
    };
  });

  const nonDeletedValidatedBasketLines = validatedBasketLines.filter(
    Boolean
  ) as BasketLine[];

  // TODO: sort out O(n) here as many nested loops
  const addressesIds = nonDeletedValidatedBasketLines.flatMap(basketLine =>
    basketLine.addressLines.map(addressLine => addressLine.address.id)
  );

  const uniqAddressesIds = Array.from(new Set(addressesIds));
  let deliverySubTotal = uniqAddressesIds.reduce<number>(
    (acc, nextAddressId) => {
      const deliveryInfo = deliveryInformations.find(
        deliveryInfo => deliveryInfo.addressId === nextAddressId
      );
      if (deliveryInfo?.deliveryFee) {
        return acc + deliveryInfo.deliveryFee;
      }

      return acc;
    },
    0
  );

  if (computeDeliveryCostPerProduct) {
    const productsDeliveryCost = nonDeletedValidatedBasketLines.reduce(
      (acc, nextBasketline) => {
        const foundCostPerPack = nextBasketline.product.deliveryCosts.find(
          deliveryCost => deliveryCost.currencyCode === currencyCode
        );
        if (
          !foundCostPerPack ||
          typeof foundCostPerPack.costPerPackExclVat !== 'number'
        ) {
          return acc;
        }

        const additionalDeliveryCostPerProduct =
          foundCostPerPack.costPerPackExclVat *
          nextBasketline.sumOfAddressLineQuantities;

        return acc + additionalDeliveryCostPerProduct;
      },
      0
    );
    deliverySubTotal += productsDeliveryCost;
  }

  return {
    ...basketState,
    basketLines: updatedBasketLines,
    basketOrders: computeBasketOrders({
      basketLines: nonDeletedValidatedBasketLines,
      deliveryInformations,
      currencyCode: currencyCode || 'GBP',
    }),
    basketSummary: computeBasketSummary({
      basketLines: nonDeletedValidatedBasketLines,
      deliverySubTotal,
      currencyCode: currencyCode || 'GBP',
      VAT,
      tariff,
    }),
  };
};
