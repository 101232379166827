import { UK_CURRENCY, UK_LOCALE } from '../../constants';
import { ProductPrice } from '../../types/ProductPrice';
import { getMoqPrice } from '../getMoqPrice/getMoqPrice';

export const getProductPrice = ({
  prices,
  unitQuantity = 1,
  locale = UK_LOCALE,
  currencyCode = UK_CURRENCY,
}: {
  prices: ProductPrice[];
  unitQuantity: number;
  locale?: string;
  currencyCode?: string;
}): {
  price: string | null;
  pricePerUnit: string | null;
} | null => {
  if (prices.length === 0) {
    return { price: null, pricePerUnit: null };
  }

  const selectedPrice = getMoqPrice({
    totalUnits: unitQuantity,
    prices: prices,
    currencyCode,
    alwaysFindFirst: true,
  });

  if (selectedPrice && typeof selectedPrice.pricePerUnit === 'number') {
    const price = selectedPrice.pricePerUnit * unitQuantity;
    return {
      price: price.toLocaleString(locale, {
        style: 'currency',
        currency: currencyCode,
      }),
      pricePerUnit: selectedPrice.pricePerUnit.toLocaleString(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      }),
    };
  }

  return { price: null, pricePerUnit: null };
};
