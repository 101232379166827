import {
  LabelSize,
  LabelWeight,
  Title,
  TitleSize,
  TitleWeight,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { UK_CURRENCY } from '../../constants';
import { useOrganisationsContext } from '../../providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { getPrice } from '../../utils/getPrice/getPrice';
import { getDeliveryFeeText } from '../Orders/utils';
import {
  SummaryContentsWrapper,
  Row,
  RowItem,
  TextLabel,
} from './OrderSummaryStyled';

export interface Props {
  size: 'small' | 'large';
  productsSubTotal: number;
  deliveryFee: number | undefined;
  orderVAT: number;
  VATPercentage: number;
  tariffPercentage?: number | null;
  orderTariff?: number | null;
  productsAndDeliverySubtotal: number;
  basketTotalIncludingTaxes: number;
  deliveryLabel: React.ReactNode;
  subtotalLabel: React.ReactNode;
  totalIncludingTaxesLabel: React.ReactNode;
  currencyCode?: string;
}

export const OrderSummary = ({
  size,
  productsSubTotal,
  deliveryFee,
  orderVAT,
  VATPercentage,
  tariffPercentage,
  productsAndDeliverySubtotal,
  basketTotalIncludingTaxes,
  orderTariff,
  deliveryLabel,
  subtotalLabel,
  totalIncludingTaxesLabel,
  currencyCode,
}: Props) => {
  const { t } = useTranslation();
  const { currentOrganisation } = useOrganisationsContext();
  const currency =
    currencyCode || currentOrganisation?.defaultCurrency || UK_CURRENCY;

  const hasTariff = Boolean(currencyCode === 'USD' && tariffPercentage);

  const SummaryTitle = () => {
    if (size === 'small') {
      return (
        <TextLabel
          size={LabelSize.Medium}
          weight={LabelWeight['Semi-Bold']}
          darkGrey
          css={{
            paddingBottom: '$spacing-fluid-block-x-small',
          }}
        >
          {t('orderDetails.summary')}
        </TextLabel>
      );
    } else {
      return (
        <Title
          size={TitleSize.Small}
          weight={TitleWeight['Semi-Bold']}
          css={{
            paddingBottom: '$spacing-fluid-block-x-small',
            fontFamily: '$font-latin-sans-serif-1',
            color: '$colour-brand-neutral-hue-1-shade-2',
          }}
        >
          {t('orderDetails.summary')}
        </Title>
      );
    }
  };

  const ProductsSubtotal = () => {
    if (currentOrganisation?.config?.prepaidStock) {
      return <>{t('viewBasket.prepaid')}</>;
    }
    if (typeof productsSubTotal === 'number') {
      return <>{getPrice(productsSubTotal, currency)}</>;
    } else return <></>;
  };

  const textLabelSize = size === 'large' ? LabelSize.Large : LabelSize.Small;

  return (
    <SummaryContentsWrapper>
      <Row>
        <RowItem noTopPaddingOrMargin={size === 'small'}>
          <SummaryTitle />
        </RowItem>
      </Row>
      <Row>
        <RowItem>
          <TextLabel size={textLabelSize} weight={LabelWeight.Regular}>
            {t('orderDetails.product_other')}
          </TextLabel>
        </RowItem>
        <RowItem>
          <TextLabel size={textLabelSize} weight={LabelWeight.Regular}>
            <ProductsSubtotal />
          </TextLabel>
        </RowItem>
      </Row>
      <Row borderBottom>
        <RowItem>
          <TextLabel size={textLabelSize} weight={LabelWeight.Regular}>
            {deliveryLabel}
          </TextLabel>
        </RowItem>
        <RowItem>
          <TextLabel
            size={textLabelSize}
            weight={LabelWeight.Regular}
            css={{ textAlign: 'right' }}
          >
            {getDeliveryFeeText({
              notApplicableText: t('orderDetails.notApplicable'),
              deliveryFee,
              currencyCode: currency,
            })}
          </TextLabel>
        </RowItem>
      </Row>
      <Row>
        <RowItem>
          <TextLabel size={textLabelSize} weight={LabelWeight.Regular}>
            {subtotalLabel}
          </TextLabel>
        </RowItem>
        <RowItem>
          <TextLabel size={textLabelSize} weight={LabelWeight.Regular}>
            {typeof productsAndDeliverySubtotal === 'number'
              ? getPrice(productsAndDeliverySubtotal, currency)
              : null}
          </TextLabel>
        </RowItem>
      </Row>
      <Row borderBottom={!hasTariff}>
        <RowItem>
          <TextLabel size={textLabelSize} weight={LabelWeight.Regular}>
            {hasTariff
              ? t('orderDetails.taxExempt')
              : t('orderDetails.vat', { VATPercentage })}
          </TextLabel>
        </RowItem>
        <RowItem>
          <TextLabel size={textLabelSize} weight={LabelWeight.Regular}>
            {typeof orderVAT === 'number'
              ? getPrice(orderVAT, currency)
              : getPrice(0, currency)}
          </TextLabel>
        </RowItem>
      </Row>
      {hasTariff && (
        <Row borderBottom>
          <RowItem>
            <TextLabel size={textLabelSize} weight={LabelWeight.Regular}>
              {t('orderDetails.tariff', { tariffPercentage })}
            </TextLabel>
          </RowItem>
          <RowItem>
            <TextLabel size={textLabelSize} weight={LabelWeight.Regular}>
              {orderTariff ? getPrice(orderTariff, currency) : '£0.00'}
            </TextLabel>
          </RowItem>
        </Row>
      )}
      <Row>
        <RowItem>
          <TextLabel
            size={textLabelSize}
            weight={LabelWeight['Semi-Bold']}
            darkGrey
          >
            {totalIncludingTaxesLabel}
          </TextLabel>
        </RowItem>
        <RowItem>
          <TextLabel
            size={textLabelSize}
            weight={LabelWeight['Semi-Bold']}
            darkGrey
          >
            {getPrice(basketTotalIncludingTaxes, currency)}
          </TextLabel>
        </RowItem>
      </Row>
    </SummaryContentsWrapper>
  );
};
