import { IconName } from '@sourceful/design-system';
import dayjs from 'dayjs';
import i18next from 'i18next';
import {
  getTotalMaximumDeliveryTime,
  getTotalMinimumDeliveryTime,
} from '../../../../shared/components/Orders/utils';
import { getProductStockLevel } from '../../../../shared/hooks/getProductStockLevel/GetProductStockLevel';
import { Address } from '../../../../shared/types/Address';
import { Product } from '../../../../shared/types/Product';
import { getNumberOrZero } from '../../../../shared/utils/getNumberOrZero/getNumberOrZero';

export const subheaderData = (
  product: Product,
  currentOrgAddresses: Address[] | undefined
): {
  subheaderText: string;
  subheaderIcon: IconName;
  // eslint-disable-next-line
  subheaderToolTipText?: any;
} | null => {
  if (product !== null && product.productType?.name === 'Warehouse') {
    return {
      subheaderText: getProductStockLevel(product).label,
      subheaderIcon: 'icon-navigation-location-sourceful',
      subheaderToolTipText: i18next.t('viewProducts.stockLevel'),
    };
  }

  if (product !== null && product.productType?.name === 'Dropship') {
    const minDeliveryTimesForAllAddresses = currentOrgAddresses?.map(address =>
      getNumberOrZero(address.deliveryTimeline?.min)
    );
    const minDeliveryTime = minDeliveryTimesForAllAddresses
      ? Math.min(...minDeliveryTimesForAllAddresses)
      : 0;
    const maxDeliveryTimesForAllAddresses = currentOrgAddresses?.map(address =>
      address.deliveryTimeline?.max ? address.deliveryTimeline?.max : 0
    );
    const maxDeliveryTime = maxDeliveryTimesForAllAddresses
      ? Math.max(...maxDeliveryTimesForAllAddresses)
      : 0;

    const totalMinimumDeliveryTime = getTotalMinimumDeliveryTime(
      minDeliveryTime,
      [product.manufacturingLeadTime?.min || 1]
    );

    const totalMaximumDeliveryTime = getTotalMaximumDeliveryTime(
      maxDeliveryTime,
      [product.manufacturingLeadTime?.max || 2]
    );

    const deliversByDate = dayjs()
      .add(totalMaximumDeliveryTime, 'day')
      .format('D MMMM YYYY')
      .toString();

    return {
      subheaderText: i18next.t('viewProducts.deliveryLeadTime', {
        min: totalMinimumDeliveryTime,
        max: totalMaximumDeliveryTime,
        durationType: product.manufacturingLeadTime?.units,
      }),
      subheaderIcon: 'icon-shopping-shipping',
      subheaderToolTipText: i18next.t('viewProducts.deliversBy', {
        date: deliversByDate,
      }),
    };
  }
  return null;
};
