import {
  Label,
  LabelSize,
  LabelWeight,
  LabelLineHeight,
} from '@sourceful/design-system';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { UK_CURRENCY, UK_LOCALE } from '../../../../../shared/constants';
import { getProductStockLevel } from '../../../../../shared/hooks/getProductStockLevel/GetProductStockLevel';
import useWindowDimensions from '../../../../../shared/hooks/useWindowDimensions/useWindowDimensions';
import { useOrganisationsContext } from '../../../../../shared/providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { AddressValue, Values } from '../../../../../shared/types/Address';
import { Product } from '../../../../../shared/types/Product';
import { getNumberWithCommas } from '../../../../../shared/utils/getNumberWithCommas/getNumberWithCommas';
import { getNumberOfPacks, getProductSubTotal } from '../../utils';
import AddProductToBasketFooter from '../AddProductToBasketFooter/AddProductToBasketFooter';
import {
  ProductTable,
  ProductTableRow,
  ProductTableColumn,
  TableHeaderText,
} from '../AddProductToBasketModal/AddProductToBasketModal.styled';
import AddProductToBasketProductRow from '../AddProductToBasketProductRow/AddProductToBasketProductRow';
import {
  ScrollArea,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from './ScrollbarStyled';
import { canPlaceDropshipOrderBasedOnQuantity } from './utils';
import { getAllMoqPricesSortedDescending } from '../../../../../shared/utils/getMoqPrice/getMoqPrice';
import { ProductPrice } from '../../../../../shared/types/ProductPrice';

export interface Props {
  product: Product;
  isEditing: boolean;
  onSubmit: (values: Values) => void;
  onCancel: () => void;
  addressesValues: AddressValue[];
  setQuantityInBasketForMoq: React.Dispatch<React.SetStateAction<number>>;
  productHasMoqPricing: boolean;
}

export const AddDropshipProductToBasketForm = ({
  product,
  onSubmit,
  onCancel,
  addressesValues,
  isEditing,
  setQuantityInBasketForMoq,
  productHasMoqPricing,
}: Props) => {
  const { height } = useWindowDimensions();
  const { t } = useTranslation();
  const addressesHeight = addressesValues.length * 135;
  const locationsWrapperHeight = Math.min(height / 2.6, addressesHeight);

  const initialValues = { addressesValues } as Values;
  const { currentOrganisation } = useOrganisationsContext();
  const locale: string = currentOrganisation?.locale || UK_LOCALE;
  const currencyCode: string =
    currentOrganisation?.defaultCurrency || UK_CURRENCY;
  const moqPrices: ProductPrice[] = getAllMoqPricesSortedDescending(
    product.productPrices
  );

  const minOrderQuantityForMoqProducts: number = productHasMoqPricing
    ? moqPrices[moqPrices.length - 1].minOrderQuantity || 1
    : 1;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ addressesValues }: Values) => {
        const standardisedAddressesValues = addressesValues.map(
          addressValue => ({
            address: addressValue.address,
            quantity: Number(addressValue.quantity),
          })
        );

        const filteredAddressesValues = standardisedAddressesValues.filter(
          addressesValue => addressesValue.quantity
        );

        onSubmit({ addressesValues: filteredAddressesValues });
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        const numberOfPacks = getNumberOfPacks(values.addressesValues);
        const canPlaceAnOrder = canPlaceDropshipOrderBasedOnQuantity({
          numberOfPacks,
          isEditing,
        });

        const productSubTotal = getProductSubTotal({
          product,
          numberOfPacks,
          locale,
          currencyCode,
        });

        const zeroPrice = Number(0).toLocaleString(locale, {
          style: 'currency',
          currency: currencyCode,
        });

        const isUnderMoq =
          productSubTotal === zeroPrice && productHasMoqPricing;
        return (
          <Form>
            <Label
              size={LabelSize.Medium}
              lineHeight={LabelLineHeight.Normal}
              css={{ marginTop: 12 }}
            >
              {values.addressesValues.length === 0 && (
                <div>{t('addProductToBasket.youDontHaveAnyLocationsYet')}</div>
              )}
            </Label>
            <ScrollArea style={{ height: locationsWrapperHeight }}>
              <ScrollAreaViewport>
                <ProductTable>
                  <ProductTableRow
                    css={{ marginBottom: '$spacing-fluid-block-x-small' }}
                  >
                    <ProductTableColumn style={{ flex: '0 0 50%' }}>
                      <TableHeaderText
                        size={LabelSize.Medium}
                        weight={LabelWeight['Semi-Bold']}
                      >
                        {t('addProductToBasket.location')}
                      </TableHeaderText>
                    </ProductTableColumn>
                    <ProductTableColumn style={{ flex: '0 0 30%' }}>
                      <TableHeaderText
                        size={LabelSize.Medium}
                        weight={LabelWeight['Semi-Bold']}
                      >
                        {t('addProductToBasket.packQuantity')}
                      </TableHeaderText>
                    </ProductTableColumn>
                    <ProductTableColumn
                      style={{
                        flex: '0 0 20%',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <TableHeaderText
                        size={LabelSize.Medium}
                        weight={LabelWeight['Semi-Bold']}
                        css={{ paddingRight: '$spacing-fluid-block-small' }}
                      >
                        {t('addProductToBasket.productCost')}
                      </TableHeaderText>
                    </ProductTableColumn>
                  </ProductTableRow>
                  {values.addressesValues.length > 0 &&
                    values.addressesValues.map(({ address }, index) => {
                      return (
                        <AddProductToBasketProductRow
                          index={index}
                          key={index}
                          amountOfLocations={values.addressesValues.length}
                          address={address}
                          product={product}
                          numberOfPacks={numberOfPacks}
                          numberOfPacksForCurrentLocation={
                            values.addressesValues[index].quantity || 0
                          }
                          amountOfUnits={
                            product.unitQuantity
                              ? values.addressesValues[index].quantity *
                                product.unitQuantity
                              : 0
                          } /* eslint-disable @typescript-eslint/no-explicit-any */
                          setFieldValue={(field: string, value: any) => {
                            setFieldValue(field, value);
                            if (productHasMoqPricing) {
                              setQuantityInBasketForMoq(value);
                            }
                          }}
                          inputQuantityValue={
                            values.addressesValues[index].quantity
                          }
                          locale={locale}
                          currencyCode={currencyCode}
                          productHasMoqPricing={productHasMoqPricing}
                          isUnderMoq={isUnderMoq}
                          minOrderQuantity={minOrderQuantityForMoqProducts}
                        />
                      );
                    })}
                </ProductTable>
              </ScrollAreaViewport>
              <ScrollAreaScrollbar orientation="vertical">
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </ScrollArea>

            <AddProductToBasketFooter
              overflowQuantity={0}
              amountOfPacksInBasket={numberOfPacks}
              amountOfPacksAvailable={
                getProductStockLevel(product).numberOfPacks
              }
              unitsTotalFormatted={
                product.unitQuantity
                  ? getNumberWithCommas(numberOfPacks * product?.unitQuantity)
                  : ''
              }
              productSubTotal={productSubTotal}
              isCtaDisabled={!canPlaceAnOrder || isUnderMoq}
              onCancel={() => onCancel()}
              handleSubmit={() => handleSubmit()}
              isEditing={isEditing}
              currencyCode={currencyCode}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
