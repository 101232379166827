const getConfigValue = (variable: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return process.env[variable] || (window as any)[variable];
};

export const config = {
  logrocketAppId: getConfigValue('REACT_APP_LOGROCKET_APP_ID'),
  apiOriginUrl: getConfigValue('REACT_APP_API_ORIGIN'),
  environment: getConfigValue('REACT_APP_ENVIRONMENT'),
  sentryTrackingUrl: getConfigValue('REACT_APP_SENTRY_URL'),
  auth0ClientId: getConfigValue('REACT_APP_AUTH0_CLIENT_ID'),
  auth0Domain: getConfigValue('REACT_APP_AUTH0_DOMAIN'),
  auth0Audience: getConfigValue('REACT_APP_AUTH0_AUDIENCE'),
  auth0LogoutUrl: getConfigValue('REACT_APP_AUTH0_LOGOUT_URL'),
  stockManagementGraphqlEndpoint: getConfigValue(
    'REACT_APP_STOCK_MANAGEMENT_GRAPHQL_ENDPOINT'
  ),
  datadogApplicationId: getConfigValue('REACT_APP_DATADOG_APPLICATION_ID'),
  datadogClientToken: getConfigValue('REACT_APP_DATADOG_CLIENT_TOKEN'),
  datadogSite: getConfigValue('REACT_APP_DATADOG_SITE'),
  datadogService: getConfigValue('REACT_APP_DATADOG_SERVICE'),
  datadogEnvironment: getConfigValue('REACT_APP_DATADOG_ENVIRONMENT'),
  appVersion: getConfigValue('REACT_APP_VERSION_SHA'),
  fileUploaderBaseUrl: getConfigValue('REACT_APP_FILE_UPLOADER_API_BASE_URL'),
  featureFlagWarehouseHolidayBannerEnabled: getConfigValue(
    'REACT_APP_WAREHOUSE_HOLIDAY_BANNER_ENABLED'
  ),
};

export default config;
