import { styled, Label } from '@sourceful/design-system';

export const SummaryContentsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const Row = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  variants: {
    title: {
      true: {
        paddingBottom: '$spacing-fluid-block-x-small',
      },
    },
    borderBottom: {
      true: {
        borderBottom: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
      },
    },
  },
});

export const RowItem = styled('div', {
  padding: '$spacing-fluid-block-xx-small 0 $spacing-fluid-block-xx-small 0',
  margin: '$spacing-fluid-block-xx-small 0 $spacing-fluid-block-xx-small 0',
  variants: {
    noTopPaddingOrMargin: {
      true: {
        marginTop: 0,
        paddingTop: 0,
      },
    },
  },
});

export const TextLabel = styled(Label, {
  color: '$colour-brand-neutral-hue-1-shade-1',
  fontFamily: '$font-latin-sans-serif-1',
  variants: {
    darkGrey: {
      true: {
        color: '$colour-brand-neutral-hue-1-shade-2',
      },
    },
  },
});
