import { Icon, Label, styled } from '@sourceful/design-system';

export const LocationCard = styled('div', {
  display: 'flex',
  width: '100%',
  borderRadius: '8px',
  border: '1px solid #D0D0D2',
  padding: 20,
  marginBottom: '$spacing-fluid-block-x-small',
});

export const AlertIcon = styled(Icon, {
  color: '#D30339',
});

export const LocationsWrapper = styled('div', {
  marginTop: 32,
  marginBottom: 32,
  overflow: 'auto',
  maxHeight: '400px',
});

export const AddressInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
});

export const FormFooter = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const FormFooterRow = styled('div', {
  display: 'flex',
  variants: {
    spaceBetween: {
      true: {
        justifyContent: 'space-between',
      },
    },
    alignRight: {
      true: {
        justifyContent: 'flex-end',
      },
    },
    borderBottom: {
      true: {
        borderBottom: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
        marginBottom: '$spacing-fluid-block-xx-small',
      },
    },
  },
});

export const FormFooterRowItem = styled('div', {
  padding: '$spacing-fluid-block-xx-small 0 $spacing-fluid-block-xx-small 0',
  margin: '$spacing-fluid-block-xx-small 0 $spacing-fluid-block-xx-small 0',
  variants: {
    noTopPaddingOrMargin: {
      true: {
        marginTop: 0,
        paddingTop: 0,
      },
    },
  },
});

export const FormFooterRowTextLabel = styled(Label, {
  color: '$colour-brand-neutral-hue-1-shade-1',
  fontFamily: '$font-latin-sans-serif-1',
  variants: {
    darkGrey: {
      true: {
        color: '$colour-brand-neutral-hue-1-shade-2',
      },
    },
  },
});

export const FormFooterInfo = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

export const Input = styled('input', {
  width: '100%',
  borderRadius: '$radii-small',
  borderColor: 'lightgrey',
  '&:disabled': {
    background: '$colour-brand-neutral-hue-1-tint-6',
    color: '$colour-brand-neutral-hue-1-tint-1',
    borderColor: '$colour-brand-neutral-hue-1-tint-6',
    borderRadius: '$radii-small',
    borderWidth: '1px',
  },
});

export const Actions = styled('div', {
  display: 'flex',
  width: '60%',
});
