import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders';
import {
  FormFooter,
  FormFooterRow,
  FormFooterInfo,
  FormFooterRowItem,
} from '../AddProductToBasketForm/AddProductToBasketFormStyled';

export const AddProductToBasketFooterSkeleton = () => {
  return (
    <FormFooter
      className="show-loading-animation"
      css={{ marginTop: '$spacing-fluid-block-medium' }}
    >
      <FormFooterRow spaceBetween>
        <FormFooterInfo>
          <FormFooterRowItem>
            <TextBlock rows={1} color="lightGray" style={{ minWidth: 150 }} />
          </FormFooterRowItem>
        </FormFooterInfo>
        <FormFooterInfo>
          <FormFooterRowItem>
            <TextBlock rows={1} color="lightGray" style={{ minWidth: 60 }} />
          </FormFooterRowItem>
        </FormFooterInfo>
      </FormFooterRow>
      <FormFooterRow spaceBetween borderBottom>
        <FormFooterInfo>
          <FormFooterRowItem>
            <TextBlock rows={1} color="lightGray" style={{ minWidth: 150 }} />
          </FormFooterRowItem>
        </FormFooterInfo>
        <FormFooterInfo>
          <FormFooterRowItem>
            <TextBlock rows={1} color="lightGray" style={{ minWidth: 60 }} />
          </FormFooterRowItem>
        </FormFooterInfo>
      </FormFooterRow>

      <FormFooterRow spaceBetween>
        <FormFooterInfo>
          <FormFooterRowItem>
            <TextBlock rows={1} color="lightGray" style={{ minWidth: 150 }} />
          </FormFooterRowItem>
        </FormFooterInfo>
        <FormFooterInfo>
          <FormFooterRowItem>
            <TextBlock rows={1} color="lightGray" style={{ minWidth: 60 }} />
          </FormFooterRowItem>
        </FormFooterInfo>
      </FormFooterRow>
      <FormFooterRow alignRight>
        <RectShape
          color="lightGray"
          className="show-loading-animation"
          style={{
            width: '140px',
            height: 40,
            marginTop: '20px',
          }}
        />

        <RectShape
          color="lightGray"
          className="show-loading-animation"
          style={{
            width: '140px',
            height: 40,
            marginTop: '20px',
          }}
        />
      </FormFooterRow>
    </FormFooter>
  );
};
