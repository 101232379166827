import { useQuery, UseQueryOptions } from 'react-query';
import { useFetcher } from './useFetcher';
import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Address = {
  addressDescription?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  deliveryFee?: Maybe<Scalars['Int']>;
  deliveryTimeline?: Maybe<DeliveryTimelineMetaData>;
  externalAddressId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isInternal?: Maybe<Scalars['Boolean']>;
  isWarehouse?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  taxRates?: Maybe<Array<Maybe<TaxRate>>>;
};

export type Attachment = {
  folderId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type ClientOrder = {
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['String']>;
  attachments: Array<Maybe<Attachment>>;
  clientOrderLines: Array<Maybe<ClientOrderLine>>;
  clientOrderSummary?: Maybe<ClientOrderSummary>;
  clientOrderTaxes?: Maybe<Array<Maybe<ClientOrderTax>>>;
  clientReference?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  contractualExpectedDeliveryDate?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdByUserId?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  deliveredAt?: Maybe<Scalars['String']>;
  deliveryExcludingVAT?: Maybe<Scalars['Float']>;
  deliveryInstructions?: Maybe<Scalars['String']>;
  dueDateManual?: Maybe<Scalars['String']>;
  externalClientOrderId?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  organisationId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['Int']>;
  status?: Maybe<ClientOrderStatus>;
  statusId?: Maybe<Scalars['Int']>;
  totalIncludingVAT?: Maybe<Scalars['Float']>;
};

export type ClientOrderLine = {
  cancelledQuantity?: Maybe<Scalars['Int']>;
  clientOrderTaxes?: Maybe<Array<Maybe<ClientOrderTax>>>;
  deliveredAt?: Maybe<Scalars['String']>;
  deliveredQuantity?: Maybe<Scalars['Int']>;
  externalProductId?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  fulfilledQuantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  packSize?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  productsSourceType?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  quantityInUnits?: Maybe<Scalars['Int']>;
  status?: Maybe<ClientOrderStatus>;
  statusId?: Maybe<Scalars['Int']>;
  totalExcludingVAT?: Maybe<Scalars['Float']>;
};

export type ClientOrderStatus = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ClientOrderSummary = {
  currencyCode?: Maybe<Scalars['String']>;
  deliveryValueExcludingVAT?: Maybe<Scalars['Float']>;
  orderSubtotal?: Maybe<Scalars['Float']>;
  productsSubtotal?: Maybe<Scalars['Float']>;
  tariffPercentage?: Maybe<Scalars['Float']>;
  tariffTaxRateValue?: Maybe<Scalars['Float']>;
  tariffValue?: Maybe<Scalars['Float']>;
  totalIncludingTaxes?: Maybe<Scalars['Float']>;
  vatPercentage?: Maybe<Scalars['Float']>;
  vatValue?: Maybe<Scalars['Float']>;
};

export type ClientOrderTax = {
  clientOrderId?: Maybe<Scalars['String']>;
  clientOrderLineId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  taxRateId?: Maybe<Scalars['Int']>;
};

export type Contact = {
  addressId?: Maybe<Scalars['String']>;
  contactEmailAddress?: Maybe<Scalars['String']>;
  contactJobTitle?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['String']>;
  preferredCommunicationMethodId?: Maybe<Scalars['Int']>;
  taxNumber?: Maybe<Scalars['String']>;
};

export type DeliveryCost = {
  costPerPackExclVat?: Maybe<Scalars['Float']>;
  countryCode?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type DeliveryInformation = {
  addressId?: Maybe<Scalars['String']>;
  deliveryFee?: Maybe<Scalars['Int']>;
  deliveryTimeline?: Maybe<DeliveryTimeline>;
};

export type DeliveryTimeline = {
  businessDaysOnly?: Maybe<Scalars['Boolean']>;
  contractualExpectedDeliveryDate?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['String']>;
};

export type DeliveryTimelineMetaData = {
  businessDaysOnly?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['String']>;
};

export type EnhancedOrganisation = {
  addresses?: Maybe<Array<Maybe<Address>>>;
  config?: Maybe<OrganisationConfig>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  defaultCurrency?: Maybe<Scalars['String']>;
  externalOrganisationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
};

export type ManufacturingLeadTime = {
  businessDaysOnly?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['String']>;
};

export type Organisation = {
  addresses?: Maybe<Array<Maybe<Address>>>;
  config?: Maybe<OrganisationConfig>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  defaultCurrency?: Maybe<Scalars['String']>;
  externalOrganisationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
};

export type OrganisationConfig = {
  autostockEnabled?: Maybe<Scalars['Boolean']>;
  computeDeliveryCostPerProduct?: Maybe<Scalars['Boolean']>;
  deliveryInvoicedSeparately?: Maybe<Scalars['Boolean']>;
  displayProductUnitCost?: Maybe<Scalars['Boolean']>;
  enableClientReferenceCollection?: Maybe<Scalars['Boolean']>;
  mainProviderType?: Maybe<Scalars['String']>;
  orderFlowTypeId?: Maybe<Scalars['Int']>;
  orderProcessingType?: Maybe<Scalars['String']>;
  paymentDisclaimer?: Maybe<Scalars['String']>;
  paymentDueDate?: Maybe<PaymentDueDateType>;
  paymentTerms?: Maybe<Scalars['String']>;
  prepaidStock?: Maybe<Scalars['Boolean']>;
  productPricingSourceType?: Maybe<Scalars['String']>;
  productsSourceType?: Maybe<Scalars['String']>;
  selfserviceEnabled?: Maybe<Scalars['Boolean']>;
  xeroClientName?: Maybe<Scalars['String']>;
};

export type PaginationClientOrderResult = {
  data: Array<Maybe<ClientOrder>>;
  pagination: PaginationInfo;
};

export type PaginationInfo = {
  currentPage: Scalars['Int'];
  from: Scalars['Int'];
  lastPage?: Maybe<Scalars['Int']>;
  perPage: Scalars['Int'];
  to: Scalars['Int'];
  total?: Maybe<Scalars['Int']>;
};

export type PaymentDueDateType = {
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type Product = {
  additionalAttributes?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  deliveryCosts?: Maybe<Array<Maybe<DeliveryCost>>>;
  description?: Maybe<Scalars['String']>;
  externalProductId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  manufacturingLeadTime?: Maybe<ManufacturingLeadTime>;
  name?: Maybe<Scalars['String']>;
  productPrices: Array<Maybe<ProductPrice>>;
  productStocks: Array<Maybe<ProductStock>>;
  productType?: Maybe<ProductType>;
  productTypeId?: Maybe<Scalars['Int']>;
  unitQuantity?: Maybe<Scalars['Int']>;
};

export type ProductPrice = {
  currencyCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  minOrderQuantity?: Maybe<Scalars['Int']>;
  pricePerUnit?: Maybe<Scalars['Float']>;
};

export type ProductStock = {
  address?: Maybe<Address>;
  addressId?: Maybe<Scalars['String']>;
  allocatedToOrganisationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  initialQuantity?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  stockConditionId?: Maybe<Scalars['Int']>;
  stockStatusId?: Maybe<Scalars['Int']>;
};

export type ProductType = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Query = {
  allOrganisations: Array<Maybe<Organisation>>;
  currentOrganisation?: Maybe<EnhancedOrganisation>;
  currentOrganisationProduct?: Maybe<Product>;
  currentOrganisationProducts: Array<Maybe<Product>>;
  getCurrentOrganisationClientOrder?: Maybe<ClientOrder>;
  getCurrentOrganisationClientOrders: PaginationClientOrderResult;
  organisationById?: Maybe<Organisation>;
  userOrganisations: Array<Maybe<Organisation>>;
};

export type QueryCurrentOrganisationProductArgs = {
  productId: Scalars['String'];
};

export type QueryGetCurrentOrganisationClientOrderArgs = {
  clientOrderId: Scalars['String'];
};

export type QueryGetCurrentOrganisationClientOrdersArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryOrganisationByIdArgs = {
  organisationId: Scalars['String'];
};

export type TaxRate = {
  countryCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  taxPercentage?: Maybe<Scalars['Float']>;
  taxRateType?: Maybe<TaxRateType>;
  taxRateTypeId?: Maybe<Scalars['Int']>;
  taxValue?: Maybe<Scalars['Float']>;
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
};

export type TaxRateType = {
  applyToDelivery?: Maybe<Scalars['Boolean']>;
  applyToProducts?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type GetCurrentOrganisationProductQueryVariables = Exact<{
  productId: Scalars['String'];
}>;

export type GetCurrentOrganisationProductQuery = {
  currentOrganisationProduct?: {
    id?: string | null;
    name?: string | null;
    externalProductId?: string | null;
    additionalAttributes?: string | null;
    description?: string | null;
    imageUrl?: string | null;
    unitQuantity?: number | null;
    productTypeId?: number | null;
    productType?: { id?: number | null; name?: string | null } | null;
    manufacturingLeadTime?: {
      min?: number | null;
      max?: number | null;
      units?: string | null;
      businessDaysOnly?: boolean | null;
    } | null;
    deliveryCosts?: Array<{
      currencyCode?: string | null;
      countryCode?: string | null;
      costPerPackExclVat?: number | null;
    } | null> | null;
    productPrices: Array<{
      id?: string | null;
      currencyCode?: string | null;
      minOrderQuantity?: number | null;
      pricePerUnit?: number | null;
    } | null>;
    productStocks: Array<{
      id?: string | null;
      productId?: string | null;
      addressId?: string | null;
      allocatedToOrganisationId?: string | null;
      stockConditionId?: number | null;
      stockStatusId?: number | null;
      initialQuantity?: number | null;
      quantity?: number | null;
      address?: {
        id?: string | null;
        externalAddressId?: string | null;
        isWarehouse?: boolean | null;
        isInternal?: boolean | null;
        organisationId?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        addressLine3?: string | null;
        city?: string | null;
        postcode?: string | null;
        countryCode?: string | null;
        addressDescription?: string | null;
        name?: string | null;
      } | null;
    } | null>;
  } | null;
};

export type GetCurrentOrganisationClientOrderQueryVariables = Exact<{
  clientOrderId: Scalars['String'];
}>;

export type GetCurrentOrganisationClientOrderQuery = {
  getCurrentOrganisationClientOrder?: {
    id?: string | null;
    organisationId?: string | null;
    sourceId?: number | null;
    currencyCode?: string | null;
    clientReference?: string | null;
    createdAt?: string | null;
    isDraft?: boolean | null;
    createdByUserId?: string | null;
    statusId?: number | null;
    deliveredAt?: string | null;
    contactId?: string | null;
    folderId?: string | null;
    externalClientOrderId?: string | null;
    deliveryInstructions?: string | null;
    addressId?: string | null;
    deliveryExcludingVAT?: number | null;
    contractualExpectedDeliveryDate?: string | null;
    attachments: Array<{
      uuid?: string | null;
      originalFilename?: string | null;
      folderId?: string | null;
      userId?: string | null;
      metadata?: string | null;
    } | null>;
    clientOrderSummary?: {
      deliveryValueExcludingVAT?: number | null;
      tariffPercentage?: number | null;
      tariffValue?: number | null;
      productsSubtotal?: number | null;
      orderSubtotal?: number | null;
      totalIncludingTaxes?: number | null;
      vatPercentage?: number | null;
      vatValue?: number | null;
      currencyCode?: string | null;
    } | null;
    status?: { id?: number | null; name?: string | null } | null;
    address?: {
      id?: string | null;
      externalAddressId?: string | null;
      isWarehouse?: boolean | null;
      isInternal?: boolean | null;
      organisationId?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      addressLine3?: string | null;
      city?: string | null;
      postcode?: string | null;
      countryCode?: string | null;
      addressDescription?: string | null;
      name?: string | null;
    } | null;
    clientOrderLines: Array<{
      productId?: string | null;
      totalExcludingVAT?: number | null;
      statusId?: number | null;
      quantity?: number | null;
      packSize?: number | null;
      quantityInUnits?: number | null;
      fulfilledQuantity?: number | null;
      deliveredQuantity?: number | null;
      cancelledQuantity?: number | null;
      folderId?: string | null;
      deliveredAt?: string | null;
      product?: {
        imageUrl?: string | null;
        unitQuantity?: number | null;
        name?: string | null;
        deliveryCosts?: Array<{
          currencyCode?: string | null;
          countryCode?: string | null;
          costPerPackExclVat?: number | null;
        } | null> | null;
        productPrices: Array<{
          id?: string | null;
          currencyCode?: string | null;
          minOrderQuantity?: number | null;
          pricePerUnit?: number | null;
        } | null>;
      } | null;
      status?: { id?: number | null; name?: string | null } | null;
    } | null>;
  } | null;
};

export type GetCurrentOrganisationClientOrdersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentOrganisationClientOrdersQuery = {
  getCurrentOrganisationClientOrders: {
    data: Array<{
      id?: string | null;
      externalClientOrderId?: string | null;
      isDraft?: boolean | null;
      createdAt?: string | null;
      currencyCode?: string | null;
      deliveredAt?: string | null;
      contractualExpectedDeliveryDate?: string | null;
      address?: { name?: string | null } | null;
      clientOrderSummary?: {
        deliveryValueExcludingVAT?: number | null;
        tariffPercentage?: number | null;
        tariffValue?: number | null;
        productsSubtotal?: number | null;
        orderSubtotal?: number | null;
        totalIncludingTaxes?: number | null;
        vatPercentage?: number | null;
        vatValue?: number | null;
        currencyCode?: string | null;
      } | null;
      status?: { id?: number | null; name?: string | null } | null;
      clientOrderLines: Array<{ id?: string | null } | null>;
    } | null>;
    pagination: {
      from: number;
      to: number;
      currentPage: number;
      perPage: number;
      total?: number | null;
      lastPage?: number | null;
    };
  };
};

export type GetCurrentOrganisationProductsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentOrganisationProductsQuery = {
  currentOrganisationProducts: Array<{
    id?: string | null;
    name?: string | null;
    deletedAt?: string | null;
    externalProductId?: string | null;
    productTypeId?: number | null;
    additionalAttributes?: string | null;
    description?: string | null;
    imageUrl?: string | null;
    unitQuantity?: number | null;
    productType?: { id?: number | null; name?: string | null } | null;
    deliveryCosts?: Array<{
      currencyCode?: string | null;
      costPerPackExclVat?: number | null;
      countryCode?: string | null;
    } | null> | null;
    manufacturingLeadTime?: {
      min?: number | null;
      max?: number | null;
      units?: string | null;
      businessDaysOnly?: boolean | null;
    } | null;
    productPrices: Array<{
      id?: string | null;
      currencyCode?: string | null;
      minOrderQuantity?: number | null;
      pricePerUnit?: number | null;
    } | null>;
    productStocks: Array<{
      id?: string | null;
      productId?: string | null;
      addressId?: string | null;
      allocatedToOrganisationId?: string | null;
      stockConditionId?: number | null;
      stockStatusId?: number | null;
      initialQuantity?: number | null;
      quantity?: number | null;
      address?: {
        id?: string | null;
        externalAddressId?: string | null;
        isWarehouse?: boolean | null;
        isInternal?: boolean | null;
        organisationId?: string | null;
        addressLine1?: string | null;
        addressLine2?: string | null;
        addressLine3?: string | null;
        city?: string | null;
        postcode?: string | null;
        countryCode?: string | null;
        addressDescription?: string | null;
        name?: string | null;
      } | null;
    } | null>;
  } | null>;
};

export type GetCurrentOrganisationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentOrganisationQuery = {
  currentOrganisation?: {
    id?: string | null;
    name?: string | null;
    defaultCurrency?: string | null;
    locale?: string | null;
    externalOrganisationId?: string | null;
    config?: {
      autostockEnabled?: boolean | null;
      selfserviceEnabled?: boolean | null;
      mainProviderType?: string | null;
      orderProcessingType?: string | null;
      deliveryInvoicedSeparately?: boolean | null;
      prepaidStock?: boolean | null;
      computeDeliveryCostPerProduct?: boolean | null;
      paymentDisclaimer?: string | null;
      productsSourceType?: string | null;
      enableClientReferenceCollection?: boolean | null;
      displayProductUnitCost?: boolean | null;
    } | null;
    addresses?: Array<{
      deliveryFee?: number | null;
      addressDescription?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      addressLine3?: string | null;
      city?: string | null;
      region?: string | null;
      countryCode?: string | null;
      externalAddressId?: string | null;
      id?: string | null;
      isWarehouse?: boolean | null;
      name?: string | null;
      organisationId?: string | null;
      postcode?: string | null;
      deliveryTimeline?: {
        min?: number | null;
        max?: number | null;
        units?: string | null;
        businessDaysOnly?: boolean | null;
      } | null;
      taxRates?: Array<{
        id?: number | null;
        taxValue?: number | null;
        taxPercentage?: number | null;
        countryCode?: string | null;
        validFrom?: string | null;
        validTo?: string | null;
        taxRateType?: {
          id?: number | null;
          name?: string | null;
          displayName?: string | null;
          applyToDelivery?: boolean | null;
          applyToProducts?: boolean | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetUserOrganisationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetUserOrganisationsQuery = {
  userOrganisations: Array<{
    id?: string | null;
    name?: string | null;
    externalOrganisationId?: string | null;
    config?: {
      autostockEnabled?: boolean | null;
      selfserviceEnabled?: boolean | null;
      mainProviderType?: string | null;
      orderProcessingType?: string | null;
      deliveryInvoicedSeparately?: boolean | null;
      prepaidStock?: boolean | null;
      computeDeliveryCostPerProduct?: boolean | null;
      paymentDisclaimer?: string | null;
      productsSourceType?: string | null;
      enableClientReferenceCollection?: boolean | null;
      displayProductUnitCost?: boolean | null;
    } | null;
    addresses?: Array<{
      addressDescription?: string | null;
      addressLine1?: string | null;
      addressLine2?: string | null;
      addressLine3?: string | null;
      city?: string | null;
      countryCode?: string | null;
      externalAddressId?: string | null;
      id?: string | null;
      isWarehouse?: boolean | null;
      name?: string | null;
      organisationId?: string | null;
      postcode?: string | null;
      region?: string | null;
    } | null> | null;
  } | null>;
};

export const GetCurrentOrganisationProductDocument = `
    query GetCurrentOrganisationProduct($productId: String!) {
  currentOrganisationProduct(productId: $productId) {
    id
    name
    externalProductId
    additionalAttributes
    description
    imageUrl
    unitQuantity
    productTypeId
    productType {
      id
      name
    }
    manufacturingLeadTime {
      min
      max
      units
      businessDaysOnly
    }
    deliveryCosts {
      currencyCode
      countryCode
      costPerPackExclVat
    }
    productPrices {
      id
      currencyCode
      minOrderQuantity
      pricePerUnit
    }
    productStocks {
      id
      productId
      addressId
      allocatedToOrganisationId
      stockConditionId
      stockStatusId
      initialQuantity
      quantity
      address {
        id
        externalAddressId
        isWarehouse
        isInternal
        organisationId
        addressLine1
        addressLine2
        addressLine3
        city
        postcode
        countryCode
        addressDescription
        name
      }
    }
  }
}
    `;
export const useGetCurrentOrganisationProductQuery = <
  TData = GetCurrentOrganisationProductQuery,
  TError = unknown
>(
  variables: GetCurrentOrganisationProductQueryVariables,
  options?: UseQueryOptions<GetCurrentOrganisationProductQuery, TError, TData>
) =>
  useQuery<GetCurrentOrganisationProductQuery, TError, TData>(
    ['GetCurrentOrganisationProduct', variables],
    useFetcher<
      GetCurrentOrganisationProductQuery,
      GetCurrentOrganisationProductQueryVariables
    >(GetCurrentOrganisationProductDocument).bind(null, variables),
    options
  );
export const GetCurrentOrganisationClientOrderDocument = `
    query getCurrentOrganisationClientOrder($clientOrderId: String!) {
  getCurrentOrganisationClientOrder(clientOrderId: $clientOrderId) {
    id
    organisationId
    sourceId
    currencyCode
    clientReference
    attachments {
      uuid
      originalFilename
      folderId
      userId
      metadata
    }
    clientOrderSummary {
      deliveryValueExcludingVAT
      tariffPercentage
      tariffValue
      productsSubtotal
      orderSubtotal
      totalIncludingTaxes
      vatPercentage
      vatValue
      currencyCode
    }
    createdAt
    isDraft
    createdByUserId
    statusId
    deliveredAt
    contactId
    folderId
    status {
      id
      name
    }
    externalClientOrderId
    deliveryInstructions
    addressId
    deliveryExcludingVAT
    contractualExpectedDeliveryDate
    address {
      id
      externalAddressId
      isWarehouse
      isInternal
      organisationId
      addressLine1
      addressLine2
      addressLine3
      city
      postcode
      countryCode
      addressDescription
      name
    }
    folderId
    deliveredAt
    clientOrderLines {
      productId
      totalExcludingVAT
      product {
        imageUrl
        unitQuantity
        name
        deliveryCosts {
          currencyCode
          countryCode
          costPerPackExclVat
        }
        productPrices {
          id
          currencyCode
          minOrderQuantity
          pricePerUnit
        }
      }
      statusId
      quantity
      packSize
      quantityInUnits
      fulfilledQuantity
      deliveredQuantity
      cancelledQuantity
      folderId
      status {
        id
        name
      }
      deliveredAt
    }
  }
}
    `;
export const useGetCurrentOrganisationClientOrderQuery = <
  TData = GetCurrentOrganisationClientOrderQuery,
  TError = unknown
>(
  variables: GetCurrentOrganisationClientOrderQueryVariables,
  options?: UseQueryOptions<
    GetCurrentOrganisationClientOrderQuery,
    TError,
    TData
  >
) =>
  useQuery<GetCurrentOrganisationClientOrderQuery, TError, TData>(
    ['getCurrentOrganisationClientOrder', variables],
    useFetcher<
      GetCurrentOrganisationClientOrderQuery,
      GetCurrentOrganisationClientOrderQueryVariables
    >(GetCurrentOrganisationClientOrderDocument).bind(null, variables),
    options
  );
export const GetCurrentOrganisationClientOrdersDocument = `
    query getCurrentOrganisationClientOrders {
  getCurrentOrganisationClientOrders(currentPage: 1, perPage: 50) {
    data {
      id
      externalClientOrderId
      isDraft
      createdAt
      currencyCode
      deliveredAt
      address {
        name
      }
      clientOrderSummary {
        deliveryValueExcludingVAT
        tariffPercentage
        tariffValue
        productsSubtotal
        orderSubtotal
        totalIncludingTaxes
        vatPercentage
        vatValue
        currencyCode
      }
      contractualExpectedDeliveryDate
      status {
        id
        name
      }
      clientOrderLines {
        id
      }
    }
    pagination {
      from
      to
      currentPage
      perPage
      total
      lastPage
    }
  }
}
    `;
export const useGetCurrentOrganisationClientOrdersQuery = <
  TData = GetCurrentOrganisationClientOrdersQuery,
  TError = unknown
>(
  variables?: GetCurrentOrganisationClientOrdersQueryVariables,
  options?: UseQueryOptions<
    GetCurrentOrganisationClientOrdersQuery,
    TError,
    TData
  >
) =>
  useQuery<GetCurrentOrganisationClientOrdersQuery, TError, TData>(
    variables === undefined
      ? ['getCurrentOrganisationClientOrders']
      : ['getCurrentOrganisationClientOrders', variables],
    useFetcher<
      GetCurrentOrganisationClientOrdersQuery,
      GetCurrentOrganisationClientOrdersQueryVariables
    >(GetCurrentOrganisationClientOrdersDocument).bind(null, variables),
    options
  );
export const GetCurrentOrganisationProductsDocument = `
    query GetCurrentOrganisationProducts {
  currentOrganisationProducts {
    id
    name
    deletedAt
    externalProductId
    productTypeId
    productType {
      id
      name
    }
    deliveryCosts {
      currencyCode
      costPerPackExclVat
      countryCode
    }
    manufacturingLeadTime {
      min
      max
      units
      businessDaysOnly
    }
    additionalAttributes
    description
    imageUrl
    unitQuantity
    productPrices {
      id
      currencyCode
      minOrderQuantity
      pricePerUnit
    }
    productStocks {
      id
      productId
      addressId
      allocatedToOrganisationId
      stockConditionId
      stockStatusId
      initialQuantity
      quantity
      address {
        id
        externalAddressId
        isWarehouse
        isInternal
        organisationId
        addressLine1
        addressLine2
        addressLine3
        city
        postcode
        countryCode
        addressDescription
        name
      }
    }
  }
}
    `;
export const useGetCurrentOrganisationProductsQuery = <
  TData = GetCurrentOrganisationProductsQuery,
  TError = unknown
>(
  variables?: GetCurrentOrganisationProductsQueryVariables,
  options?: UseQueryOptions<GetCurrentOrganisationProductsQuery, TError, TData>
) =>
  useQuery<GetCurrentOrganisationProductsQuery, TError, TData>(
    variables === undefined
      ? ['GetCurrentOrganisationProducts']
      : ['GetCurrentOrganisationProducts', variables],
    useFetcher<
      GetCurrentOrganisationProductsQuery,
      GetCurrentOrganisationProductsQueryVariables
    >(GetCurrentOrganisationProductsDocument).bind(null, variables),
    options
  );
export const GetCurrentOrganisationDocument = `
    query GetCurrentOrganisation {
  currentOrganisation {
    config {
      autostockEnabled
      selfserviceEnabled
      mainProviderType
      orderProcessingType
      deliveryInvoicedSeparately
      prepaidStock
      computeDeliveryCostPerProduct
      paymentDisclaimer
      productsSourceType
      enableClientReferenceCollection
      displayProductUnitCost
    }
    id
    name
    defaultCurrency
    locale
    externalOrganisationId
    addresses {
      deliveryFee
      deliveryTimeline {
        min
        max
        units
        businessDaysOnly
      }
      addressDescription
      addressLine1
      addressLine2
      addressLine3
      city
      region
      countryCode
      externalAddressId
      id
      isWarehouse
      name
      organisationId
      postcode
      taxRates {
        id
        taxValue
        taxPercentage
        countryCode
        validFrom
        validTo
        taxRateType {
          id
          name
          displayName
          applyToDelivery
          applyToProducts
        }
      }
    }
  }
}
    `;
export const useGetCurrentOrganisationQuery = <
  TData = GetCurrentOrganisationQuery,
  TError = unknown
>(
  variables?: GetCurrentOrganisationQueryVariables,
  options?: UseQueryOptions<GetCurrentOrganisationQuery, TError, TData>
) =>
  useQuery<GetCurrentOrganisationQuery, TError, TData>(
    variables === undefined
      ? ['GetCurrentOrganisation']
      : ['GetCurrentOrganisation', variables],
    useFetcher<
      GetCurrentOrganisationQuery,
      GetCurrentOrganisationQueryVariables
    >(GetCurrentOrganisationDocument).bind(null, variables),
    options
  );
export const GetUserOrganisationsDocument = `
    query GetUserOrganisations {
  userOrganisations {
    config {
      autostockEnabled
      selfserviceEnabled
      mainProviderType
      orderProcessingType
      deliveryInvoicedSeparately
      prepaidStock
      computeDeliveryCostPerProduct
      paymentDisclaimer
      productsSourceType
      enableClientReferenceCollection
      displayProductUnitCost
    }
    id
    name
    externalOrganisationId
    addresses {
      addressDescription
      addressLine1
      addressLine2
      addressLine3
      city
      countryCode
      externalAddressId
      id
      isWarehouse
      name
      organisationId
      postcode
      region
    }
  }
}
    `;
export const useGetUserOrganisationsQuery = <
  TData = GetUserOrganisationsQuery,
  TError = unknown
>(
  variables?: GetUserOrganisationsQueryVariables,
  options?: UseQueryOptions<GetUserOrganisationsQuery, TError, TData>
) =>
  useQuery<GetUserOrganisationsQuery, TError, TData>(
    variables === undefined
      ? ['GetUserOrganisations']
      : ['GetUserOrganisations', variables],
    useFetcher<GetUserOrganisationsQuery, GetUserOrganisationsQueryVariables>(
      GetUserOrganisationsDocument
    ).bind(null, variables),
    options
  );

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    GetCurrentOrganisationProduct(
      variables: GetCurrentOrganisationProductQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetCurrentOrganisationProductQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCurrentOrganisationProductQuery>(
            GetCurrentOrganisationProductDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetCurrentOrganisationProduct',
        'query'
      );
    },
    getCurrentOrganisationClientOrder(
      variables: GetCurrentOrganisationClientOrderQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetCurrentOrganisationClientOrderQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCurrentOrganisationClientOrderQuery>(
            GetCurrentOrganisationClientOrderDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getCurrentOrganisationClientOrder',
        'query'
      );
    },
    getCurrentOrganisationClientOrders(
      variables?: GetCurrentOrganisationClientOrdersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetCurrentOrganisationClientOrdersQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCurrentOrganisationClientOrdersQuery>(
            GetCurrentOrganisationClientOrdersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getCurrentOrganisationClientOrders',
        'query'
      );
    },
    GetCurrentOrganisationProducts(
      variables?: GetCurrentOrganisationProductsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetCurrentOrganisationProductsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCurrentOrganisationProductsQuery>(
            GetCurrentOrganisationProductsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetCurrentOrganisationProducts',
        'query'
      );
    },
    GetCurrentOrganisation(
      variables?: GetCurrentOrganisationQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetCurrentOrganisationQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetCurrentOrganisationQuery>(
            GetCurrentOrganisationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetCurrentOrganisation',
        'query'
      );
    },
    GetUserOrganisations(
      variables?: GetUserOrganisationsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetUserOrganisationsQuery> {
      return withWrapper(
        wrappedRequestHeaders =>
          client.request<GetUserOrganisationsQuery>(
            GetUserOrganisationsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'GetUserOrganisations',
        'query'
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
