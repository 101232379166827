import { Product } from '../../../types/Product';
import { getMoqPrice } from '../../../utils/getMoqPrice/getMoqPrice';
import { DeliveryInfo } from '../BasketProvider';
import { BasketLine, BasketOrder } from '../useBasket';

export interface ComputeBasketOrdersProps {
  basketLines: BasketLine[];
  products?: Product[];
  deliveryInformations?: DeliveryInfo[];
  currencyCode: string;
}

export const computeBasketOrders = ({
  basketLines,
  deliveryInformations = [],
  currencyCode,
}: ComputeBasketOrdersProps): BasketOrder[] => {
  if (basketLines.length === 0) {
    return [];
  }
  const basketOrders: BasketOrder[] = [];

  for (const basketLine of basketLines) {
    for (const addressLine of basketLine.addressLines) {
      const foundIndex = basketOrders.findIndex(
        basketOrder => basketOrder.address.id === addressLine.address.id
      );

      const foundBasketOrderLine = foundIndex !== -1;
      const numberOfUnitsInPack = basketLine.product?.unitQuantity ?? 0;

      const productPriceByCurrency = getMoqPrice({
        totalUnits: numberOfUnitsInPack * basketLine.sumOfAddressLineQuantities,
        prices: basketLine.product.productPrices,
        currencyCode,
      });

      if (foundBasketOrderLine) {
        const foundBasketOrder = basketOrders[foundIndex];

        const deliveryInfo = deliveryInformations.find(
          deliveryInfo => foundBasketOrder.address.id === deliveryInfo.addressId
        );

        const subTotal =
          basketLine.product.unitQuantity &&
          basketLine.product.productPrices.length > 0 &&
          productPriceByCurrency?.pricePerUnit
            ? basketLine.product.unitQuantity *
              productPriceByCurrency.pricePerUnit *
              addressLine.quantity
            : 0;

        const basketOrderLine = {
          product: basketLine.product,
          numberOfPacks: addressLine.quantity,
          subTotal,
          overflowQuantity: basketLine.overflowQuantity,
        };

        const updatedItem = {
          address: foundBasketOrder.address,
          orderLines: [...foundBasketOrder.orderLines, basketOrderLine].filter(
            basketLine => basketLine.numberOfPacks > 0
          ),
          deliveryInfo,
          clientReference: addressLine.clientReference,
          attachments: addressLine.attachments,
          subTotal: foundBasketOrder.subTotal + subTotal,
        };

        basketOrders.splice(foundIndex, 1, updatedItem);
      } else {
        const subTotal =
          basketLine.product.unitQuantity &&
          basketLine.product.productPrices.length > 0 &&
          productPriceByCurrency?.pricePerUnit
            ? basketLine.product.unitQuantity *
              productPriceByCurrency?.pricePerUnit *
              addressLine.quantity
            : 0;

        const preciseSubTotal = Number(
          parseFloat(subTotal.toString()).toPrecision(5)
        );
        const basketOrderLine = {
          product: basketLine.product,
          numberOfPacks: addressLine.quantity,
          subTotal: preciseSubTotal,
          overflowQuantity: basketLine.overflowQuantity,
        };
        const deliveryInfo = deliveryInformations.find(
          deliveryInfo => addressLine.address.id === deliveryInfo.addressId
        );

        basketOrders.push({
          deliveryInfo,
          clientReference: addressLine.clientReference,
          attachments: addressLine.attachments,
          address: addressLine.address,
          orderLines: [basketOrderLine],
          subTotal: preciseSubTotal,
        });
      }
    }
  }

  return basketOrders;
};
