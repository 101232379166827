import { styled, Label } from '@sourceful/design-system';

export const ProductCardWrapper = styled('div', {
  marginTop: 16,
});

export const ProductTable = styled('section', {
  width: '100%',
});

export const ProductTableRow = styled('div', {
  display: 'flex',
  variants: {
    default: {
      true: {
        paddingLeft: '$spacing-fluid-block-x-small',
        paddingRight: '$spacing-fluid-block-small',
        paddingTop: '$spacing-fluid-block-small',
        paddingBottom: '$spacing-fluid-block-x-small',
        background: '$colour-lightest',
        borderBottom: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
        borderRight: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
        borderLeft: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
      },
    },
    compact: {
      true: {
        paddingTop: '$spacing-fluid-block-x-small',
        paddingBottom: '$spacing-fluid-block-x-small',
      },
    },
    hover: {
      true: {
        background: '$colour-brand-neutral-hue-1-tint-6',
      },
    },
    firstRow: {
      true: {
        borderTopLeftRadius: '$radii-small $radii-small',
        borderTopRightRadius: '$radii-small $radii-small',
        borderTop: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
      },
    },
    lastRow: {
      true: {
        borderBottomLeftRadius: '$radii-small $radii-small',
        borderBottomRightRadius: '$radii-small $radii-small',
        borderBottom: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
      },
    },
  },
});

export const CardImage = styled('img', {
  height: undefined,
  width: '100%',
  objectFit: 'cover',
  maxWidth: 64,
  maxHeight: 64,
  borderRadius: '8px',
  aspectRatio: '1/1',
});

export const TableHeaderText = styled(Label, {
  fontFamily: '$font-latin-sans-serif-1',
  color: '$colour-brand-neutral-hue-1-shade-1',
  fontWeight: 600,
});

export const ProductTableColumn = styled('div', {
  display: 'flex',
  variants: {
    largerWidth: {
      true: {
        flexBasis: '74%',
      },
    },
  },
});

export const TemporaryIconTextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export const TextColumn = styled('div', {
  fontFamily: '$font-latin-sans-serif-1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingLeft: '$spacing-fluid-inline-small',
  variants: {
    isPrepaidStock: {
      true: {
        justifyContent: 'start',
        rowGap: '$spacing-fluid-inline-xx-small',
        paddingTop: '$spacing-fluid-inline-small',
      },
    },
  },
});
