import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';

export interface Props {
  children: React.ReactNode;
}

const AuthenticationProvider = ({ children }: Props): React.ReactElement => {
  if (!config.auth0Audience || !config.auth0ClientId || !config.auth0Domain)
    throw new Error(
      'Please set REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_DOMAIN and REACT_APP_AUTH0_CLIENT_ID .env variables'
    );

  const navigate = useNavigate();

  const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] =
    appState => {
      navigate(appState?.returnTo || window.location.href);
    };

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={config.auth0Audience}
      cacheLocation="localstorage"
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthenticationProvider;
