import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { UK_CURRENCY, UK_LOCALE } from '../../../../shared/constants';
import { useOrganisationsContext } from '../../../../shared/providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { BasicClientOrder } from '../../../../shared/types/ClientOrder';
import { formatOrderNumber } from '../../../../shared/utils/formatOrderNumber/formatOrderNumber';
import { formatPrice } from '../../../../shared/utils/formatPrice/formatPrice';
import {
  TableContentRow,
  TableData,
  BlueLink,
  StatusTag,
} from '../page/ViewClientOrdersStyled';

interface TableRowProps {
  clientOrder: BasicClientOrder;
}

const TableRow = (props: TableRowProps) => {
  const { clientOrder } = props;
  const { t } = useTranslation();
  const { currentOrganisation } = useOrganisationsContext();
  const locale = currentOrganisation?.locale || UK_LOCALE;
  const currencyCode = currentOrganisation?.defaultCurrency || UK_CURRENCY;

  const ExpectedDeliveryDate = ({
    date,
  }: {
    date: string | undefined | null;
  }) => {
    const inputDate = dayjs(date);

    if (inputDate.isValid() && date) {
      return (
        <>
          {new Date(date).toLocaleString(UK_LOCALE, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </>
      );
    }
    return (
      <StatusTag grey={true}>
        {t('viewClientOrders.statusLabels.pending')}
      </StatusTag>
    );
  };

  const StatusLabel = ({ status }: { status: string | undefined | null }) => {
    let labelText: string;
    switch (status) {
      case 'Draft':
        labelText = t('viewClientOrders.statusLabels.draft');
        break;
      case 'Submitted':
        labelText = t('viewClientOrders.statusLabels.submitted');
        break;
      case 'In transit':
        labelText = t('viewClientOrders.statusLabels.inTransit');
        break;
      case 'Delivered':
        labelText = t('viewClientOrders.statusLabels.delivered');
        break;
      default:
        labelText = '';
    }
    if (!labelText) {
      return null;
    }
    return (
      <StatusTag
        red={status === 'Draft'}
        blue={status === 'Submitted'}
        yellow={status === 'In transit'}
        grey={status === 'Delivered'}
      >
        {labelText}
      </StatusTag>
    );
  };

  return (
    <TableContentRow
      key={clientOrder.id}
      data-cy={`client-orders-order-${formatOrderNumber(
        clientOrder.externalClientOrderId || clientOrder.id
      )}`}
    >
      <TableData>
        {formatOrderNumber(clientOrder.externalClientOrderId || clientOrder.id)}
      </TableData>
      <TableData>
        <StatusLabel status={clientOrder.status} />
      </TableData>
      <TableData>
        <ExpectedDeliveryDate date={clientOrder.createdAt} />
      </TableData>
      <TableData>
        <ExpectedDeliveryDate date={clientOrder.expectedDeliveryDate} />
      </TableData>
      <TableData>{clientOrder.addressName}</TableData>
      <TableData>
        {t('viewClientOrders.product', {
          count: Number(clientOrder.numberOfProducts || 0),
        })}
      </TableData>
      <TableData>
        {formatPrice(
          locale,
          currencyCode,
          Number(clientOrder.clientOrderSummary?.totalIncludingTaxes || 0)
        )}
      </TableData>
      <TableData>
        <BlueLink
          to={`/orders/${clientOrder.externalClientOrderId || clientOrder.id}`}
        >
          {t('viewClientOrders.view')}
        </BlueLink>
      </TableData>
    </TableContentRow>
  );
};

export default TableRow;
