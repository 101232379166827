import { Product } from '../../types/Product';
import { getHumanReadableUnits } from '../../utils/getHumanReadableUnits/getHumanReadableUnits';

export const getProductStockLevel = (product: Product) => {
  let internalProductStockQuantity = 0;

  if (product.productStocks.length > 0) {
    const productStockWithInternalAddress = product.productStocks.find(
      productStock => productStock.address?.isInternal
    );
    if (productStockWithInternalAddress) {
      internalProductStockQuantity =
        productStockWithInternalAddress.quantity || 0;
    }
  }

  const productStockPacks = Math.floor(
    internalProductStockQuantity / (product.unitQuantity || 1)
  );
  return {
    label: `${productStockPacks} packs (${getHumanReadableUnits(
      internalProductStockQuantity
    )})`,
    numberOfPacks: productStockPacks,
  };
};
