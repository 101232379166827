import axios from 'axios';
import config from '../../../config';

export const useFetcher = <TData, TVariables>(
  query: string
): ((variables?: TVariables) => Promise<TData>) => {
  return async (variables?: TVariables) => {
    return axios
      .post<{ data: TData; errors: unknown }>(
        config.stockManagementGraphqlEndpoint,
        { query, variables }
      )
      .then(res => {
        if (res.data.errors) throw new Error(JSON.stringify(res.data.errors));
        return res.data.data;
      });
  };
};
