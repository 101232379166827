import { isTaxRate } from '../../../modules/products/viewProducts/graphql/mappers';
import {
  SOURCEFUL_PROVIDER_TYPE,
  STANDALONE_ORDER_PROCESSING_TYPE,
  STANDALONE_PRODUCTS_SOURCE,
} from '../../constants';
import { Address } from '../../types/Address';
import { Organisation } from '../../types/Organisation';
import { isBoolean } from '../../utils/isBoolean/isBoolean';
import {
  EnhancedOrganisation as EnhancedOrganisationGraphqlObject,
  Address as AddressGraphqlObject,
} from '../types/generated';

type AddressItem = AddressGraphqlObject | null | undefined;

const isAddress = (item: AddressItem): item is AddressGraphqlObject => {
  return Boolean(item);
};

export const mapAddress = (address: AddressGraphqlObject): Address => {
  const taxRates =
    address?.taxRates && Array.isArray(address.taxRates)
      ? address.taxRates
      : [];

  const filteredTaxRates = taxRates.filter(isTaxRate);
  return { ...address, taxRates: filteredTaxRates };
};

export const mapOrganisationData = (
  organisation: EnhancedOrganisationGraphqlObject
): Organisation => {
  const addresses =
    organisation.addresses && Array.isArray(organisation.addresses)
      ? organisation.addresses.filter(isAddress)
      : [];

  const mappedAddresses = addresses.map(mapAddress);

  return {
    ...organisation,
    config: {
      prepaidStock: isBoolean(organisation.config?.prepaidStock)
        ? Boolean(organisation.config?.prepaidStock)
        : false,
      displayProductUnitCost: isBoolean(
        organisation.config?.displayProductUnitCost
      )
        ? Boolean(organisation.config?.displayProductUnitCost)
        : false,
      enableClientReferenceCollection: isBoolean(
        organisation.config?.enableClientReferenceCollection
      )
        ? Boolean(organisation.config?.enableClientReferenceCollection)
        : false,
      computeDeliveryCostPerProduct: isBoolean(
        organisation.config?.computeDeliveryCostPerProduct
      )
        ? Boolean(organisation.config?.computeDeliveryCostPerProduct)
        : false,
      selfserviceEnabled: isBoolean(organisation.config?.selfserviceEnabled)
        ? Boolean(organisation.config?.selfserviceEnabled)
        : true,
      autostockEnabled: isBoolean(organisation.config?.selfserviceEnabled)
        ? Boolean(organisation.config?.selfserviceEnabled)
        : true,
      deliveryInvoicedSeparately: isBoolean(
        organisation.config?.deliveryInvoicedSeparately
      )
        ? Boolean(organisation.config?.deliveryInvoicedSeparately)
        : true,
      // TODO: think of solution with enums
      mainProviderType:
        organisation.config?.mainProviderType ?? SOURCEFUL_PROVIDER_TYPE,
      orderProcessingType:
        organisation.config?.orderProcessingType ??
        STANDALONE_ORDER_PROCESSING_TYPE,
      paymentDisclaimer:
        typeof organisation.config?.paymentDisclaimer === 'string'
          ? organisation.config?.paymentDisclaimer
          : '',
      productsSourceType:
        organisation.config?.productsSourceType ?? STANDALONE_PRODUCTS_SOURCE,
    },
    addresses: mappedAddresses,
  };
};
