/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Icon,
  Label,
  LabelSize,
  LabelWeight,
  styled,
} from '@sourceful/design-system';

import Dropzone from 'react-dropzone';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FileAttachment } from '../../types/FileAttachment';
import useUpload from './useUpload';

export const FileInput = styled('input', {
  display: 'none',
});

export const DropzoneWrapper = styled('section', {
  border: 'dashed 1px $colour-brand-neutral-hue-1-tint-3',
  borderRadius: '$radii-small',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '80px 1fr',
  gridGap: '10px',
  alignItems: 'center',
  minHeight: '100px',
});

export const IconWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

interface AttachmentsUploaderProps {
  onSuccess: (files: FileAttachment[]) => void;
  onError: (error: Error) => void;
  acceptedFiles?: string;
  id?: string;
}

const FILE_SIZE_LIMIT_IN_BYTES = 202400000;

const AttachmentsUploader = ({
  onSuccess,
  onError,
  acceptedFiles,
}: AttachmentsUploaderProps) => {
  const { t } = useTranslation();

  const { selectedFiles, setSelectedFiles, uploadFiles } = useUpload<File[]>({
    onUploadComplete: (files: any[]) => {
      onSuccess(files);
    },
  });

  const handleSubmit = async () => {
    try {
      await uploadFiles();
    } catch (error: any) {
      console.error("Couldn't upload file", error);
      onError(error);
    }
  };

  useEffect(() => {
    if (selectedFiles) {
      handleSubmit();
    }
  }, [selectedFiles]);

  const handleFileInput = (files: File[]) => {
    if (!files || files.length === 0) {
      return;
    }

    for (const file of files) {
      if (file?.size > FILE_SIZE_LIMIT_IN_BYTES) {
        onError(new Error('File size cannot exceed more than 200MB'));
        return;
      }
    }

    setSelectedFiles(files);
  };

  return (
    <Dropzone onDrop={handleFileInput}>
      {({ getRootProps, getInputProps }) => (
        <DropzoneWrapper {...getRootProps()}>
          <IconWrapper>
            <Icon className="preview-icon" name="icon-save-upload" />
          </IconWrapper>

          <div>
            <Label size={LabelSize.Small} weight={LabelWeight['Semi-Bold']}>
              {t('attachments.tooltipDragAndDropAttachment')}
            </Label>
            <Label size={LabelSize.Small}>
              {t('attachments.tooltipAttachmentMaxSize')}
            </Label>
          </div>

          <FileInput
            {...getInputProps()}
            type="file"
            name="file"
            accept={acceptedFiles}
          />
        </DropzoneWrapper>
      )}
    </Dropzone>
  );
};

export default AttachmentsUploader;
