import { ReactNode } from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { styled, Icon, IconSize } from '@sourceful/design-system';
import { Z_INDEX_SELECT_MENU } from '../../constants/zIndexValues';

const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  padding:
    '$spacing-fluid-inline-xx-small $spacing-fluid-inline-xx-small $spacing-fluid-inline-xx-small $spacing-fluid-inline-x-small',
  fontSize: '$font-size-5',
  backgroundColor: '$colour-lightest',
  color: '$colour-brand-neutral-hue-1-shade-1',
  border: '1px solid $colour-brand-neutral-hue-1-tint-4',
  borderRadius: '$radii-medium',
});

const StyledIcon = styled(SelectPrimitive.SelectIcon, {
  color: '$colour-brand-neutral-hue-1-shade-1',
  borderLeft: '1px solid $colour-brand-neutral-hue-1-tint-4',
  paddingLeft: '$spacing-fluid-inline-xx-small',
  marginLeft: '$spacing-fluid-inline-small',
});

const StyledContent = styled(SelectPrimitive.Content, {
  overflow: 'hidden',
  backgroundColor: '$colour-lightest',
  zIndex: Z_INDEX_SELECT_MENU,
  borderRadius: '$radii-medium',
  border: '1px solid $colour-brand-neutral-hue-1-tint-4',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const StyledViewport = styled(SelectPrimitive.Viewport, {
  padding: '$spacing-fluid-inline-xx-small',
});

const StyledPortal = styled(SelectPrimitive.Portal, {
  padding: '$spacing-fluid-inline-xx-small',
});

function Content({ children, ...props }: { children: ReactNode }) {
  return (
    <StyledPortal>
      <StyledContent {...props}>{children}</StyledContent>
    </StyledPortal>
  );
}

const StyledItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  fontSize: '$font-size-5',
  lineHeight: 1,
  color: '$colour-brand-neutral-hue-1-shade-1',
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',
  '&:hover': {
    backgroundColor: '$colour-brand-neutral-hue-1-tint-6',
    cursor: 'pointer',
  },
});

const StyledLabel = styled(SelectPrimitive.Label, {
  padding: '0 25px',
  fontSize: 12,
  lineHeight: '25px',
});

const StyledSeparator = styled(SelectPrimitive.Separator, {
  height: 1,
  margin: 5,
});

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  right: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: '$spacing-fluid-inline-xx-small',
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: '#005ae1',
  cursor: 'default',
};

const StyledScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  scrollButtonStyles
);

const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles
);

// Exports
export const Select = SelectPrimitive.Root;
export const SelectTrigger = StyledTrigger;
export const SelectValue = SelectPrimitive.Value;
export const SelectIcon = StyledIcon;
export const SelectContent = Content;
export const SelectViewport = StyledViewport;
export const SelectGroup = SelectPrimitive.Group;
export const SelectItem = StyledItem;
export const SelectItemText = SelectPrimitive.ItemText;
export const SelectItemIndicator = StyledItemIndicator;
export const SelectLabel = StyledLabel;
export const SelectSeparator = StyledSeparator;
export const SelectScrollUpButton = StyledScrollUpButton;
export const SelectScrollDownButton = StyledScrollDownButton;

const Box = styled('div', {});

export interface Props {
  value?: string;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  placeholder?: string;
  dataCy?: string;
}

export const TemporarySelect: React.FC<Props> = ({
  value,
  onChange,
  options,
  placeholder,
  dataCy,
}) => (
  <Box>
    <Select
      onValueChange={value => {
        onChange(value);
      }}
      value={value}
    >
      <SelectTrigger aria-label={placeholder} data-cy={dataCy}>
        <SelectValue placeholder={placeholder} />
        <SelectIcon>
          <Icon size={IconSize.Small} name="icon-arrow-chevron-down-sml" />
        </SelectIcon>
      </SelectTrigger>
      <SelectContent>
        <SelectScrollUpButton>
          <Icon name="icon-arrow-chevron-up-sml" />
        </SelectScrollUpButton>
        <SelectViewport>
          {options.map((option, key) => (
            <SelectItem key={key} value={option.value}>
              <SelectItemText>{option.label}</SelectItemText>
              <SelectItemIndicator>
                <Icon
                  size={IconSize.Small}
                  name="icon-toggle-checkmark-check"
                />
              </SelectItemIndicator>
            </SelectItem>
          ))}
        </SelectViewport>
        <SelectScrollDownButton>
          <Icon name="icon-arrow-chevron-down-sml" />
        </SelectScrollDownButton>
      </SelectContent>
    </Select>
  </Box>
);

export default TemporarySelect;
