import { styled } from '@sourceful/design-system';
import { Z_INDEX_EDIT_ICONS } from '../../../constants/zIndexValues';

export const StyledInput = styled('input', {
  fontFamily: '$font-latin-sans-serif-1',
  width: 100,
  height: 32,
  borderRadius: '$radii-small',
  border: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
  backgroundColor: '$colour-lightest !important',
  '&:active': {
    outline: 'none',
    border: 'solid 1px $colour-brand-blue-hue-1-tint-2',
  },
  '&:focus': {
    outline: 'none',
    border: 'solid 1px $colour-brand-blue-hue-1-tint-2',
    boxShadow: '0 0 0 3px hsla(216, 90%, 79%, 1)',
  },
  '&:hover': {
    border: '1px solid $colour-brand-neutral-hue-1-base',
  },
  variants: {
    error: {
      true: {
        border: '1px solid $colour-brand-red-hue-1-base !important',
        backgroundColor: '$colour-brand-red-hue-1-tint-6 !important',
        outline: 'none',
        boxShadow: '0 0 0 0 !important',
      },
    },
  },
});

export const CardImage = styled('img', {
  height: undefined,
  width: '100%',
  objectFit: 'cover',
  maxWidth: 64,
  maxHeight: 64,
  borderRadius: '8px',
  aspectRatio: '1/1',
});

export const ActionsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  zIndex: Z_INDEX_EDIT_ICONS,
});
