import {
  Product as ProductGraphqlType,
  useGetCurrentOrganisationProductsQuery,
} from '../../../../../../shared/graphql/types/generated';
import { useUserContext } from '../../../../../../shared/providers/UserProvider/UserProvider';
import { Product } from '../../../../../../shared/types/Product';
import { mapProduct } from '../../mappers';

interface HookResult {
  productsLoading: boolean;
  productsError?: unknown;
  products: Product[];
}

const useCurrentOrganisationProducts = (): HookResult => {
  const { currentOrganisationId } = useUserContext();
  const { isLoading, error, data } = useGetCurrentOrganisationProductsQuery({
    currentOrganisationId: currentOrganisationId as never, // this is to retry the http request when currentOrganisationId changes
  });

  const products =
    data?.currentOrganisationProducts &&
    Array.isArray(data?.currentOrganisationProducts)
      ? data?.currentOrganisationProducts
      : [];

  const filteredProducts = products?.filter(Boolean) as ProductGraphqlType[];

  return {
    productsLoading: isLoading,
    productsError: error,
    products: filteredProducts.map(mapProduct),
  };
};

export default useCurrentOrganisationProducts;
