import { styled } from '@sourceful/design-system';

export const LinkText = styled('div', {
  color: '#58585A',
  '&:hover': {
    color: '#58585A',
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
});

export const Text = styled('div', {
  color: '#58585A',
  marginLeft: '10px',
});

export const WarningButtonColourOverride = {
  active: {
    color: '$colour-brand-red-hue-1-base', // colour.ui.light.container.neutral.no-contrast.text.red.lo
    backgroundColor: '$colour-brand-neutral-hue-1-tint-6 !important', // colour.ui.light.container.neutral.no-contrast.colour
  },
  default: {
    color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
    backgroundColor: '$colour-brand-neutral-hue-1-tint-6', // colour.ui.light.container.neutral.no-contrast.colour
  },
  hover: {
    color: '$colour-brand-red-hue-1-base', // colour.ui.light.container.neutral.no-contrast.text.red.lo
    backgroundColor: '$colour-brand-neutral-hue-1-tint-6 !important', // colour.ui.light.container.neutral.no-contrast.colour
  },
  visited: {
    color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
    backgroundColor: '$colour-brand-neutral-hue-1-tint-6', // colour.ui.light.container.neutral.no-contrast.colour
  },
};

export const InfoButtonColourOverride = {
  active: {
    color: '$colour-brand-blue-hue-1-base', // colour.ui.light.container.neutral.no-contrast.text.blue.lo
    backgroundColor: '$colour-brand-neutral-hue-1-tint-6 !important', // colour.ui.light.container.neutral.no-contrast.colour
  },
  default: {
    color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
    backgroundColor: '$colour-brand-neutral-hue-1-tint-6', // colour.ui.light.container.neutral.no-contrast.colour
  },
  hover: {
    color: '$colour-brand-blue-hue-1-base', // colour.ui.light.container.neutral.no-contrast.text.blue.lo
    backgroundColor: '$colour-brand-neutral-hue-1-tint-6 !important', // colour.ui.light.container.neutral.no-contrast.colour
  },
  visited: {
    color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
    backgroundColor: '$colour-brand-neutral-hue-1-tint-6', // colour.ui.light.container.neutral.no-contrast.colour
  },
};

export const Divider = styled('div', {
  height: '1px',
  width: '100%',
  backgroundColor: '$colour-brand-neutral-hue-1-tint-5',
  marginTop: '20px',
  marginBottom: '20px',
  variants: {
    margin: {
      noMargin: {
        marginTop: '0px',
        marginBottom: '0px',
      },
      smallMargin: {
        marginTop: '5px',
        marginBottom: '5px',
      },
    },
  },
});

export const ProductCardDivider = styled('div', {
  backgroundColor: '$colour-brand-neutral-hue-1-tint-5',
  height: 1,
  width: '100%',
  marginTop: '$spacing-fluid-block-x-small',
  marginBottom: '$spacing-fluid-block-x-small',
});

export const OrdersWrapper = styled('div', {
  backgroundColor: '$colour-lightest',
  marginTop: '$spacing-fluid-block-medium',
  marginBottom: '$spacing-fluid-block-small',
});
