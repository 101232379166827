import { styled } from '@sourceful/design-system';

export const OrderDetailsCardWrapper = styled('div', {
  backgroundColor: '$colour-lightest ',
  marginBottom: '$spacing-fluid-block-medium',
  padding: '$spacing-fluid-block-small',
  border: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
  borderRadius: '$radii-small',
});

export const OrderTitle = styled('div', {
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
});

export const ProductsWrapper = styled('div', {
  variants: {
    noBottomBorder: {
      true: {
        border: '1px 1px 0 1px',
      },
    },
    noRadius: {
      true: {
        borderRadius: 0,
      },
    },
    noTopRadius: {
      true: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    noBottomRadius: {
      true: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
});

export const DeliveryTimeline = styled('div', {
  display: 'flex',
  fontFamily: '$font-latin-sans-serif-1',
  color: '$colour-brand-neutral-hue-1-shade-1',
  margin: '$spacing-fluid-block-medium 0 $spacing-fluid-block-small 0',
  padding: '$spacing-fluid-block-small 0 0 0',
});
