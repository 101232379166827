import { styled } from '@sourceful/design-system';

export const OrderInfoWrapper = styled('div', {
  gridColumn: '1 / span 6',
  '@bp-sm': {
    gridColumn: '1 / span 8',
  },
});

export const SummaryWrapper = styled('div', {
  gridColumn: '1 / span 6',
  marginTop: '$spacing-fluid-block-large',
  '@bp-sm': {
    gridColumn: '9 / span 4',
    marginTop: 0,
  },
});

export const ButtonWrapper = styled('div', {
  gridColumn: '1 / span 6',
  '@bp-sm': {
    gridColumn: '1 / span 4',
  },
});
