import { ReactNode } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { styled } from '@sourceful/design-system';
import { Z_INDEX_BASKET_DROPDOWN } from '../../../constants/zIndexValues';

const contentStyles = {
  minWidth: 330,
  width: '100%',
  maxWidth: 352,
  backgroundColor: 'white',
  borderRadius: 6,
  padding: 16,
  boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.16)',
};

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  ...contentStyles,
  marginRight: 30,
  zIndex: Z_INDEX_BASKET_DROPDOWN,
});

const StyledArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: 'white !important',
});

export const DropdownMenuActions = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const SummaryWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: 16,
  marginBottom: 16,
});

export const BasketTotalWrapper = styled('div', {
  marginTop: 16,
  marginBottom: 16,
});

export const NumberOfExcessProductsInBasketWrapper = styled('div', {
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  padding: 0,
});

function Content({
  children,
  ...props
}: {
  children: ReactNode;
  sideOffset?: number;
}) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledContent {...props}>
        {children}
        <StyledArrow />
      </StyledContent>
    </DropdownMenuPrimitive.Portal>
  );
}

const StyledSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: '#D0D0D2',
});

// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = Content;
export const DropdownMenuSeparator = StyledSeparator;

// Your app...
export const Box = styled('div', {});

export const DropdownMenuDemo = () => {
  return (
    <Box>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>Trigger</DropdownMenuTrigger>

        <DropdownMenuContent sideOffset={5}>
          3 products in the basket
          <DropdownMenuSeparator />
        </DropdownMenuContent>
      </DropdownMenu>
    </Box>
  );
};

export const ButtonWrapper = styled('div', {
  //  this div is required for the tooltip to work over the button,
  //   and the background color needs to be set to white, otherwise
  //   there's a grey border when you zoom in
  backgroundColor: '$colour-lightest',
});

export const BasketLinesWrapper = styled('div', {
  marginBlockStart: '$spacing-fluid-block-small',
  marginBlockEnd: '$spacing-fluid-block-x-small',
});

export const PaymentDisclaimer = styled('div', {
  background: '$colour-brand-neutral-hue-1-tint-6',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '$radii-small',
  columnGap: 6,
  marginTop: '$spacing-fluid-block-small',
  padding: '4px 6px',
});

export const DeletedBasketLinesWrapper = styled('div', {
  marginBottom: '$spacing-fluid-block-x-small',
});

export const DeletedProductCardWrapper = styled('div', {
  marginTop: '$spacing-fluid-block-x-small',
});

export const SummaryDataWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '$spacing-fluid-block-x-small',
});

export const Separator = styled('div', {
  flexGrow: 1,
  background: '$colour-brand-neutral-hue-1-tint-5',
  height: 1,
});

export const BasketProductCounter = styled('span', {
  display: 'inline-block',
  position: 'absolute',
  verticalAlign: 'top',
  marginTop: '-5px',
  marginLeft: '-18px',
  width: '30px',
  height: '30px',
  padding: '5px',
  border: '3px solid white',
  borderRadius: '50%',
  backgroundColor: '$colour-brand-blue-hue-1-base',
  fontSize: '10px',
  fontWeight: '600',
  textAlign: 'center',
  color: 'white',
});

export default DropdownMenuDemo;
