import { ProductPrice } from '../../types/ProductPrice';

export interface IsProductWithMoqPricingProps {
  productPrices?: ProductPrice[];
}

export const isProductWithMoqPricing = ({
  productPrices,
}: IsProductWithMoqPricingProps): boolean => {
  if (productPrices) {
    return !!productPrices.some(
      productPrice =>
        productPrice.minOrderQuantity && productPrice.minOrderQuantity > 1
    );
  } else return false;
};
