import {
  Title,
  TitleSize,
  TitleLineHeight,
  Icon,
  IconSize,
  Tooltip,
  Button,
  ButtonVariant,
  ButtonRadii,
  ButtonSize,
  Label,
  LabelSize,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import {
  BasketOrder,
  EditProductQuantityOptions,
} from '../../providers/BasketProvider/useBasket';
import InvoiceDetails from '../InvoiceDetails/InvoiceDetails';
import { DeliveryInfo } from '../Orders/DeliveryInfo/DeliveryInfo';
import { OrderLinesTable } from '../Orders/OrderLinesTable/OrderLinesTable';
import {
  OrderDetailsCardWrapper,
  OrderTitle,
  ProductsWrapper,
  DeliveryTimeline,
} from './OrderDetailsCardStyled';
import { FileAttachment } from '../../types/FileAttachment';

interface OrderDetailsCardProps {
  isEditing: boolean;
  order: BasketOrder;
  deliveredAt?: string;
  contractualExpectedDeliveryDate?: Date;
  onProductQuantityChange?: (props: EditProductQuantityOptions) => void;
  onClientReferenceChange?: (
    clientReference: string,
    addressId: string
  ) => void;
  onAttachmentAdd?: (attachments: FileAttachment[], addressId: string) => void;
  onAttachmentRemove?: (attachmentUuid: string) => Promise<void> | void;
  onOrderRemove?: (addressId: string) => void;
  onProductDelete?: (productId: string, addressId: string) => void;
  onLineEdit?: (productId: string) => void;
}

export const OrderDetailsCard = ({
  isEditing,
  order,
  deliveredAt,
  contractualExpectedDeliveryDate,
  onProductQuantityChange,
  onClientReferenceChange,
  onAttachmentAdd,
  onAttachmentRemove,
  onOrderRemove,
  onProductDelete,
  onLineEdit,
}: OrderDetailsCardProps) => {
  const { t } = useTranslation();

  const handleDeleteOrder = (addressId: string) => {
    onOrderRemove && onOrderRemove(addressId);
  };

  const handleProductDelete = (productId: string, addressId: string) => {
    onProductDelete && onProductDelete(productId, addressId);
  };

  const handleRemove = (
    addressId: string | null | undefined,
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (handleDeleteOrder && addressId) {
      handleDeleteOrder(addressId);
    }
  };

  const handleClientReferenceChange = (clientReference: string) => {
    onClientReferenceChange &&
      onClientReferenceChange(clientReference, order.address.id as string);
  };

  const handleAttachmentAdd = (attachments: FileAttachment[]) => {
    onAttachmentAdd && onAttachmentAdd(attachments, order.address.id as string);
  };

  const handleAttachmentRemove = (attachmentUuid: string) => {
    onAttachmentRemove && onAttachmentRemove(attachmentUuid);
  };

  return (
    <>
      <OrderDetailsCardWrapper className="order-detail-card">
        <OrderTitle>
          <Title
            size={TitleSize.Small}
            lineHeight={TitleLineHeight.Normal}
            css={{
              color: '#58585A',
              marginBottom: '$spacing-fluid-block-medium',
            }}
          >
            {t('orderDetails.title', {
              addressName: order.address.name,
            })}
          </Title>
          {isEditing && (
            <Tooltip
              trigger={
                <Button
                  variant={ButtonVariant.Secondary}
                  size={ButtonSize['XX-Small']}
                  radii={ButtonRadii.Small}
                  onClick={event => handleRemove(order.address.id, event)}
                  css={{
                    marginRight: '0 !important',
                    paddingInlineStart: '$spacing-static-inline-x-small',
                    paddingInlineEnd: '$spacing-static-inline-x-small',
                  }}
                  colourOverride={{
                    active: {
                      color: '$colour-brand-red-hue-1-base', // colour.ui.light.container.neutral.no-contrast.text.red.lo
                      backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
                    },
                    default: {
                      color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                      backgroundColor: '$colour-lightest', // colour.ui.light.container.neutral.no-contrast.colour
                    },
                    hover: {
                      color: '$colour-brand-red-hue-1-base', // colour.ui.light.container.neutral.no-contrast.text.red.lo
                      backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
                    },
                    visited: {
                      color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
                      backgroundColor: '$colour-lightest', // colour.ui.light.container.neutral.no-contrast.colour
                    },
                  }}
                >
                  <Icon
                    size={IconSize['Static XXX-Small']}
                    name="icon-navigation-trash"
                  />
                </Button>
              }
              contentText={t('viewBasket.tooltipDeleteWholeLocationOrder')}
            />
          )}
        </OrderTitle>
        <ProductsWrapper>
          <OrderLinesTable
            isEditing={isEditing}
            order={order}
            handleOrderLineEdit={onLineEdit}
            handleNumberPackChange={onProductQuantityChange}
            handleProductRemovedFromAddress={handleProductDelete}
          />
        </ProductsWrapper>
        {!isEditing && (
          <DeliveryTimeline>
            <Label
              css={{ flex: 'initial', width: '200px' }}
              size={LabelSize.Small}
            >
              {t('orderDetails.deliveryTimeline')}
            </Label>

            <Label size={LabelSize.Small}>
              <DeliveryInfo
                postcode={order.address.postcode || ''}
                contractualExpectedDeliveryDate={
                  contractualExpectedDeliveryDate !== undefined
                    ? contractualExpectedDeliveryDate
                    : null
                }
                deliveredAt={deliveredAt !== undefined ? deliveredAt : null}
              />
            </Label>
          </DeliveryTimeline>
        )}

        <InvoiceDetails
          isEditing={isEditing}
          orderCreationDate={order.orderSummary?.createdAt}
          addressId={order.address.id as string}
          attachments={order.attachments || []}
          clientReference={order.clientReference || ''}
          onClientReferenceChange={handleClientReferenceChange}
          onAttachmentRemove={handleAttachmentRemove}
          onAttachmentAdd={handleAttachmentAdd}
        />
      </OrderDetailsCardWrapper>
    </>
  );
};
