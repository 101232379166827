import {
  Button,
  ButtonRadii,
  ButtonSize,
  ButtonVariant,
  Icon,
  IconSize,
  Label,
  LabelSize,
  LabelWeight,
  styled,
} from '@sourceful/design-system';

interface AttachmentProps {
  originalFilename: string;
  attachmentUuid: string;
  onDownload: (
    attachmentUuid: string,
    originalFilename: string
  ) => Promise<void> | void;
  isEditing?: boolean;
  onAttachmentRemove: (attachmentUuid: string) => Promise<void> | void;
}

export const MainBar = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

export const Filename = styled('div', {
  maxWidth: '80%',
});

export const DisplayAttachment = styled('div', {
  display: 'grid',
  gridGap: '10px',
  gridTemplateColumns: '1fr',
  borderRadius: '$radii-small',
  border: '1px dashed $colour-brand-neutral-hue-1-tint-3',

  alignItems: 'center',
  marginTop: '$spacing-static-block-x-small',
  marginBottom: '$spacing-static-block-x-small',
  width: '100%',
  paddingBlock: '$spacing-static-block-x-small',
  paddingInline: '$spacing-static-block-small',
});

export const Link = styled('a', {
  fontSize: 14,
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const AttachmentDownloader = ({
  originalFilename,
  attachmentUuid,
  onDownload,
  isEditing = false,
  onAttachmentRemove,
}: AttachmentProps) => {
  const handleDownload = () => {
    onDownload(attachmentUuid, originalFilename);
  };

  const handleDelete = () => {
    onAttachmentRemove(attachmentUuid);
  };

  return isEditing ? (
    <DisplayAttachment key={attachmentUuid}>
      <MainBar>
        <Filename>
          <Link onClick={handleDownload}>
            <Label size={LabelSize.Small}>{originalFilename}</Label>
          </Link>
        </Filename>

        <Button
          variant={ButtonVariant.Secondary}
          size={ButtonSize['XX-Small']}
          radii={ButtonRadii.Medium}
          onClick={handleDelete}
          css={{
            marginLeft: '$spacing-static-inline-medium',
            paddingInlineStart: '$spacing-static-inline-x-small',
            paddingInlineEnd: '$spacing-static-inline-x-small',
          }}
          colourOverride={{
            active: {
              color: '$colour-brand-red-hue-1-base', // colour.ui.light.container.neutral.no-contrast.text.red.lo
              backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
            },
            default: {
              color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
              backgroundColor: '$colour-lightest', // colour.ui.light.container.neutral.no-contrast.colour
            },
            hover: {
              color: '$colour-brand-red-hue-1-base', // colour.ui.light.container.neutral.no-contrast.text.red.lo
              backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
            },
            visited: {
              color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
              backgroundColor: '$colour-lightest', // colour.ui.light.container.neutral.no-contrast.colour
            },
          }}
        >
          <Icon
            size={IconSize['Static XXX-Small']}
            name="icon-navigation-trash"
          />
        </Button>
      </MainBar>
    </DisplayAttachment>
  ) : (
    <Filename
      key={attachmentUuid}
      css={{ paddingBottom: '$spacing-static-block-x-small' }}
    >
      <Link
        onClick={handleDownload}
        css={{
          display: 'flex',
          flexDirection: 'row',
          color: '$colour-brand-neutral-hue-1-shade-1',
        }}
      >
        <Icon
          size={IconSize['Static XXX-Small']}
          name="icon-save-download"
          css={{ marginInlineEnd: '$spacing-static-inline-x-small' }}
        />
        <Label size={LabelSize.Small} weight={LabelWeight['Semi-Bold']}>
          {originalFilename}
        </Label>
      </Link>
    </Filename>
  );
};

export default AttachmentDownloader;
