/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useEffect } from 'react';
import { GridContainer, Grid } from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AlertBanner } from '../../../../shared/components/AlertBanner/AlertBanner';
import {
  OrderInfoWrapper,
  SummaryWrapper,
} from '../../../../shared/components/Grid/ViewOrderGrid';
import { TitleWrapper } from '../../../../shared/components/Nav/NavStyled';
import { NavigationBanner } from '../../../../shared/components/NavigationBanner/NavigationBanner';
import { OrderDetailsCard } from '../../../../shared/components/OrderDetailsCard/OrderDetailsCard';
import { OrderSummary } from '../../../../shared/components/OrderSummary/OrderSummary';
import { useOrganisationName } from '../../../../shared/hooks/useOrganisationName/useOrganisationName';
import { useUserContext } from '../../../../shared/providers/UserProvider/UserProvider';
import { Divider } from '../../../../shared/styles/SharedStyles';
import { formatOrderNumber } from '../../../../shared/utils/formatOrderNumber/formatOrderNumber';
import { getNumberOrZero } from '../../../../shared/utils/getNumberOrZero/getNumberOrZero';
import { getStringValue } from '../../../../shared/utils/getStringValue/getStringValue';
import { OrderSummaryBanner } from '../components/OrderSummaryBanner';
import { ViewClientOrderSkeleton } from '../components/ViewClientOrderSkeleton';
import useCurrentOrganisationClientOrder from '../graphql/hooks/useCurrentOrganisationProducts/useCurrentOrganisationClientOrder';
import { mapClientOrderToBasketStructure } from '../mappers';

export const ViewClientOrder = () => {
  const params = useParams();
  const { t } = useTranslation();

  const organisationName = useOrganisationName();
  const { isSwitchingOrgs } = useUserContext();

  useEffect(() => {
    document.title = `Order #${formatOrderNumber(
      params.clientOrderId,
      true
    )} for ${organisationName} via Stock management by Sourceful`;
    return function cleanup() {
      document.title = 'Stock management by Sourceful';
    };
  }, [organisationName]);

  const { clientOrder, clientOrderOrderLoading, clientOrderOrderError } =
    useCurrentOrganisationClientOrder(getStringValue(params.clientOrderId));

  const mappedClientOrder = clientOrder
    ? mapClientOrderToBasketStructure(clientOrder)
    : null;
  const isLoading = clientOrderOrderLoading || isSwitchingOrgs;

  return (
    <div style={{ marginBottom: 24 }}>
      <TitleWrapper>
        <GridContainer>
          <Grid columns={{ '@initial': 1 }}>
            <NavigationBanner
              currentPageName={`Order  ${formatOrderNumber(
                params.clientOrderId
              )}`}
              previousPageName={'All Orders'}
              previousPageLink={'/orders'}
            ></NavigationBanner>
          </Grid>
        </GridContainer>
      </TitleWrapper>

      {isLoading && <ViewClientOrderSkeleton />}

      {!isLoading && (
        <>
          {!clientOrderOrderError && (
            <>
              <GridContainer>
                <Grid columns={{ '@initial': 1 }}>
                  <OrderSummaryBanner
                    createdAt={getStringValue(clientOrder?.createdAt)}
                    clientOrderId={params.clientOrderId}
                    statusName={getStringValue(clientOrder?.status?.name)}
                  ></OrderSummaryBanner>
                </Grid>
              </GridContainer>

              <GridContainer>
                <Grid columns={{ '@initial': 1 }}>
                  <Divider />
                </Grid>
              </GridContainer>
            </>
          )}
          {(clientOrderOrderError || !clientOrder) && (
            <GridContainer>
              <Grid columns={{ '@initial': 1 }}>
                <AlertBanner
                  firstLineMessage="viewClientOrders.loadDataError"
                  variant="error"
                />
              </Grid>
            </GridContainer>
          )}
          {clientOrder && !clientOrderOrderError && (
            <GridContainer>
              <Grid gapY={'large'} columns={{ '@initial': 6, '@bp-sm': 12 }}>
                <OrderInfoWrapper>
                  {mappedClientOrder && (
                    <OrderDetailsCard
                      order={mappedClientOrder}
                      isEditing={false}
                      deliveredAt={
                        clientOrder?.deliveredAt
                          ? clientOrder?.deliveredAt
                          : undefined
                      }
                      contractualExpectedDeliveryDate={
                        clientOrder?.deliveryInfo?.deliveryTimeline
                          .contractualExpectedDeliveryDate
                          ? clientOrder?.deliveryInfo?.deliveryTimeline
                              .contractualExpectedDeliveryDate
                          : undefined
                      }
                    />
                  )}
                </OrderInfoWrapper>

                <SummaryWrapper>
                  {mappedClientOrder && (
                    <OrderSummary
                      size="large"
                      productsSubTotal={getNumberOrZero(
                        mappedClientOrder.orderSummary?.productsSubtotal
                      )}
                      deliveryFee={getNumberOrZero(
                        mappedClientOrder.orderSummary
                          ?.deliveryValueExcludingVAT
                      )}
                      orderVAT={getNumberOrZero(
                        mappedClientOrder.orderSummary?.vatValue
                      )}
                      VATPercentage={getNumberOrZero(
                        mappedClientOrder.orderSummary?.vatPercentage
                      )}
                      tariffPercentage={
                        mappedClientOrder.orderSummary?.tariffPercentage
                      }
                      orderTariff={mappedClientOrder.orderSummary?.tariffValue}
                      productsAndDeliverySubtotal={getNumberOrZero(
                        mappedClientOrder.orderSummary?.orderSubtotal
                      )}
                      basketTotalIncludingTaxes={getNumberOrZero(
                        mappedClientOrder.orderSummary?.totalIncludingTaxes
                      )}
                      deliveryLabel={t('orderDetails.delivery')}
                      subtotalLabel={t('orderDetails.orderSubTotal')}
                      totalIncludingTaxesLabel={
                        mappedClientOrder.orderSummary?.tariffPercentage
                          ? t('orderDetails.orderTotalInclTaxes')
                          : t('orderDetails.orderTotalInclVAT')
                      }
                      currencyCode={getStringValue(
                        mappedClientOrder.orderSummary?.currencyCode
                      )}
                    />
                  )}
                </SummaryWrapper>
              </Grid>
            </GridContainer>
          )}
        </>
      )}
    </div>
  );
};
