import { RectShape, TextBlock } from 'react-placeholder/lib/placeholders';
import {
  ProductTable,
  ProductTableRow,
  ProductTableColumn,
} from '../AddProductToBasketModal/AddProductToBasketModal.styled';

const ProductCardSkeleton = () => {
  return (
    <ProductTable className="show-loading-animation">
      {/** adding class to show the loading animation https://github.com/buildo/react-placeholder/issues/93 */}

      <ProductTableRow
        default
        firstRow="true"
        lastRow="true"
        compact="true"
        css={{ marginBottom: '$spacing-fluid-block-small' }}
      >
        <ProductTableColumn>
          <RectShape
            color="lightGray"
            style={{
              width: 64,
              height: 64,
            }}
          />
        </ProductTableColumn>
        <ProductTableColumn>
          <TextBlock rows={3} color="lightGray" style={{ minWidth: 300 }} />
        </ProductTableColumn>
      </ProductTableRow>
    </ProductTable>
  );
};

export default ProductCardSkeleton;
