import { useCallback } from 'react';
import {
  Button,
  ButtonSize,
  ButtonRadii,
  ButtonVariant,
  Icon,
  IconSize,
  Tooltip,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import config from '../../../config';
import { BREAKPOINT_M } from '../../constants/styles';
import {
  basketStore,
  BASKET_KEY_NAME,
} from '../../providers/BasketProvider/BasketProvider';
import { useUserContext } from '../../providers/UserProvider/UserProvider';

export const NavLogout = () => {
  const { t } = useTranslation();
  const { logout } = useUserContext();

  const handleLogout = useCallback(() => {
    basketStore.removeItem(BASKET_KEY_NAME);
    logout({ returnTo: config.auth0LogoutUrl });
  }, [basketStore]);
  return (
    <Tooltip
      trigger={
        <Button
          data-cy="logout-button"
          radii={ButtonRadii['X-Small']}
          size={ButtonSize.Medium}
          variant={ButtonVariant.Tertiary}
          css={{
            marginLeft: '$spacing-static-inline-small',
            paddingInlineStart: '$spacing-static-inline-x-small',
            paddingInlineEnd: '$spacing-static-inline-x-small',
            [BREAKPOINT_M]: {
              marginLeft: '9px',
            },
          }}
          colourOverride={{
            active: {
              color: '$colour-brand-blue-hue-1-base !important', // colour.ui.light.container.neutral.no-contrast.text.blue.lo
              backgroundColor: '$colour-lightest !important', // colour.ui.light.container.neutral.no-contrast.colour
            },
            default: {
              color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
            },
            hover: {
              color: '$colour-brand-neutral-hue-1-shade-2', // colour.ui.light.container.neutral.xx-lo.text.neutral.hi
            },
            visited: {
              color: '$colour-brand-neutral-hue-1-shade-1', // colour.ui.light.container.neutral.no-contrast.text.neutral.mid
            },
          }}
          onClick={handleLogout}
        >
          <Icon size={IconSize['X-Small']} name="icon-navigation-exit" />
        </Button>
      }
      contentText={t('navigation.logout')}
    />
  );
};

export default NavLogout;
