import { ReactElement } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

export interface Props {
  children: ReactElement;
}

const QueryProvider = ({ children }: Props) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        notifyOnChangeProps: ['data', 'isLoading', 'error'],
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryProvider;
