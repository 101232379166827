/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import {
  Label,
  LabelLineHeight,
  LabelSize,
  LabelWeight,
  Icon,
  IconSize,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { DoubleDisplay } from '../../../../../shared/components/DoubleDisplay/DoubleDisplay';
import { FormControl } from '../../../../../shared/components/Form/FormControl/FormControl';
import { StyledInput } from '../../../../../shared/components/Orders/OrderProductRow/OrderProductRowStyled';
import { getProductStockLevel } from '../../../../../shared/hooks/getProductStockLevel/GetProductStockLevel';
import { Address } from '../../../../../shared/types/Address';
import { Product } from '../../../../../shared/types/Product';
import { getNumberWithCommas } from '../../../../../shared/utils/getNumberWithCommas/getNumberWithCommas';
import { getProductSubTotal } from '../../utils';
import {
  getAddressText,
  getOverflowQuantity,
} from '../AddProductToBasketForm/utils';
import {
  ProductTableRow,
  ProductTableColumn,
  TextColumn,
} from '../AddProductToBasketModal/AddProductToBasketModal.styled';
import { useBasketContext } from '../../../../../shared/providers/BasketProvider/BasketProvider';
import { PRODUCT_ID_QUERY_PARAM_NAME } from '../../../../../shared/constants';
import { useSearchParams } from 'react-router-dom';

export interface Props {
  index: number;
  amountOfLocations: number;
  address: Address;
  hasOverflow?: boolean;
  product: Product;
  numberOfPacks: number;
  numberOfPacksForCurrentLocation: number;
  availablePacks?: number;
  amountOfUnits: number;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  inputQuantityValue: number;
  locale: string;
  currencyCode: string;
  productHasMoqPricing?: boolean;
  isUnderMoq?: boolean;
  minOrderQuantity?: number;
}

const AddProductToBasketProductRow = ({
  index,
  amountOfLocations,
  address,
  hasOverflow,
  product,
  numberOfPacks,
  numberOfPacksForCurrentLocation,
  availablePacks,
  amountOfUnits,
  setFieldValue,
  inputQuantityValue,
  locale,
  currencyCode,
  productHasMoqPricing = false,
  isUnderMoq = false,
  minOrderQuantity,
}: Props) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [hover, setHover] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isProductInBasket } = useBasketContext();
  const { [PRODUCT_ID_QUERY_PARAM_NAME]: productIdFromQueryParams } =
    Object.fromEntries(Array.from(searchParams));

  const productId = productIdFromQueryParams;
  const productIsInBasket = isProductInBasket(productId);

  const taxInfoText =
    currencyCode === 'USD'
      ? t('orderDetails.exclTaxes')
      : t('orderDetails.exclVAT');

  useEffect(() => {
    if (productHasMoqPricing && !productIsInBasket) {
      setFieldValue(`addressesValues.${index}.quantity`, minOrderQuantity);
    }

    setIsLoading(false);
  }, [productHasMoqPricing, productIsInBasket]);

  return (
    <ProductTableRow
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={index}
      default
      firstRow={index === 0}
      lastRow={index === amountOfLocations - 1}
      css={{
        justifyContent: 'space-around',
        margin: 0,
        paddingLeft: '$spacing-fluid-block-x-small',
        backgroundColor: isUnderMoq ? '$colour-brand-neutral-hue-1-tint-6' : '',
      }}
    >
      <ProductTableColumn style={{ flex: '0 0 50%' }}>
        <TextColumn style={{ paddingLeft: 0 }}>
          <Label
            size={LabelSize.Medium}
            lineHeight={LabelLineHeight.Normal}
            weight={LabelWeight['Semi-Bold']}
            css={{
              color: '$colour-brand-neutral-hue-1-shade-1',
              marginBottom: '$spacing-fluid-block-xx-small',
            }}
          >
            {address?.name}
          </Label>
          <Label
            size={LabelSize.Small}
            css={{
              color: '$colour-brand-neutral-hue-1-shade-1',
            }}
          >
            {getAddressText(address)}
          </Label>
        </TextColumn>
      </ProductTableColumn>
      <ProductTableColumn
        style={{
          flex: '0 0 30%',
          alignItems: 'center',
        }}
      >
        <FormControl
          footer={
            <Label
              as="label"
              size={LabelSize.Small}
              css={
                hasOverflow || (isUnderMoq && !isLoading)
                  ? {
                      color: '$colour-brand-red-hue-1-base',
                      fontFamily: '$font-latin-sans-serif-1',
                    }
                  : {
                      color: '$colour-brand-neutral-hue-1-shade-1',
                      fontFamily: '$font-latin-sans-serif-1',
                    }
              }
            >
              {availablePacks && hasOverflow && product.unitQuantity && (
                <>
                  <Icon
                    size={IconSize['Static XXX-Small']}
                    name="icon-alert-exclamation-outline"
                  />
                  &nbsp;
                  {t('viewBasket.stockOverflowNotification', {
                    amountOfPacksInBasket:
                      getProductStockLevel(product).numberOfPacks +
                      getOverflowQuantity({
                        numberOfPacks,
                        availablePacks,
                      }),
                    amountOfPacks: getProductStockLevel(product).numberOfPacks,
                  })}
                </>
              )}
              {isUnderMoq && minOrderQuantity && !isLoading && (
                <>
                  <Icon
                    size={IconSize['Static XXX-Small']}
                    name="icon-alert-exclamation-outline"
                  />
                  {t('addProductToBasket.moqNotification', {
                    moq: getNumberWithCommas(minOrderQuantity),
                  })}
                </>
              )}
              {!hasOverflow &&
                !isUnderMoq &&
                product.unitQuantity &&
                t('addProductToBasket.amountOfUnits', {
                  amountOfUnits: getNumberWithCommas(amountOfUnits),
                  count: amountOfUnits,
                })}
            </Label>
          }
        >
          <div>
            <StyledInput
              error={hasOverflow || (isUnderMoq && !isLoading)}
              name={`addressesValues.${index}.quantity`}
              placeholder={`${minOrderQuantity || 0}`}
              min={productHasMoqPricing ? minOrderQuantity : 0}
              data-cy={`product-quantity-${address.name}`}
              type="number"
              value={inputQuantityValue}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement>) => {
                const newValue =
                  value.length > 0 ? parseInt(value, 10).toString() : 0;
                setFieldValue(`addressesValues.${index}.quantity`, newValue);
              }}
            />
          </div>
        </FormControl>
      </ProductTableColumn>
      <ProductTableColumn
        style={{
          flex: '0 0 20%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <DoubleDisplay
          css={{
            display: 'flex',
            rowGap: `4`,
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
          left={
            <Label
              css={{
                fontFamily: '$font-latin-sans-serif-1',
              }}
              size={LabelSize.Medium}
            >
              {getProductSubTotal({
                product,
                numberOfPacks: numberOfPacksForCurrentLocation,
                locale,
                currencyCode,
              })}
            </Label>
          }
          right={
            <Label
              size={LabelSize.Small}
              css={{
                color: '$colour-brand-neutral-hue-1-shade-1',
                fontFamily: '$font-latin-sans-serif-1',
              }}
            >
              {taxInfoText}
            </Label>
          }
        />
      </ProductTableColumn>
    </ProductTableRow>
  );
};

export default AddProductToBasketProductRow;
