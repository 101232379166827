import { getMoqPrice } from '../../../utils/getMoqPrice/getMoqPrice';
import { BasketLine, DEFAULT_BASKET_SUMMARY } from '../useBasket';

export interface ComputeBasketSummaryProps {
  basketLines: BasketLine[];
  deliverySubTotal: number;
  currencyCode: string;
  VAT: number;
  tariff: number;
}

export const computeBasketSummary = ({
  basketLines,
  deliverySubTotal,
  currencyCode,
  VAT,
  tariff,
}: ComputeBasketSummaryProps) => {
  if (basketLines.length === 0) {
    return DEFAULT_BASKET_SUMMARY;
  }

  const productsSubTotal = basketLines.reduce(
    (basketLinesPackQuantity, nextbasketLine) => {
      const numberOfUnitsInPack = nextbasketLine.product?.unitQuantity ?? 0;

      const productPriceByCurrency = getMoqPrice({
        totalUnits:
          numberOfUnitsInPack * nextbasketLine.sumOfAddressLineQuantities,
        prices: nextbasketLine.product.productPrices,
        currencyCode,
      });

      const productUnitPrice = productPriceByCurrency?.pricePerUnit;

      if (nextbasketLine.sumOfAddressLineQuantities === 0) {
        return basketLinesPackQuantity;
      }

      const basketLinePrice =
        numberOfUnitsInPack &&
        productUnitPrice &&
        nextbasketLine.sumOfAddressLineQuantities
          ? numberOfUnitsInPack *
            productUnitPrice *
            nextbasketLine.sumOfAddressLineQuantities
          : 0;

      return basketLinesPackQuantity + basketLinePrice;
    },
    0
  );

  // tariff: US only & on products only - not on delivery costs
  const basketSubTotal = productsSubTotal + deliverySubTotal;
  const vatValue = basketSubTotal * VAT;
  const tariffValue = tariff ? basketSubTotal * tariff : 0;

  const basketTotalIncludingTaxes = basketSubTotal + vatValue + tariffValue;
  // TODO: add property:
  // basket has errors
  // for each basket line specify the boolean hasError
  // this will happen if we have a stale basket and serverside the stock at sourceful will drop below required for basket
  return {
    ...DEFAULT_BASKET_SUMMARY,
    productsSubTotal,
    deliverySubTotal,
    basketSubTotal,
    vat: vatValue,
    tariff: tariffValue,
    basketTotalIncludingTaxes,
  };
};
