import { TextBlock } from 'react-placeholder/lib/placeholders';
import { AddProductToBasketFooterSkeleton } from '../AddProductToBasketFooter/AddProductToBasketFooterSkeleton';
import {
  ProductTable,
  ProductTableRow,
  ProductTableColumn,
} from '../AddProductToBasketModal/AddProductToBasketModal.styled';

export interface Props {
  addresses: unknown[];
}

export const AddProductToBasketFormSkeleton = ({ addresses }: Props) => {
  return (
    <>
      <ProductTable className="show-loading-animation">
        {/** adding class to show the loading animation https://github.com/buildo/react-placeholder/issues/93 */}
        {addresses.map((_, index) => {
          return (
            <ProductTableRow
              key={index}
              default
              firstRow={index === 0}
              lastRow={index === addresses.length - 1}
              css={{
                justifyContent: 'space-around',
              }}
            >
              <ProductTableColumn>
                <TextBlock
                  rows={3}
                  color="lightGray"
                  style={{ minWidth: 100 }}
                />
              </ProductTableColumn>
              <ProductTableColumn>
                <TextBlock
                  rows={3}
                  color="lightGray"
                  style={{ minWidth: 100 }}
                />
              </ProductTableColumn>
              <ProductTableColumn>
                <TextBlock
                  rows={3}
                  color="lightGray"
                  style={{ minWidth: 100 }}
                />
              </ProductTableColumn>
            </ProductTableRow>
          );
        })}
      </ProductTable>
      <AddProductToBasketFooterSkeleton />
    </>
  );
};
