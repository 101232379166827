import { useGetCurrentOrganisationProductQuery } from '../../../../../../shared/graphql/types/generated';
import { useUserContext } from '../../../../../../shared/providers/UserProvider/UserProvider';
import { Product } from '../../../../../../shared/types/Product';
import { mapProduct } from '../../../../../products/viewProducts/graphql/mappers';

interface HookResult {
  productLoading: boolean;
  productError?: unknown;
  product: Product | null;
}

const useCurrentOrganisationProduct = ({
  productId,
}: {
  productId: string;
}): HookResult => {
  const { currentOrganisationId } = useUserContext();
  const { isLoading, error, data } = useGetCurrentOrganisationProductQuery(
    {
      productId,
    },
    { enabled: !!productId && !!currentOrganisationId }
  );

  const product = data?.currentOrganisationProduct
    ? data?.currentOrganisationProduct
    : null;

  return {
    productLoading: isLoading,
    productError: error,
    product: product ? mapProduct(product) : null,
  };
};

export default useCurrentOrganisationProduct;
