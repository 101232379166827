import {
  UK_COUNTRY_CODE,
  US_COUNTRY_CODE,
} from '../../../../../shared/constants';
import { Address } from '../../../../../shared/types/Address';
import { AlertIcon } from './AddProductToBasketFormStyled';

// TODO: refactor those utils to components

export const getErrorIcon = (hasError: boolean) => {
  return hasError ? (
    <AlertIcon name="icon-alert-exclamation-outline" />
  ) : (
    <div style={{ width: 16 }}>&nbsp;</div>
  );
};

export const getAddressText = (address: Address) => {
  if (address?.countryCode === UK_COUNTRY_CODE) {
    return (
      <>
        {address.addressLine1}
        {address.addressLine2 ? (
          <>
            <br />
            {address.addressLine2}
          </>
        ) : (
          ''
        )}
        {address.addressLine3 ? (
          <>
            <br />
            {address.addressLine3}
          </>
        ) : (
          ''
        )}
        <>
          <br />
          {address?.city} {address?.postcode} {address?.countryCode}
        </>
      </>
    );
  }
  if (address?.countryCode === US_COUNTRY_CODE) {
    return (
      <>
        {address.addressLine1}
        {address.addressLine2 ? (
          <>
            <br />
            {address.addressLine2}
          </>
        ) : (
          ''
        )}
        {address.addressLine3 ? (
          <>
            <br />
            {address.addressLine3}
          </>
        ) : (
          ''
        )}
        <>
          <br />
          {address?.city}, {address?.region} {address?.postcode}
          <br />
          {address?.countryCode}
        </>
      </>
    );
  }
};

export const getProductQuantityFooter = ({
  numberOfPacks,
  availablePacks,
}: {
  numberOfPacks: number;
  availablePacks: number;
}) => {
  if (!numberOfPacks) {
    return <span style={{ height: 16 }}>&nbsp;</span>;
  }

  const availabilityQuantityDiff = availablePacks - numberOfPacks;

  if (availabilityQuantityDiff < 0) {
    const diffQuantity = Math.abs(availabilityQuantityDiff);

    return (
      <span style={{ color: '#D30339', height: 16 }}>
        {diffQuantity} over availabilty
      </span>
    );
  }
  return `${availablePacks} available`;
};

export const getOverflowQuantity = ({
  numberOfPacks,
  availablePacks,
}: {
  numberOfPacks: number;
  availablePacks: number;
}) => {
  if (!numberOfPacks) {
    return 0;
  }

  const availabilityQuantityDiff = availablePacks - numberOfPacks;

  if (availabilityQuantityDiff < 0) {
    const diffQuantity = Math.abs(Math.floor(availabilityQuantityDiff));

    return diffQuantity;
  }
  return 0;
};

export const canPlaceWarehouseOrderBasedOnQuantity = ({
  numberOfPacks,
  availablePacks,
  isEditing,
}: {
  isEditing: boolean;
  numberOfPacks: number;
  availablePacks: number;
}) => {
  const availabilityQuantityDiff = availablePacks - numberOfPacks;

  return Boolean(availabilityQuantityDiff >= 0 && (numberOfPacks || isEditing));
};

export const canPlaceDropshipOrderBasedOnQuantity = ({
  numberOfPacks,
  isEditing,
}: {
  numberOfPacks: number;
  isEditing: boolean;
}) => {
  return Boolean(numberOfPacks > 0 || isEditing);
};
