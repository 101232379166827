export interface IsMoqQuantityValidProps {
  unitQuantityPackSize: number | null | undefined;
  numberOfPacks: number;
  minMoqQuantity: number | null | undefined;
}

export const isMoqQuantityValid = ({
  unitQuantityPackSize,
  numberOfPacks,
  minMoqQuantity,
}: IsMoqQuantityValidProps): boolean => {
  const totalUnitsInBasket = (unitQuantityPackSize || 1) * numberOfPacks;
  return totalUnitsInBasket >= (minMoqQuantity || 1);
};
