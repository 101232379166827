import { CSSProperties, ReactNode } from 'react';
import { styled } from '@sourceful/design-system';

export const FormControlWrapper = styled('div', {
  display: 'grid',
  gridTemplateAreas: `'labelWrapperSpacer labelWrappper labelWrappper labelWrappper labelWrappper labelWrappper'
    'formControlSpacer formControl formControl formControl formControl formControl'
    'footerSpacer footer footer footer footer footer'`,
  columnGap: 6,

  variants: {
    noColumnGap: {
      true: {
        columnGap: 0,
      },
    },
  },
});

export const LabelWrapperSpacer = styled('div', {
  gridArea: 'labelWrapperSpacer',
});

export const LabelWrapper = styled('div', {
  gridArea: 'labelWrappper',
  marginBottom: '$spacing-fluid-block-x-small',
});

export const InputWrapperSpacer = styled('div', {
  gridArea: 'formControlSpacer',
});

export const FooterSpacer = styled('div', {
  gridArea: 'footerSpacer',
});

export const InputWrapper = styled('div', {
  gridArea: 'formControl',
});

export const Footer = styled('div', {
  gridArea: 'footer',
});

interface Props {
  children: ReactNode;
  icon?: ReactNode;
  label?: ReactNode;
  hasIcon?: boolean;
  footer?: ReactNode;
  noColumnGap?: boolean;
  css?: CSSProperties;
}

export const FormControl = ({
  label,
  icon,
  hasIcon = false,
  noColumnGap = false,
  footer,
  children,
  css,
}: Props) => {
  return (
    <FormControlWrapper style={css} noColumnGap={noColumnGap}>
      {hasIcon && <LabelWrapperSpacer />}

      {label && <LabelWrapper>{label}</LabelWrapper>}

      {hasIcon && <InputWrapperSpacer>{icon}</InputWrapperSpacer>}

      <InputWrapper>{children}</InputWrapper>

      {hasIcon && footer && <FooterSpacer />}

      {footer && <Footer>{footer}</Footer>}
    </FormControlWrapper>
  );
};
