import {
  Button,
  Label,
  LabelSize,
  LabelWeight,
  LabelLineHeight,
  styled,
  ButtonVariant,
  ButtonSize,
  ButtonRadii,
  IconText,
  IconTextIconPosition,
  IconTextIconSize,
  IconTextSpacing,
  IconTextWeight,
  IconTextSize,
} from '@sourceful/design-system';
import { BasketLine } from '../../../providers/BasketProvider/useBasket';

const NavBasketDropdownProductCardStyled = styled('div', {
  display: 'flex',
  padding: '$spacing-fluid-block-x-small',
  color: '$colour-brand-neutral-hue-1-base',
  background: '$colour-brand-neutral-hue-1-tint-6',
  justifyContent: 'space-between',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '$colour-brand-neutral-hue-1-tint-4',
  borderRadius: '$radii-small',
});
const IconTextWrapper = styled('div', {
  color: '$colour-brand-neutral-hue-1-shade-1',
});

export interface Props {
  basketLine: BasketLine;
  onUndoDelete: (basketLine: BasketLine) => void;
}

export const NavBasketDropdownDeletedProductCard = ({
  basketLine,
  onUndoDelete,
}: Props) => {
  return (
    <NavBasketDropdownProductCardStyled>
      <Label size={LabelSize.Medium} css={{ alignItems: 'center', height: 36 }}>
        <IconTextWrapper>
          <IconText
            iconName="icon-alert-tick-outline"
            iconPosition={IconTextIconPosition['Inline Start']}
            iconSize={IconTextIconSize.Small}
            spacing={IconTextSpacing.Small}
            text={'Product removed'}
            textSize={IconTextSize.Small}
            textType="Label"
            css={{
              fontFamily: '$font-latin-sans-serif-1',
              color: '$colour-brand-neutral-hue-1-base',
              height: 16,
            }}
            textWeight={IconTextWeight.Regular}
          />
        </IconTextWrapper>

        <Label
          size={LabelSize.Small}
          lineHeight={LabelLineHeight.Normal}
          weight={LabelWeight['Semi-Bold']}
          css={{
            fontFamily: '$font-latin-sans-serif-1',
            color: '$colour-brand-neutral-hue-1-base',
          }}
        >
          {basketLine.product.name}
        </Label>
      </Label>
      <Label
        size={LabelSize.Medium}
        weight={LabelWeight['Semi-Bold']}
        css={{ padding: 0, alignItems: 'center', height: 36 }}
      >
        <Button
          variant={ButtonVariant.Secondary}
          size={ButtonSize['XX-Small']}
          css={{
            color: '$colour-brand-blue-hue-1-base',
            backgroundColor: 'white',
            width: 64,
            height: 36,
            whiteSpace: 'nowrap',
            alignItems: 'center',
          }}
          radii={ButtonRadii.Small}
          onClick={() => onUndoDelete(basketLine)}
        >
          Undo
        </Button>
      </Label>
    </NavBasketDropdownProductCardStyled>
  );
};
