import {
  LabelSize,
  LabelWeight,
  ButtonVariant,
  ButtonSize,
  Button,
  DialogActionsContainer,
  Tooltip,
  ButtonRadii,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { US_CURRENCY } from '../../../../../shared/constants';
import {
  FormFooter,
  FormFooterRow,
  FormFooterInfo,
  FormFooterRowItem,
  FormFooterRowTextLabel,
} from '../AddProductToBasketForm/AddProductToBasketFormStyled';

export interface Props {
  overflowQuantity: number;
  amountOfPacksInBasket: number;
  amountOfPacksAvailable: number;
  unitsTotalFormatted: string;
  productSubTotal: string | number;
  isCtaDisabled: boolean;
  onCancel: () => void;
  handleSubmit: () => void;
  isEditing: boolean;
  currencyCode: string;
}

const AddProductToBasketFooter = ({
  overflowQuantity,
  amountOfPacksInBasket,
  amountOfPacksAvailable,
  unitsTotalFormatted,
  productSubTotal,
  isCtaDisabled,
  onCancel,
  handleSubmit,
  isEditing,
  currencyCode,
}: Props) => {
  const { t } = useTranslation();

  const taxInfoText =
    currencyCode === US_CURRENCY
      ? t('addProductToBasket.productsTotalExclTaxes')
      : t('addProductToBasket.productsTotalExclVAT');

  return (
    <FormFooter>
      <FormFooterRow spaceBetween>
        <FormFooterInfo>
          <FormFooterRowItem>
            <FormFooterRowTextLabel
              size={LabelSize.Small}
              weight={LabelWeight.Regular}
              css={
                overflowQuantity > 0
                  ? {
                      color: '$colour-brand-red-hue-1-base',
                    }
                  : {}
              }
            >
              {t('addProductToBasket.packsTotal')}
            </FormFooterRowTextLabel>
          </FormFooterRowItem>
        </FormFooterInfo>
        <FormFooterInfo>
          <FormFooterRowItem>
            <FormFooterRowTextLabel
              size={LabelSize.Small}
              weight={LabelWeight.Regular}
              css={
                overflowQuantity > 0
                  ? {
                      color: '$colour-brand-red-hue-1-base',
                    }
                  : {}
              }
            >
              {amountOfPacksAvailable
                ? t('addProductToBasket.amountOfPacksTotal', {
                    amountOfPacksInBasket: amountOfPacksInBasket,
                    amountOfPacks: amountOfPacksAvailable,
                  })
                : amountOfPacksInBasket}
            </FormFooterRowTextLabel>
          </FormFooterRowItem>
        </FormFooterInfo>
      </FormFooterRow>
      <FormFooterRow spaceBetween borderBottom>
        <FormFooterInfo>
          <FormFooterRowItem>
            <FormFooterRowTextLabel
              size={LabelSize.Small}
              weight={LabelWeight.Regular}
            >
              {t('addProductToBasket.unitsTotal')}
            </FormFooterRowTextLabel>
          </FormFooterRowItem>
        </FormFooterInfo>
        <FormFooterInfo>
          <FormFooterRowItem>
            <FormFooterRowTextLabel
              size={LabelSize.Small}
              weight={LabelWeight.Regular}
            >
              {unitsTotalFormatted}
            </FormFooterRowTextLabel>
          </FormFooterRowItem>
        </FormFooterInfo>
      </FormFooterRow>

      <FormFooterRow spaceBetween>
        <FormFooterInfo>
          <FormFooterRowItem>
            <FormFooterRowTextLabel
              darkGrey
              size={LabelSize.Medium}
              weight={LabelWeight['Semi-Bold']}
            >
              {taxInfoText}
            </FormFooterRowTextLabel>
          </FormFooterRowItem>
        </FormFooterInfo>
        <FormFooterInfo>
          <FormFooterRowItem>
            <FormFooterRowTextLabel
              darkGrey
              size={LabelSize.Medium}
              weight={LabelWeight['Semi-Bold']}
            >
              {productSubTotal}
            </FormFooterRowTextLabel>
          </FormFooterRowItem>
        </FormFooterInfo>
      </FormFooterRow>

      <DialogActionsContainer>
        <Button
          size={ButtonSize.Medium}
          variant={ButtonVariant.Secondary}
          css={{ whiteSpace: 'nowrap', fontWeight: '600' }}
          onClick={onCancel}
          radii={ButtonRadii.Small}
        >
          {t('addProductToBasket.cancel')}
        </Button>

        {isCtaDisabled && (
          <Tooltip
            trigger={
              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.Primary}
                css={{
                  whiteSpace: 'nowrap',
                  cursor: 'not-allowed',
                  fontWeight: '600',
                }}
                onClick={() => handleSubmit()}
                disabled={isCtaDisabled}
                radii={ButtonRadii.Small}
              >
                {t(
                  isEditing
                    ? 'addProductToBasket.editInBasket'
                    : 'addProductToBasket.addToBasket'
                )}
              </Button>
            }
            contentText={t('addProductToBasket.disabledCTATooltip')}
          />
        )}

        {!isCtaDisabled && (
          <Button
            size={ButtonSize.Medium}
            data-cy="add-product-to-basket-button"
            variant={ButtonVariant.Primary}
            css={{ whiteSpace: 'nowrap', fontWeight: '600' }}
            onClick={() => handleSubmit()}
            disabled={isCtaDisabled}
            radii={ButtonRadii.Small}
          >
            {t(
              isEditing
                ? 'addProductToBasket.editInBasket'
                : 'addProductToBasket.addToBasket'
            )}
          </Button>
        )}
      </DialogActionsContainer>
    </FormFooter>
  );
};

export default AddProductToBasketFooter;
