import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { styled } from '@sourceful/design-system';

const SCROLLBAR_SIZE = 0;

const StyledScrollArea = styled(ScrollAreaPrimitive.Root, {
  width: '100%',
  height: 'auto',
  maxHeight: '90vh',
  overflow: 'hidden',
  overflowY: 'auto',
  marginBottom: '$spacing-fluid-block-medium',

  /* width */
  '&::-webkit-scrollbar': {
    width: SCROLLBAR_SIZE,
    touchAction: 'none',
    padding: 2,
    transition: 'background 160ms ease-out',
    position: 'absolute',
    right: '-100px',
  },

  /* Track */
  '&::-webkit-scrollbar-track': {
    boxShadow: '0',
  },

  /* Handle */
  '&::-webkit-scrollbar-thumb': {
    background: 'lightGrey',
    borderRadius: SCROLLBAR_SIZE,
  },

  /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'lightGrey',
  },
});

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: '100%',
  height: '100%',
});

export const ScrollArea = StyledScrollArea;
export const ScrollAreaViewport = StyledViewport;
