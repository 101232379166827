import { styled } from '@sourceful/design-system';

export const Card = styled('div', {
  backgroundColor: '#fff',
  boxShadow: '0 0.0625rem 0.5rem 0 rgba(64, 64, 68, 0.3)',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  // maxWidth: '303px',
  // minWidth: '156px',
  borderRadius: '4px',
});
