import { GridContainer, Grid } from '@sourceful/design-system';
import {
  OrderInfoWrapper,
  SummaryWrapper,
} from '../../../../shared/components/Grid/ViewOrderGrid';
import { OrderDetailsCardSkeleton } from '../../../../shared/components/OrderDetailsCard/OrderDetailsCardSkeleton';
import { SummarySkeleton } from '../../../../shared/components/Summary/SummarySkeleton';
import { OrderSummaryBannerSkeleton } from '../components/OrderSummaryBannerSkeleton';

export const ViewClientOrderSkeleton = () => {
  return (
    <>
      <GridContainer>
        <Grid columns={{ '@initial': 1 }}>
          <OrderSummaryBannerSkeleton />
        </Grid>
      </GridContainer>
      <GridContainer>
        <Grid gapY={'large'} columns={{ '@initial': 6, '@bp-sm': 12 }}>
          <OrderInfoWrapper>
            <OrderDetailsCardSkeleton />
          </OrderInfoWrapper>
          <SummaryWrapper>
            <SummarySkeleton />
          </SummaryWrapper>
        </Grid>
      </GridContainer>
    </>
  );
};
