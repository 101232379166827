import {
  Label,
  LabelSize,
  LabelWeight,
  LabelLineHeight,
  Icon,
  IconSize,
  DialogActionsContainer,
  Button,
  ButtonSize,
  ButtonVariant,
  ButtonRadii,
  Tooltip,
} from '@sourceful/design-system';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { UK_CURRENCY, UK_LOCALE } from '../../../../../shared/constants';
import useWindowDimensions from '../../../../../shared/hooks/useWindowDimensions/useWindowDimensions';
import { useOrganisationsContext } from '../../../../../shared/providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { AddressValue, Values } from '../../../../../shared/types/Address';
import { Product } from '../../../../../shared/types/Product';
import { getNumberOfPacks } from '../../utils';
import {
  ProductTable,
  ProductTableRow,
  ProductTableColumn,
  TableHeaderText,
} from '../AddProductToBasketModal/AddProductToBasketModal.styled';
import AddPrepaidStockToBasketProductRow from '../AddProductToBasketProductRow/AddPrepaidStockToBasketProductRow';
import { FormFooter } from './AddProductToBasketFormStyled';
import {
  ScrollArea,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from './ScrollbarStyled';
import {
  canPlaceWarehouseOrderBasedOnQuantity,
  getOverflowQuantity,
} from './utils';

export interface Props {
  product: Product;
  isEditing: boolean;
  onSubmit: (values: Values) => void;
  onCancel: () => void;
  addressesValues: AddressValue[];
}
export const AddPrepaidStockToBasketForm = ({
  product,
  onSubmit,
  onCancel,
  addressesValues,
  isEditing,
}: Props) => {
  const { height } = useWindowDimensions();
  const { t } = useTranslation();
  const addressesHeight = addressesValues.length * 135;
  const locationsWrapperHeight = Math.min(height / 2.6, addressesHeight);

  let availablePacks = 0;

  if (product.productStocks.length > 0) {
    const productStockWithInternalAddress = product.productStocks.find(
      productStock => productStock.address?.isInternal
    );
    if (productStockWithInternalAddress) {
      availablePacks =
        (productStockWithInternalAddress.quantity || 0) /
        (product.unitQuantity || 1);
    }
  }
  const initialValues = { addressesValues } as Values;
  const { currentOrganisation } = useOrganisationsContext();
  const locale = currentOrganisation?.locale || UK_LOCALE;
  const currencyCode = currentOrganisation?.defaultCurrency || UK_CURRENCY;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ addressesValues }: Values) => {
        const standardisedAddressesValues = addressesValues.map(
          addressValue => ({
            address: addressValue.address,
            quantity: Number(addressValue.quantity),
          })
        );

        const filteredAddressesValues = standardisedAddressesValues.filter(
          addressesValue => addressesValue.quantity
        );

        onSubmit({ addressesValues: filteredAddressesValues });
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        const numberOfPacks = getNumberOfPacks(values.addressesValues);
        const canPlaceAnOrder = canPlaceWarehouseOrderBasedOnQuantity({
          numberOfPacks,
          availablePacks,
          isEditing,
        });

        const hasOverflow = (index: number) =>
          values.addressesValues[index].quantity > 0 && !canPlaceAnOrder;

        const isCtaDisabled = availablePacks < 1 || !canPlaceAnOrder;

        return (
          <Form>
            <Label
              size={LabelSize.Medium}
              lineHeight={LabelLineHeight.Normal}
              css={{ marginTop: 12 }}
            >
              {values.addressesValues.length === 0 && (
                <div>{t('addProductToBasket.youDontHaveAnyLocationsYet')}</div>
              )}
            </Label>
            <ScrollArea style={{ height: locationsWrapperHeight }}>
              <ScrollAreaViewport>
                <ProductTable>
                  {!currentOrganisation?.config?.prepaidStock && (
                    <ProductTableRow
                      css={{ marginBottom: '$spacing-fluid-block-x-small' }}
                    >
                      <ProductTableColumn style={{ flex: '0 0 50%' }}>
                        <TableHeaderText
                          size={LabelSize.Medium}
                          weight={LabelWeight['Semi-Bold']}
                        >
                          {t('addProductToBasket.location')}
                        </TableHeaderText>
                      </ProductTableColumn>
                      <ProductTableColumn style={{ flex: '0 0 30%' }}>
                        <TableHeaderText
                          size={LabelSize.Medium}
                          weight={LabelWeight['Semi-Bold']}
                        >
                          {t('addProductToBasket.packQuantity')}
                        </TableHeaderText>
                      </ProductTableColumn>
                      <ProductTableColumn
                        style={{
                          flex: '0 0 20%',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <TableHeaderText
                          size={LabelSize.Medium}
                          weight={LabelWeight['Semi-Bold']}
                          css={{ paddingRight: '$spacing-fluid-block-small' }}
                        >
                          {t('addProductToBasket.productCost')}
                        </TableHeaderText>
                      </ProductTableColumn>
                    </ProductTableRow>
                  )}
                  {values.addressesValues.length > 0 &&
                    values.addressesValues.map(({ address }, index) => {
                      return (
                        <AddPrepaidStockToBasketProductRow
                          index={index}
                          key={index}
                          amountOfLocations={values.addressesValues.length}
                          address={address}
                          hasOverflow={hasOverflow(index)}
                          product={product}
                          numberOfPacks={numberOfPacks}
                          numberOfPacksForCurrentLocation={
                            values.addressesValues[index].quantity || 0
                          }
                          availablePacks={availablePacks}
                          amountOfUnits={
                            product.unitQuantity
                              ? values.addressesValues[index].quantity *
                                product.unitQuantity
                              : 0
                          }
                          setFieldValue={(
                            field: string,
                            value: React.ChangeEvent<HTMLInputElement>
                          ) => setFieldValue(field, value)}
                          inputQuantityValue={
                            values.addressesValues[index].quantity
                          }
                          locale={locale}
                          currencyCode={currencyCode}
                        />
                      );
                    })}
                </ProductTable>
              </ScrollAreaViewport>
              <ScrollAreaScrollbar orientation="vertical">
                <ScrollAreaThumb />
              </ScrollAreaScrollbar>
            </ScrollArea>
            {getOverflowQuantity({
              numberOfPacks,
              availablePacks,
            }) > 0 && (
              <Label
                size={LabelSize.Small}
                css={{
                  color: '$colour-brand-red-hue-1-base',
                  fontFamily: '$font-latin-sans-serif-1',
                  display: 'flex',
                  marginTop: '-$spacing-fluid-block-medium',
                  position: 'absolute',
                }}
              >
                <Icon
                  size={IconSize['Static XXX-Small']}
                  name="icon-alert-exclamation-outline"
                />
                &nbsp;&nbsp;
                {t('viewBasket.stockOverflowExplanation', {
                  totalAmountOfOverflowProducts: getOverflowQuantity({
                    numberOfPacks,
                    availablePacks,
                  }),
                  count: getOverflowQuantity({
                    numberOfPacks,
                    availablePacks,
                  }),
                })}
              </Label>
            )}

            <FormFooter css={{ margin: '0 !important' }}>
              <DialogActionsContainer>
                <Button
                  size={ButtonSize.Medium}
                  variant={ButtonVariant.Secondary}
                  css={{ whiteSpace: 'nowrap', fontWeight: '600' }}
                  onClick={onCancel}
                  radii={ButtonRadii.Small}
                >
                  {t('addProductToBasket.cancel')}
                </Button>

                {isCtaDisabled && (
                  <Tooltip
                    trigger={
                      <Button
                        size={ButtonSize.Medium}
                        variant={ButtonVariant.Primary}
                        css={{
                          whiteSpace: 'nowrap',
                          cursor: 'not-allowed',
                          fontWeight: '600',
                        }}
                        onClick={() => handleSubmit()}
                        disabled={isCtaDisabled}
                        radii={ButtonRadii.Small}
                      >
                        {t(
                          isEditing
                            ? 'addProductToBasket.editInBasket'
                            : 'addProductToBasket.addToBasket'
                        )}
                      </Button>
                    }
                    contentText={t('addProductToBasket.disabledCTATooltip')}
                  />
                )}

                {!isCtaDisabled && (
                  <Button
                    data-cy="add-product-to-basket-button"
                    size={ButtonSize.Medium}
                    variant={ButtonVariant.Primary}
                    css={{ whiteSpace: 'nowrap', fontWeight: '600' }}
                    onClick={() => handleSubmit()}
                    disabled={isCtaDisabled}
                    radii={ButtonRadii.Small}
                  >
                    {t(
                      isEditing
                        ? 'addProductToBasket.editInBasket'
                        : 'addProductToBasket.addToBasket'
                    )}
                  </Button>
                )}
              </DialogActionsContainer>
            </FormFooter>
          </Form>
        );
      }}
    </Formik>
  );
};
