import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import LogRocket from 'logrocket';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  DEFAULT_ROLES,
  HASURA_ALLOWED_ROLES_CLAIM,
  HASURA_CLAIMS_NAMESPACE,
  ROLE,
} from '../../constants/authorization';
import { User } from '../../types/User';
import { mapUser } from './mappers';

const DEFAULT_CONTEXT: UserContextInterface = {
  activeRole: ROLE.PUBLIC,
  roles: DEFAULT_ROLES,
  user: undefined,
  organisations: [],
};

const DEFAULT_CONTEXT_VALUE: UserContextValue = {
  ...DEFAULT_CONTEXT,
  isLoading: true,
  isAuthenticated: false,
  getAccessToken: async () => '',
  login: async () => undefined,
  logout: async () => null,
  setCurrentOrganisationId: async () => null,
  currentOrganisationId: null,
  organisations: [],
  isSwitchingOrgs: false,
};

export interface LogoutOptions {
  returnTo?: string;
}

export interface LoginOptions {
  redirectUri?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appState?: any;
  fragment?: string;
}

export interface Options {
  children: React.ReactElement;
}

export interface GetAccessTokenOptions {
  organisationId?: string;
  ignoreCache?: boolean;
}

export interface Organisation {
  id: string;
  name: string;
  display_name: string;
}
export interface UserContextInterface {
  activeRole: string;
  roles: string[];
  user?: User;
  organisations: Organisation[];
}

export interface UserContextValue extends UserContextInterface {
  isAuthenticated: boolean;
  isLoading: boolean;
  logout: (logoutOptions: LogoutOptions) => void;
  login: (loginOptions: LoginOptions) => Promise<void>;
  getAccessToken: (options: GetAccessTokenOptions) => Promise<string>;
  setCurrentOrganisationId: (orgId: string) => void;
  currentOrganisationId: string | null;
  isSwitchingOrgs: boolean;
}

export const UserContext = createContext<UserContextValue>(
  DEFAULT_CONTEXT_VALUE
);

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children, ...props }: Options) => {
  const {
    user,
    isLoading,
    isAuthenticated,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const [currentAuth0OrganisationId, setCurrentAuth0OrganisationId] = useState<
    string | null
  >(null);

  const [currentOrganisationId, setCurrentOrganisationId] = useState<
    string | null
  >(null);

  const [userContext, setUserContext] = useState(DEFAULT_CONTEXT);
  const [isSwitchingOrgs, setIsSwitchingOrgs] = useState(false);

  const [userToken, setUserToken] = useState<string | null>(null);

  const getAccessToken = async ({
    organisationId,
    ignoreCache,
  }: GetAccessTokenOptions) =>
    getAccessTokenSilently({
      organisation: organisationId,
      ignoreCache,
    });

  async function switchOrganisationId(organisationId: string) {
    setIsSwitchingOrgs(true);
    setCurrentOrganisationId(null);

    const token = await getAccessToken({
      organisationId: organisationId,
      ignoreCache: true,
    });
    setUserToken(token);

    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
    setIsSwitchingOrgs(false);

    setCurrentOrganisationId(organisationId);
  }

  useEffect(() => {
    if (isAuthenticated) {
      if (
        Array.isArray(
          user?.[HASURA_CLAIMS_NAMESPACE]?.[HASURA_ALLOWED_ROLES_CLAIM]
        )
      ) {
        const roles =
          user?.[HASURA_CLAIMS_NAMESPACE]?.[HASURA_ALLOWED_ROLES_CLAIM];

        let activeRole: ROLE;
        if (roles.includes(ROLE.ADMIN)) {
          activeRole = ROLE.ADMIN;
        } else {
          activeRole = ROLE.PUBLIC;
        }

        if (user?.sub) {
          LogRocket.identify(user?.sub || '', {
            name: user?.name || 'name',
            email: user?.email || 'email',
          });
        }

        const organisations = Array.isArray(
          user?.[HASURA_CLAIMS_NAMESPACE]?.user?.organisations
        )
          ? user?.[HASURA_CLAIMS_NAMESPACE]?.user?.organisations
          : [];

        const currentOrganisationIdFromAuth0 = user?.[HASURA_CLAIMS_NAMESPACE]
          ?.user?.org?.id
          ? user?.[HASURA_CLAIMS_NAMESPACE]?.user?.org?.id
          : null;

        setCurrentAuth0OrganisationId(currentOrganisationIdFromAuth0);

        setUserContext({
          activeRole: activeRole,
          roles,
          user: mapUser(user),
          organisations,
        });
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isAuthenticated && currentAuth0OrganisationId) {
      switchOrganisationId(currentAuth0OrganisationId);
    } else if (!isAuthenticated) {
      loginWithRedirect({
        appState: { returnTo: document.location },
      });
    }
  }, [isAuthenticated, isLoading, currentAuth0OrganisationId]);

  const userContextValue = {
    ...userContext,
    isLoading,
    isSwitchingOrgs,
    isAuthenticated,
    getAccessToken,
    currentOrganisationId,
    setCurrentOrganisationId: (orgId: string) => {
      setCurrentAuth0OrganisationId(orgId);
    },
    logout: (logoutOptions: LogoutOptions) => logout(logoutOptions),
    login: async (options: LoginOptions) => loginWithRedirect(options),
  };

  if (!userToken) return null;

  return (
    <UserContext.Provider value={userContextValue} {...props}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
