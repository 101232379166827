// FYI: had to downgrade to TSC 4.5.5 due to the issue with stiches

import { styled } from '@sourceful/design-system';
import { BREAKPOINT_M } from '../../constants/styles';
import { Z_INDEX_HEADER, Z_INDEX_LOWEST } from '../../constants/zIndexValues';

// https://github.com/stitchesjs/stitches/issues/947
export const StyledNav = styled('nav', {
  width: '100%',
  display: 'flex',
  height: '64px',
  alignItems: 'center',
});

export const NavBottomBorder = styled('div', {
  display: 'none',
  [BREAKPOINT_M]: {
    display: 'block',
    position: 'absolute',
    top: '65px',
    left: '0',
    width: '100vw',
    borderBottom: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
    zIndex: Z_INDEX_LOWEST,
  },
});

export const SelectWrapper = styled('div', {
  marginRight: '$spacing-fluid-block-small',
});

export const NavWrapper = styled('div', {
  width: '100vw',
  position: 'fixed',
  zIndex: Z_INDEX_HEADER,
  background: '$colour-lightest',
  variants: {
    responsiveMenuOpen: {
      true: {
        [BREAKPOINT_M]: {
          height: '100vh',
        },
      },
    },
  },
});

export const TitleWrapper = styled('div', {
  marginBlockEnd: '$spacing-fluid-block-medium',
});

export const UnorderedList = styled('ul', {
  display: 'flex',
  alignItems: 'center',
  variants: {
    navigationLinks: {
      true: {
        flexGrow: '1',
      },
    },
    right: {
      true: {
        marginLeft: 'auto',
      },
    },
    notIncludedInPrintView: {
      true: {
        '@media print': {
          display: 'none',
        },
      },
    },
    responsive: {
      true: {
        [BREAKPOINT_M]: {
          position: 'absolute',
          top: '70px',
          display: 'block',
        },
      },
    },
  },
});

export const ListItem = styled('li', {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  variants: {
    noPadding: {
      true: {
        padding: 0,
      },
    },
    right: {
      true: {
        marginLeft: 'auto',
      },
    },
    visibleInHeaderForResponsiveView: {
      true: {
        // does not show until medium breakpoint is met
        display: 'none',
        [BREAKPOINT_M]: {
          display: 'block',
        },
      },
      false: {
        // won't show after breakpoint is met
        [BREAKPOINT_M]: {
          display: 'none',
        },
      },
    },
    responsive: {
      true: {
        [BREAKPOINT_M]: {
          display: 'block',
          marginLeft: '0 !important',
          paddingBottom: '$spacing-fluid-block-medium',
        },
      },
    },
    borderTop: {
      true: {
        [BREAKPOINT_M]: {
          width: '90vw',
          borderTop: 'solid 1px $colour-brand-neutral-hue-1-tint-5',
        },
      },
    },
    notIncludedInPrintView: {
      true: {
        '@media print': {
          display: 'none',
        },
      },
    },
  },
});
