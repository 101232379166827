import { Store } from '..';

export function createLocalStorageStore<T>(): Store<T> {
  return {
    setItem: (key: string, value: T) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
    getItem: (key: string): T | undefined => {
      const value = localStorage.getItem(key);

      return value ? JSON.parse(value) : undefined;
    },
    removeItem: (key: string) => {
      localStorage.removeItem(key);
    },
  };
}
