import { styled } from '@sourceful/design-system';

export const BannerWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto auto auto', // add an extra 'auto' when the created by info is added to single order view
  width: '500px',
  rowGap: '8px',
});

export const PrinterButtonWrapper = styled('div', {
  alignSelf: 'flex-end',
  '@media print': {
    display: 'none',
  },
});

export const OrderSummaryBannerWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  fontFamily: '$font-latin-sans-serif-1',
});
