import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useCurrentOrganisationClientOrders from '../../../modules/clientOrders/viewClientOrders/graphql/hooks/useCurrentOrganisationClientOrders';
import { formatOrderNumber } from '../../utils/formatOrderNumber/formatOrderNumber';
import { useToastContext } from '../ToastProvider/ToastProvider';
import { useOrganisationsContext } from '../UserOrganisationsProvider/UserOrganisationsProvider';
import { useBasketContext } from './BasketProvider';
import {
  createDefaultPlacingOrderState,
  usePlaceOrder,
  UsePlaceOrderResult,
} from './usePlaceOrder';
import { createClientOrderPayload } from './utils';

export interface Options {
  children: React.ReactElement;
}

export interface ClientOrderContextInterface
  extends Omit<UsePlaceOrderResult, 'placeOrder'> {
  placeOrder: (options: { isDraft: boolean }) => Promise<void>;
}

const DEFAULT_CONTEXT: ClientOrderContextInterface = {
  ...createDefaultPlacingOrderState(),
  placeOrder: async () => undefined,
};

export const ClientOrderContext =
  createContext<ClientOrderContextInterface>(DEFAULT_CONTEXT);

export const useClientOrderContext = () => useContext(ClientOrderContext);

export const ClientOrderProvider = ({ children, ...props }: Options) => {
  const { showToast } = useToastContext();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const basket = useBasketContext();

  const { placeOrder: handlePlaceOrder, ...placeOrderProps } = usePlaceOrder();

  const { refetchCurrentOrganisation } = useOrganisationsContext();
  const { refetchClientOrders } = useCurrentOrganisationClientOrders();

  const orderContainsDropshipProducts = basket.basketOrders.every(order =>
    order.orderLines.every(
      orderline => orderline?.product?.productType?.name === 'Dropship'
    )
  );

  const placeOrder = async ({
    isDraft = false,
  }: { isDraft?: boolean } = {}) => {
    const payload = createClientOrderPayload({
      basketOrders: basket.basketOrders,
      isDraft,
    });

    const { hasError, createdClientOrders } = await handlePlaceOrder(payload);
    if (Array.isArray(createdClientOrders) && createdClientOrders.length) {
      createdClientOrders.forEach(createdClientOrder => {
        showToast({
          title: t('toast.orderPlacedTitle', {
            orderNumber: formatOrderNumber(createdClientOrder?.clientOrderId),
            maxDays: createdClientOrder?.deliveryTimeline?.max,
            units: createdClientOrder?.deliveryTimeline?.units,
          }),
          description: orderContainsDropshipProducts
            ? t('toast.orderPlacedPaymentDisclaimerDropship')
            : t('toast.orderPlacedPaymentDescriptionWarehouse'),
          iconName: 'icon-shopping-shipping',
        });
      });

      basket.resetBasketState();
      refetchCurrentOrganisation();
      refetchClientOrders();
      navigate(`/orders`);
    } else if (hasError) {
      showToast({
        description: t('toast.errorPlacingOrderDescription'),
        iconName: 'icon-alert-exclamation-outline',
      });
    }
  };

  return (
    <ClientOrderContext.Provider
      value={{
        ...placeOrderProps,
        placeOrder,
      }}
      {...props}
    >
      {children}
    </ClientOrderContext.Provider>
  );
};

export default ClientOrderProvider;
