import { getPrice } from '../../../utils/getPrice/getPrice';

export const getDeliveryFee = (deliveryFee: number) => {
  if (deliveryFee === 0) {
    return 'Free';
  }

  return `+${getPrice(deliveryFee)}`;
};

interface DeliveryFeeOptions {
  notApplicableText: string;
  deliveryFee?: number;
  locale?: string;
  currencyCode?: string;
}

export const getDeliveryFeeText = (options: DeliveryFeeOptions) => {
  if (typeof options.deliveryFee === 'number' && options.deliveryFee !== 0) {
    return getPrice(options.deliveryFee, options.currencyCode);
  } else if (options.deliveryFee === 0) {
    return options.notApplicableText;
  } else {
    return null;
  }
};

export const getTotalMinimumDeliveryTime = (
  minDeliveryTime: number,
  minManufacturingTimes: number[]
) => {
  const minManufacturingTime = Math.max(...minManufacturingTimes);
  return minManufacturingTime + minDeliveryTime;
};

export const getTotalMaximumDeliveryTime = (
  maxDeliveryTime: number,
  maxManufacturingTimes: number[]
) => {
  const maxManufacturingTime = Math.max(...maxManufacturingTimes);
  return maxManufacturingTime + maxDeliveryTime;
};
