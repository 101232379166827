import { styled, BodyWeight } from '@sourceful/design-system';
import { Link } from 'react-router-dom';

// responsive solution:
// https://codepen.io/team/css-tricks/pen/wXgJww?editors=1100

export const TableContainer = styled('table', {
  width: '100%',
  borderCollapse: 'separate',
  borderSpacing: 0,
  '@media (max-width: 950px)': {
    width: '100%',
    'table, thead, tbody, th, td, tr': {
      display: 'block',
    },
    'table, thead, tbody, th, td': {
      border: '0 !important',
    },
    thead: {
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
    },
    tr: {
      border: 'solid 1px $colour-brand-neutral-hue-1-tint-4',
      borderRadius: '$radii-small',
      margin: '0 0 1rem 0',
    },
    td: {
      /* Behave  like a "row" */
      position: 'relative',
      paddingLeft: '50% !important',
      '&:last-child': {
        textAlign: 'left',
      },
    },
    'td:before': {
      /* Now like a table header */
      position: 'absolute',
      /* left values mimic padding */
      left: '1rem',
      width: ' 45%',
      paddingRight: '10px',
      whiteSpace: 'nowrap',
      fontWeight: '600',
    },
    'td:nth-of-type(1)': {
      backgroundColor: '$colour-brand-neutral-hue-1-tint-6', // colour/brand/neutral/hue/1/tint/6
      borderRadius: '$radii-small',
    },
    'td:nth-of-type(1):before': {
      content: 'Order Id',
    },
    'td:nth-of-type(2):before': {
      content: 'Status',
    },
    'td:nth-of-type(3):before': {
      content: 'Order date',
    },
    'td:nth-of-type(4):before': {
      content: 'Delivery date',
    },
    'td:nth-of-type(5):before': {
      content: 'To',
    },
    'td:nth-of-type(6):before': {
      content: 'Products',
    },
    'td:nth-of-type(7):before': {
      content: 'Total incl. VAT',
    },
    'td:nth-of-type(8):before': {
      content: '',
    },
  },
});

export const TableHeader = styled('th', {
  textAlign: 'left',
  paddingBottom: '$spacing-fluid-block-small',
  fontFamily: '$font-latin-sans-serif-1', // typography.latin.label.comfort.small.normal.regular
  fontWeight: '$font-weight.regular',
  lineHeight: '$line-height.23',
  fontSize: '$label-small',
  letterSpacing: '$letter-spacing.loose',
});

export const TableContentRow = styled('tr', {
  backgroundColor: '$colour-lightest',
  '&:hover': {
    backgroundColor: '$colour-brand-neutral-hue-1-tint-8', // colour/brand/neutral/hue/1/tint/8
  },
  '&:first-child': {
    td: {
      borderTopStyle: 'solid',
    },
    'td:first-child': {
      borderTopLeftRadius: '$radii-small',
    },
    'td:last-child': {
      borderTopRightRadius: '$radii-small',
    },
  },
  '&:last-child': {
    'td:first-child': {
      borderBottomLeftRadius: '$radii-small',
    },
    'td:last-child': {
      borderBottomRightRadius: '$radii-small',
    },
  },
  '& :nth-child(4)': {
    paddingRight: '$spacing-fluid-block-xx-large',
  },
});

export const TableData = styled('td', {
  border: 'solid 1px $colour-brand-neutral-hue-1-tint-4',
  borderStyle: 'none none solid none',
  padding:
    '$spacing-fluid-block-small $spacing-fluid-block-small $spacing-fluid-block-small 0',
  fontFamily: '$font-latin-sans-serif-1', // typography.latin.label.comfort.small.normal.regular
  textAlign: 'left',
  fontWeight: BodyWeight.Regular,
  lineHeight: '$line-height.23',
  fontSize: '$label-medium',
  letterSpacing: '$letter-spacing.loose',
  '&:last-child': {
    borderRightStyle: 'solid',
    textAlign: 'right',
    paddingLeft: '$spacing-fluid-block-large',
  },
  '&:first-child': {
    paddingLeft: '$spacing-fluid-block-medium',
    borderLeftStyle: 'solid',
    fontWeight: '600',
  },
});

export const BlueLink = styled(Link, {
  paddingBottom: '$spacing-fluid-block-small',
  fontFamily: '$font-latin-sans-serif-1', // typography.latin.label.comfort.small.normal.regular
  fontWeight: '600',
  lineHeight: '$line-height.23',
  fontSize: '$label-medium',
  letterSpacing: '$letter-spacing.loose',
  color: '$colour-brand-blue-hue-1-base',
});

export const StatusTag = styled('span', {
  padding: '$spacing-fluid-block-xx-small',
  borderRadius: '$radii-x-small',
  fontSize: '$label-small',
  width: 'fit-content',
  variants: {
    red: {
      true: {
        color: '$colour-brand-red-hue-1-shade-3',
        backgroundColor: '$colour-brand-red-hue-1-tint-5',
      },
    },
    blue: {
      true: {
        color: '#002D70',
        backgroundColor: '#E5F0FF',
      },
    },
    yellow: {
      true: {
        color: '#422F00',
        backgroundColor: '#FFF9EB',
      },
    },
    grey: {
      true: {
        color: '#6A7381',
        backgroundColor: '#F5F5F5',
      },
    },
    greyNoBackground: {
      true: {
        color: '#6A7381',
        paddingLeft: '$spacing-fluid-block-x-small',
      },
    },
  },
});
