import { useState } from 'react';
import {
  Title,
  TitleSize,
  TitleLineHeight,
  Label,
  LabelSize,
  LabelLineHeight,
  LabelWeight,
  Button,
  ButtonRadii,
  ButtonSize,
  ButtonVariant,
  styled,
  Icon,
  IconSize,
  GridContainer,
  Grid,
} from '@sourceful/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  OrderInfoWrapper,
  SummaryWrapper,
  ButtonWrapper,
} from '../../../../shared/components/Grid/ViewOrderGrid';
import { TitleWrapper } from '../../../../shared/components/Nav/NavStyled';
import { OrderDetailsCard } from '../../../../shared/components/OrderDetailsCard/OrderDetailsCard';
import { OrderSummary } from '../../../../shared/components/OrderSummary/OrderSummary';
import {
  MODAL_ID_QUERY_PARAM_NAME,
  PRODUCT_ID_QUERY_PARAM_NAME,
  UK_CURRENCY,
} from '../../../../shared/constants';
import { ADD_PRODUCT_TO_BASKET_MODAL_ID } from '../../../../shared/constants/modals';
import { useBasketContext } from '../../../../shared/providers/BasketProvider/BasketProvider';
import { useClientOrderContext } from '../../../../shared/providers/BasketProvider/ClientOrderProvider';
import { useOrganisationsContext } from '../../../../shared/providers/UserOrganisationsProvider/UserOrganisationsProvider';
import { useUserContext } from '../../../../shared/providers/UserProvider/UserProvider';
import { getNumberOrZero } from '../../../../shared/utils/getNumberOrZero/getNumberOrZero';
import { getTaxRate } from '../../../../shared/utils/getTaxRate/getTaxRate';
import { DeleteLocationAndProductsAlertDialog } from '../components/DeleteLocationAndProductsAlertDialog/DeleteLocationAndProductsAlertDialog';
import { ViewBasketSkeleton } from '../components/ViewBasketSkeleton';
import { getAllMoqPricesSortedDescending } from '../../../../shared/utils/getMoqPrice/getMoqPrice';
import { isMoqQuantityValid } from '../../../../shared/utils/isMoqQuantityValid/isMoqQuantityValid';
import config from '../../../../config';
import { AlertBanner } from '../../../../shared/components/AlertBanner/AlertBanner';

export const SummaryDataWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: 22,
  marginBottom: 22,
  rowGap: 12,
});
export const PaymentDisclaimer = styled('div', {
  background: '$colour-brand-neutral-hue-1-tint-6',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '$radii-small',
  columnGap: 6,
  marginTop: '$spacing-fluid-block-small',
  padding: '4px 6px',
});

export const ViewBasket = () => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [addressIdToBeRemoved, setAddressIdToBeRemoved] = useState<
    string | null
  >(null);

  const { currentOrganisation } = useOrganisationsContext();
  const { isSwitchingOrgs } = useUserContext();
  const {
    basketOrders,
    basketSummary,
    deliveryInformations,
    removeProductFromAddress,
    removeBasketOrderByAddressId,
    editProductQuantityInBasket,
    handleClientReferenceChange,
    handleAddAttachment,
    handleRemoveAttachment,
  } = useBasketContext();
  const { placeOrder, isPlacingOrder, isSavingDraftOrder } =
    useClientOrderContext();

  const isLoading = !currentOrganisation || isSwitchingOrgs;

  const handleBasketOrderRemove = (addressId: string) => {
    setAddressIdToBeRemoved(addressId);
  };
  const removeBasketOrderByAddress = () => {
    if (addressIdToBeRemoved) {
      removeBasketOrderByAddressId(addressIdToBeRemoved);
      setAddressIdToBeRemoved(null);
    }
  };
  const editProductInBasket = (productId: string) => {
    setSearchParams(
      `?${MODAL_ID_QUERY_PARAM_NAME}=${ADD_PRODUCT_TO_BASKET_MODAL_ID}&${PRODUCT_ID_QUERY_PARAM_NAME}=${productId}`
    );
  };

  const basketOrdersToBeDisplayed = basketOrders.map(basketOrder => {
    const deliveryInfo = deliveryInformations.find(
      deliveryInformation =>
        deliveryInformation.addressId === basketOrder.address.id
    );

    return { ...basketOrder, deliveryInfo };
  });

  const displayBasketOrders = Boolean(basketOrdersToBeDisplayed.length > 0);

  const isBatchOrderCorrect = basketOrders.every(basketOrder => {
    return basketOrder.orderLines.every(orderLine => {
      const moqPrices = getAllMoqPricesSortedDescending(
        orderLine.product.productPrices
      );
      return (
        orderLine.overflowQuantity === 0 &&
        isMoqQuantityValid({
          unitQuantityPackSize: orderLine.product.unitQuantity,
          numberOfPacks: orderLine.numberOfPacks,
          minMoqQuantity: moqPrices[moqPrices.length - 1].minOrderQuantity,
        })
      );
    });
  });

  const isButtonDisabled =
    isPlacingOrder || isSavingDraftOrder || !isBatchOrderCorrect;

  const VATPercentage = getTaxRate(
    currentOrganisation?.addresses[0].taxRates,
    'VAT'
  ).taxPercentage;
  const tariffPercentage = getTaxRate(
    currentOrganisation?.addresses[0].taxRates,
    'Tariff'
  ).taxPercentage;
  const computedTariff = basketSummary.tariff;

  const currencyCode = currentOrganisation?.defaultCurrency || UK_CURRENCY;

  const handlePlacingOrder =
    ({ isDraft }: { isDraft: boolean }) =>
    async () => {
      if (isButtonDisabled) {
        return;
      }
      await placeOrder({ isDraft });
    };

  const isWarehouseOrg = basketOrders?.some(basketOrder => {
    if (basketOrder) {
      return basketOrder.orderLines.some(orderLine => {
        return (
          orderLine.product.productType?.name === 'Warehouse' ||
          orderLine.product.productTypeId === 1
        );
      });
    } else return false;
  });

  return (
    <div>
      {!isLoading &&
      isWarehouseOrg &&
      config.featureFlagWarehouseHolidayBannerEnabled === 'true' ? (
        <GridContainer>
          <Grid columns={{ '@initial': 1 }}>
            <AlertBanner
              firstLineMessage="alertMessages.warehouseClosedForHolidays"
              secondLineMessage="alertMessages.happyHolidays"
              variant="info"
            />
          </Grid>
        </GridContainer>
      ) : null}
      <TitleWrapper>
        <GridContainer>
          <Grid columns={{ '@initial': 1 }}>
            <Title
              size={TitleSize.Medium}
              lineHeight={TitleLineHeight.Normal}
              as="h2"
            >
              {t('viewBasket.yourBasket')}
            </Title>
          </Grid>
        </GridContainer>
      </TitleWrapper>
      {isLoading && <ViewBasketSkeleton />}
      {!displayBasketOrders && !isLoading && (
        <GridContainer>
          <Grid gapY={'medium'} columns={{ '@initial': 1 }}>
            <Label
              size={LabelSize.Small}
              lineHeight={LabelLineHeight.Loose}
              weight={LabelWeight['Semi-Bold']}
            >
              {t('viewBasket.emptyBasket')}
            </Label>
          </Grid>
        </GridContainer>
      )}
      {!isLoading && basketOrders.length > 0 && (
        <GridContainer>
          <Grid gapY={'large'} columns={{ '@initial': 6, '@bp-sm': 12 }}>
            <OrderInfoWrapper>
              <DeleteLocationAndProductsAlertDialog
                onConfirm={removeBasketOrderByAddress}
                isOpen={!!addressIdToBeRemoved}
                onOpenChange={open => {
                  if (!open) setAddressIdToBeRemoved(null);
                }}
              />

              {displayBasketOrders &&
                basketOrdersToBeDisplayed.map((basketOrder, index) => {
                  return (
                    <OrderDetailsCard
                      isEditing={true}
                      onAttachmentAdd={handleAddAttachment}
                      onAttachmentRemove={handleRemoveAttachment}
                      order={basketOrder}
                      onClientReferenceChange={handleClientReferenceChange}
                      onProductQuantityChange={editProductQuantityInBasket}
                      onOrderRemove={handleBasketOrderRemove}
                      onProductDelete={removeProductFromAddress}
                      onLineEdit={editProductInBasket}
                      key={`${basketOrder.address.addressDescription}-${index}`}
                    />
                  );
                })}
            </OrderInfoWrapper>
            <SummaryWrapper css={{ position: 'relative', top: '-3.25rem' }}>
              <OrderSummary
                size="large"
                currencyCode={currencyCode}
                productsSubTotal={basketSummary.productsSubTotal}
                deliveryFee={basketSummary.deliverySubTotal}
                orderVAT={basketSummary.vat}
                VATPercentage={getNumberOrZero(VATPercentage)}
                tariffPercentage={getNumberOrZero(tariffPercentage)}
                orderTariff={getNumberOrZero(computedTariff)}
                productsAndDeliverySubtotal={basketSummary.basketSubTotal}
                basketTotalIncludingTaxes={
                  basketSummary.basketTotalIncludingTaxes
                }
                deliveryLabel={t('orderDetails.delivery')}
                subtotalLabel={t('orderDetails.basketSubTotal')}
                totalIncludingTaxesLabel={
                  tariffPercentage
                    ? t('orderDetails.basketTotalInclTaxes')
                    : t('orderDetails.basketTotalInclVAT')
                }
              />

              <Button
                size={ButtonSize.Medium}
                variant={ButtonVariant.Primary}
                radii={ButtonRadii.Small}
                disabled={isButtonDisabled}
                onClick={handlePlacingOrder({ isDraft: false })}
                css={
                  isButtonDisabled
                    ? {
                        marginTop: '$spacing-fluid-block-small',
                        width: '100%',
                        fontWeight: 600,
                        cursor: 'not-allowed',
                      }
                    : {
                        marginTop: '$spacing-fluid-block-small',
                        width: '100%',
                        fontWeight: 600,
                      }
                }
                data-cy="view-basket-place-order-button"
              >
                {isPlacingOrder
                  ? t('viewBasket.placingOrder')
                  : t('viewBasket.placeOrder', {
                      count: basketOrdersToBeDisplayed.length,
                    })}
              </Button>
              <PaymentDisclaimer>
                <Icon
                  css={{ color: '$colour-brand-blue-hue-1-base' }}
                  name="icon-alert-information-fill"
                  size={IconSize.Small}
                />
                <Label
                  size={LabelSize.Small}
                  css={{ color: '$colour-brand-neutral-hue-1-base' }}
                >
                  {currentOrganisation.config?.paymentDisclaimer}
                </Label>
              </PaymentDisclaimer>
            </SummaryWrapper>
            <ButtonWrapper>
              <Button
                radii={ButtonRadii.Small}
                size={ButtonSize['X-Large']}
                variant={ButtonVariant.Tertiary}
                onClick={() => navigate('/')}
                css={{
                  backgroundColor: '$colour-brand-neutral-hue-1-tint-5',
                  fontWeight: 600,
                }}
              >
                {t('viewBasket.addMoreProducts')}
              </Button>
            </ButtonWrapper>
            {/* {POST MVP} */}
            {/* <Button
              size={ButtonSize.Small}
              variant={ButtonVariant.Secondary}
              radii={ButtonRadii.Pill}
              disabled={isButtonDisabled}
              css={{ marginTop: '$spacing-fluid-block-small' }}
              onClick={handlePlacingOrder({ isDraft: true })}
            >
              {isSavingDraftOrder
                ? t('viewBasket.savingDraftOrder')
                : t('viewBasket.saveAsDraftOrder')}
            </Button>
            */}
          </Grid>
        </GridContainer>
      )}
    </div>
  );
};
