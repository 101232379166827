import axios from 'axios';
import fileDownload from 'js-file-download';
import config from '../../../config';
import { objectToQueryString } from '../../utils/objectToQueryString/objectToQueryString';

const useDownload = () => {
  const downloadFile = async (
    attachmentUuid: string,
    originalFilename: string
  ) => {
    const attachmentQueryString = objectToQueryString({
      filename: originalFilename,
      uuid_name: attachmentUuid,
    });

    try {
      const response = await axios.get(
        `${config.fileUploaderBaseUrl}/download?${attachmentQueryString}`,
        {
          responseType: 'blob',
        }
      );
      const blob = response.data;
      fileDownload(blob, originalFilename);
    } catch (err) {
      console.error('Error in download attachment API call', err);
      throw err;
    }
  };

  return { downloadFile };
};

export default useDownload;
