export const MAX_NUMBER_OF_CHARACTERS_IN_ORDER_ID = 8;

export const formatOrderNumber = (
  value: string | undefined | null,
  withoutNumberSign?: boolean
) => {
  if (!value) {
    return '';
  }

  if (value.length < MAX_NUMBER_OF_CHARACTERS_IN_ORDER_ID) {
    return `${withoutNumberSign ? '' : '#'}${value.toUpperCase()}`;
  }

  return `${withoutNumberSign ? '' : '#'}${value
    .substr(0, MAX_NUMBER_OF_CHARACTERS_IN_ORDER_ID)
    .toUpperCase()}`;
};
