import { useSearchParams } from 'react-router-dom';
import { AddProductToBasketModal } from '../../../modules/basket/addProductToBasket/components/AddProductToBasketModal/AddProductToBasketModal';
import { MODAL_ID_QUERY_PARAM_NAME } from '../../constants';
import { ADD_PRODUCT_TO_BASKET_MODAL_ID } from '../../constants/modals';

export interface ModalComponentProps {
  open: boolean;
  handleClose: () => void;
}

export type ModalComponent = (props: ModalComponentProps) => JSX.Element | null;

export interface ModalItem {
  id: string;
  modal: ModalComponent;
}

const modals: ModalItem[] = [
  {
    id: ADD_PRODUCT_TO_BASKET_MODAL_ID,
    modal: AddProductToBasketModal,
  },
];

const ModalProvider = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    setSearchParams('');
  };

  const { [MODAL_ID_QUERY_PARAM_NAME]: modalId } = Object.fromEntries(
    Array.from(searchParams)
  );

  const isOpen = Boolean(modalId);

  if (!isOpen) return null;

  const foundModal = modals.find(modal => modal.id === modalId);
  if (!foundModal) return null;

  const ModalComponent = foundModal.modal;

  return <ModalComponent open={Boolean(modalId)} handleClose={handleClose} />;
};

export default ModalProvider;
