import {
  ClientOrder as ClientOrderGraphqlType,
  useGetCurrentOrganisationClientOrdersQuery,
} from '../../../../../shared/graphql/types/generated';
import { useUserContext } from '../../../../../shared/providers/UserProvider/UserProvider';
import { BasicClientOrder } from '../../../../../shared/types/ClientOrder';
import { mapBasicClientOrder } from '../mappers/index';

interface HookResult {
  clientOrdersLoading: boolean;
  clientOrdersError?: unknown;
  clientOrders: BasicClientOrder[];
  refetchClientOrders: () => void;
}

const useCurrentOrganisationClientOrders = (): HookResult => {
  const { currentOrganisationId } = useUserContext();
  const { isLoading, error, data, refetch } =
    useGetCurrentOrganisationClientOrdersQuery({
      currentOrganisationId: currentOrganisationId as never, // this is to retry the http request when currentOrganisationId changes
    });

  const clientOrders =
    data?.getCurrentOrganisationClientOrders &&
    Array.isArray(data?.getCurrentOrganisationClientOrders.data)
      ? data?.getCurrentOrganisationClientOrders.data
      : [];

  const filteredClientOrders = clientOrders.filter(
    Boolean
  ) as ClientOrderGraphqlType[];

  return {
    clientOrdersLoading: isLoading,
    clientOrdersError: error,
    clientOrders: filteredClientOrders.map(mapBasicClientOrder),
    refetchClientOrders: () => refetch(),
  };
};

export default useCurrentOrganisationClientOrders;
