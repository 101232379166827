import { ProductPrice } from '../../types/ProductPrice';

export interface GetMoqPriceProps {
  totalUnits: number;
  prices: ProductPrice[];
  currencyCode: string;
  alwaysFindFirst?: boolean;
}

export const getMoqPrice = ({
  totalUnits,
  prices,
  currencyCode,
  alwaysFindFirst = false,
}: GetMoqPriceProps): ProductPrice | undefined => {
  let productPrice: ProductPrice | undefined;

  const productPricesByCurrency: ProductPrice[] = prices.filter(
    price => price.currencyCode === currencyCode
  );

  if (productPricesByCurrency.length === 0) {
    return undefined;
  }

  // get all prices that have a minOrderQuantity lower than the totalUnits
  const moqPrices: ProductPrice[] = getAllMoqPricesSortedDescending(
    productPricesByCurrency.filter(
      price => !price.minOrderQuantity || price.minOrderQuantity <= totalUnits
    )
  );

  if (moqPrices.length === 0 && !alwaysFindFirst) {
    return undefined;
  }

  if (moqPrices.length === 0) {
    productPrice = productPricesByCurrency[0];
  } else {
    productPrice = moqPrices[0];
  }

  return productPrice;
};

export const getAllMoqPricesSortedDescending = (prices: ProductPrice[]) => {
  return prices.sort((a, b) => {
    if (a.minOrderQuantity && b.minOrderQuantity) {
      return b.minOrderQuantity - a.minOrderQuantity;
    } else if (a.minOrderQuantity) {
      return -1;
    } else return 1;
  });
};
